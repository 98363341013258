import React, {useEffect, useState} from 'react';
import {IonContent, IonHeader, IonIcon, IonItem, IonModal, IonToolbar} from '@ionic/react';
import {arrowDown, arrowUp, ellipsisHorizontal, flask, helpSharp, send} from 'ionicons/icons';
import 'react-circular-progressbar/dist/styles.css';
import trailer from "../imgs/trailer.png";
import chevronRight from "../imgs/chevron-right.png";
import upArrow from "../imgs/up-arrow.png";
import gasoline from "../imgs/gasoline.png";
import documentIcon from "../imgs/document.png"
import greenCheck from "../imgs/green-checkbox.png"
import redXXX from "../imgs/red-checkbox.png"
import greenCircle from "../imgs/green-circle.png";
import emptyCircle from "../imgs/empty-circle.png";
import {Link} from "react-router-dom";
import CountUp from 'react-countup';
import {ApiService} from "../api/ApiService";
import {DriverProfile} from "../api/model/DriverProfile";
import {UserState, userStore} from "../api/AuthService";
import {UserUtils} from "../utils/UserUtils";
import { toastStore } from '../components/AppToast';
import AnimateHeight from 'react-animate-height';
import Speedometer, { Background, Arc, Needle, Progress, Marks, Indicator } from 'react-speedometer';
import HelpDirect from './HelpDirect';

//////// -----------------> MOVE TO PROGRESS CHECKER <------------------\\\\\\\\\\\\\\
let interval: any
let interval2: any
let interval3: any
let interval4: any
let i = 0;
let h = 0;
let j = 0
let k = 0;

const ProfileInfo: React.FC = () => {

    const {driver, profilePictureUrl}: { driver: DriverProfile, profilePictureUrl: string } = userStore((state: UserState) => ({
        driver: state.driver,
        profilePictureUrl: state.profilePictureUrl
    }));

    const today = new Date();

  const [showNum, SetShowNum] = useState(0);
  const [showMilesNum, setShowMilesNum] = useState(0);
  const [showMotiveNum, setShowMotiveNum] = useState(0);

  const {division} = userStore((state: UserState) => ({division: state.driver?.division}));
  const [clearedDivision, setClearedDivision] = useState<boolean>(false);
  const [currentRate, setCurrentRate] = useState(0);
  const [daysUntilRaise, setDaysUntilRaise] = useState(0);
  const [payrollStartDate, setPayrollStartDate] = useState("");
  const [showLatest, setShowLatest] = useState(false);
  const [showFuel, setShowFuel] = useState(false);
  const [lowDEF, setLowDEF] = useState<string>('no');
  const [DEFLevel, setDEFLevel] = useState<string>("");
  const [fuelLevel, setFuelLevel] = useState<string>("");
  const [height1, setHeight1] = useState(false);
  const [height2, setHeight2] = useState(false);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [bonusEligible, setBonusEligible] = useState<boolean>(false);
  const [bonusData, setBonusData] = useState<any>({});
  const [countDownMonths, setCountDownMonths] = useState<number>(0);
  const [countDownDays, setCountDownDays] = useState<number>(0);
  const [totalGoalsNum, setTotalGoalsNum] = useState<number>(0);
  const [scoreTotal, setScoreTotal] = useState<number>(0);
  const [gameData, setGameData] = useState({
    COST_CENTER: "-",
    HIRE_DATE: "-",
    HIRE_TENURE_DAYS: 0,
    MILES_LIFETIME: 0,
    MILES_MTD: 0,
    MILES_MTD_RANK: 0,
    MILES_WTD: 0,
    MILES_WTD_RANK: 0,
    MPG_MTD_COSTCENTER_GROUP: "-",
    MPG_MTD_MILES: 0,
    MPG_MTD_MPG: 0,
    MPG_MTD_RANK: 0,
    MPG_MTD_TRUCK_GROUP: "-",
    MPG_WTD_COSTCENTER_GROUP: "-",
    MPG_WTD_MILES: 0,
    MPG_WTD_MPG: 0,
    MPG_WTD_RANK: 0,
    MPG_WTD_TRUCK_GROUP: "-",
    MTD_TEAM: 0,
    PRETRIPS_MTD_RANK: 0,
    PRETRIPS_WTD_RANK: 0,
    WTD_TEAM: 0,
    exectime: 0  
  }
)

  useEffect(() => {
    checkDivision();
    ApiService.getPayrollOverview("profileInfo").then(it => {
      if (!it) {
        return;
      }
      let startDate = it.INITIAL_DATE.replace('T', ' ').slice(0, -14)
      setCurrentRate(it.CURRENT_FULL_SOLO_RATE);
      setDaysUntilRaise(it.DAYS_TILL_NEXT_RATE);
      setPayrollStartDate(startDate.substr(5) + "-" + startDate.substr(0, 4));
    })
    .catch(error => {
      console.error(error);
    });
    ApiService.getGamificationDriver2("profileInfo").then(it => setGameData(it as any))
    .catch(error => {
      console.error(error);
    });
    ApiService.getSafetyBonus("profileInfo").then(it => parseBonusData(it)).catch(it => console.log("no data found"));
   }, []);

   const checkDivision = () => {
    if (division === "VNASH" || division === "NER" || division === "VCT" || division === "VVA" || division === "100" || division === "FNASH" || division === "FNR" || division === "500") {
      setClearedDivision(true);
    } else {
      setClearedDivision(false);
    }
  }

  function toProperCase (str: string) {
    if (str !== undefined) {
      return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    } else {
      return "";
    }
  }

  function parseBonusData(data :any) {
    let tempData: any = data
    let tempDate: any =  new Date().toISOString().toString().slice(0, -14);
    console.log("bonus data", tempData.NEW_ELIGIBILITY_DATE.toString().slice(0, -14) === tempDate, tempData);
    if (tempData.ACCIDENT_ELIGIBLE === "Y" && tempData.TENURE_ELIGIBLE === "Y") {
      let tempObj: {} = {
        totalMiles: tempData.TOTAL_DISPMILES,
        motiveScore: tempData.MOTIVE_SCORE,
        noAccident: tempData.ACCIDENT_ELIGIBLE,
        watchedVideos: tempData.ASSIGNMENT_ELIGIBLE // NEEDS ITS DATA
      }
      let tempMilesPercent = Math.round((tempData.TOTAL_DISPMILES / 6000) * 100)
      let motivePercent = (tempData.MOTIVE_SCORE / 90) * 100
      let totalGoalsPercent: any = getGoalsTotal(tempObj);
      setScoreTotal(totalGoalsPercent.totalScore)
      interval2 = setInterval(() => {increment(tempMilesPercent, 2)}, 15);
      interval3 = setInterval(() => {increment(motivePercent, 3)}, 10);
      interval4 = setInterval(() => {increment(totalGoalsPercent.percent, 4)}, 15);
      setBonusData(tempObj);
      setBonusEligible(true)
    } else {
      if (tempData.TENURE_ELIGIBLE === "N" || tempData.ACCIDENT_ELIGIBLE === "N") {
        calculateMonthsAndDays(tempData.NEW_ELIGIBILITY_DATE);
        setBonusEligible(false);
      }
    }
  }

  function getGoalsTotal(data:any) {
    let i: any = {
      totalScore: 0,
      percent: 0
    }
    if (data.totalMiles >= 6000) i.totalScore += 1;
    if (data.motiveScore >= 90) i.totalScore += 1;
    if (data.noAccident === 'Y') i.totalScore += 1;
    if (data.watchedVideos === 'Y') i.totalScore += 1;
    i.percent = (i.totalScore / 4) * 100
    return i;
  }

  const sendFuelRequest = async () => {
    if (fuelLevel !== "" && DEFLevel !== "") {
      const emailAddress = await getEmailAddress();
      const emailBody = `Driver ${localStorage.getItem('username')} has request a fuel soltuion on unit: ${userStore.getState().driver?.unit} <br/> Fuel Level: ${fuelLevel} <br/> Low on DEF: ${lowDEF} <br/>DEF Level: ${DEFLevel}`;
      ApiService.sendEmail("Fuel solution", {to: [emailAddress], subject: `Fuel Solution for Unit: ${userStore.getState().driver?.unit}`, body: emailBody}).then(() => {setShowFuel(false); setLowDEF("no"); setDEFLevel(""); setFuelLevel("")});
      ApiService.sendEventCommand("Fuel Request", "eventCheck", "User requested a fuel solution");
    } else {
      toastStore.getState().setToast('Please fill out all the fields');
    }
  }

  function calculateMonthsAndDays(endDate: any) {
    let tempDate = new Date(endDate)
    let months = tempDate.getMonth() - today.getMonth() + (12 * (tempDate.getFullYear() - today.getFullYear()));
    let days = tempDate.getDate() - today.getDate();
    if (days < 0) {
      months--;
      const prevMonth = new Date(tempDate.getFullYear(), tempDate.getMonth(), 0);
      days += prevMonth.getDate();
    }
    const timeDifference = tempDate.valueOf() - today.valueOf();
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    console.log("endDate", tempDate, today, months, days, timeDifference, daysDifference)
    setProgressValue(Math.abs(daysDifference) / 183)
    setCountDownMonths(months);
    setCountDownDays(days);
    console.log("days diff", daysDifference, Math.abs((183 - daysDifference) / 183) * 100);
    interval = setInterval(() => {increment(Math.abs((183 - daysDifference) / 183) * 100, 1)}, 30);
    return { months, days };
  }

  function increment(progress: number, id: number) {
    if (id === 1) { 
      i = i + 1;
      if (i >= progress) {
        clearInterval(interval)
      }
      console.log("logs", i);
      SetShowNum(i);
    } else if(id === 2) {
      h = h + 1;
      if (h >= progress) {
        clearInterval(interval2)
      }
      setShowMilesNum(h);
    } else if(id === 3) {
      j = j + 1;
      if (j >= progress) {
        clearInterval(interval3)
      }
      setShowMotiveNum(j);
    } else if(id === 4) {
      k = k + 1;
      if (k >= progress) {
        clearInterval(interval4)
      }
      setTotalGoalsNum(k);
    }
  }

  async function getEmailAddress() {
    const day = today.getDay();
    const hours = today.getHours()
    if (day !== 0 && day !== 6 && hours >= 7 && hours <= 16) {
      console.log("wsxi");
      return "wsxi-fuel@westernexp.com"
    } else {
      console.log("hotelfuel");
      return "HOTELSBUSTICKETSFUEL@westernexp.com"
    }
  }

  const countup = (number: number) => {
    return (
      <CountUp end={number} duration={1.2} delay={0.2} separator=','/>
    )
  }

  return (
    <IonItem lines="none" color="light">
       
       <IonModal isOpen={showLatest}>
          <IonHeader >
                <IonToolbar color="primary">
                  <p style={{fontFamily: "Montserrat, sans-seriff", padding: "0 8px"}} onClick={() => setShowLatest(false)}>Back</p>
                </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonItem color='light' lines="none">
              <div className='item-container'>
                <p>Latest Update</p>
                <h1><strong>Leader Boards!</strong></h1>
                <p className='subtitle'>Check out the leader boards to see the MPG standings. Do you have the best fuel efficiency in your division?!</p>
                  <Link className='western-button-link' to="/rewards">
                    <div className="space-between">
                      <p>Go to Division Standings</p>
                      <img src={chevronRight} style={{ height: "1.8vh"}}alt="arrow"/>
                    </div>
                  </Link>
              </div>
            </IonItem>
        </IonContent>
      </IonModal>

      <IonModal isOpen={showFuel}>
          <IonHeader>
                <IonToolbar color="primary">
                  <p style={{fontFamily: "Montserrat, sans-seriff", padding: "0 8px"}} onClick={() => setShowFuel(false)}>Back</p>
                </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonItem color='light' lines="none">
              <div className='item-container'>
                <h1><strong>Request Fuel Solution</strong></h1>
                <p className='subtitle'>Before requesting a fuel solution, please answer the following questions:</p>
                <IonItem color="transparent">
                  <p style={{ width: "60%", padding: '5px', alignItems: "center"}}>Current Fuel Level</p>
                  <div className="input-container">
                      <input id="trainee" className="input" type="text" placeholder=" " value={fuelLevel} onChange={e => setFuelLevel(e.target.value!)}/>
                      <p className="placeholder">Fuel Level</p>
                  </div>
                  </IonItem> 
                  <IonItem color="transparent">
                  <p style={{width: "60%", padding: '5px', alignItems: "center"}}>Are you low on DEF?</p>
                  <div className="input-container" style={{width: "25%", marginLeft: '15px'}}>
                      <select id="accident" className="input"  placeholder=" " value={lowDEF} onChange={e => {setLowDEF(e.target.value!);}} style={{ height: "20px", textAlign: "center"}}>
                          <option value={"no"}>No</option>
                          <option value={"yes"}>Yes</option>
                      </select>
                      <p className="placeholder" ></p>
                  </div>
                  </IonItem> 
                  <IonItem color="transparent" lines="none">
                  <p style={{width: "60%", padding: '5px', alignItems: "center"}}>DEF Level</p>
                  <div className="input-container">
                      <input id="trainee" className="input" type="text" placeholder=" " value={DEFLevel} onChange={e => setDEFLevel(e.target.value!)}/>
                      <p className="placeholder">DEF Level</p>
                  </div>
                  </IonItem> 
                  <div className="space-between" style={{background: "#fff", borderRadius: "10px", marginTop: "10px"}} onClick={e => {sendFuelRequest()}}>
                    <p style={{paddingLeft: "10px"}}>Request Solution</p>
                    <img src={chevronRight} style={{ height: "1.8vh", paddingRight: '8px'}}alt="arrow"/>
                  </div>
              </div>
          </IonItem> 
        </IonContent>
      </IonModal>
      
     <div className="item-container">
      <div>
        <div style={!UserUtils.isTrainee() ? { display: "grid", gridTemplateColumns: "40% 58%", gap: "2vw", alignItems: "center", margin: "8px"} : { display: "flex", alignItems: "center", margin: "8px"}}>
            {/* <img className="profile-photo" onError={({currentTarget}) => {
                currentTarget.onerror = null;
                currentTarget.src = placeholder
            }} src={profilePictureUrl} alt="avatar"/> */}

            <div className='box__bg' style={{alignContent: "center", textAlign: "center", margin: "auto"}}>
              <div>
              <span style={{fontSize: "16px"}}><b>{driver.driverCode !== undefined ? '' : ''} {driver.driverCode}</b></span>
              </div>
              <div className="game-stat">
                    <p><b>Unit</b></p>
                    <strong>{driver?.unit || '-'}</strong>
                </div>
            </div>

            <div className='western-button-link' style={{gap: "2vw"}}>
                {!UserUtils.isTrainee() ? <>
                    <div className="game-stat" style={{display: "grid", marginBottom: "10px"}}>
                      <div style={{margin: "auto", justifyContent: "center", fontSize: '14px'}}>
                        <p>Hello</p>
                        <strong>&nbsp;{toProperCase((driver.name).substring(0, driver.name.indexOf(' ')))}!</strong>
                      </div>
                      <div style={{justifyContent: "center"}}>
                        <p>Tenure:</p>
                        <strong>&nbsp;{gameData.HIRE_TENURE_DAYS >= 0 && gameData.HIRE_TENURE_DAYS !== null ? gameData.HIRE_TENURE_DAYS.toLocaleString() : '-'} Days</strong>
                      </div>
                      { division !== "800" ? <div style={{justifyContent: "center"}}>
                        <p>Base Rate:</p>
                        <strong>&nbsp;{currentRate ? currentRate + '/Mi' : '-'}</strong>
                      </div> : null} 
                    </div>
                  </>
                 : null}
            </div>
          </div>
        </div>
        {/* <h5 className="green" style={{ margin: "8px"}}><b><span style={{ color: "black"}}>{toProperCase(driver.name)}</span> {driver.driverCode !== undefined ? '|' : ''} {driver.driverCode}</b></h5> */}
       
        { !UserUtils.isTrainee() && clearedDivision ? 
        <div className="western-button-link" onClick={ e => {setHeight2(!height2);}} style={{marginBottom: "10%"}}>
          <div className="green" style={{padding: "0 0px"}}>

              {/* <----------------------- Bonus Egilibility -------------------------> */}
            { !bonusEligible ? 
            <Link to="/safetybonus" style={{textDecoration: "none", width: "100%"}}>
            <div style={{display: "grid", textAlign: "center", gridTemplateColumns: "33% 33% 33%", backgroundColor: '#e5f4e0', width: "100%"}}>
              <p><b>Safety Bonus Count Down</b></p>
              <div style={{backgroundColor: '#e5f4e0', margin: "10% auto auto auto", width: "100%", justifyContent: "center"}}>
                <Speedometer max={100} value={showNum} fontFamily='squada-one' width={80}>
                  <Background color="transparent" />
                  <Arc color='#000' opacity={100} arcWidth={3}/>
                  <Needle baseWidth={3} offset={13} baseOffset={-11} circleRadius={8} circleColor='black' color='black'/>
                  <Progress arcWidth={8} color="#99cc00" lineCap="butt" />
                  <Marks step={10} fontSize={0} lineColor='black'/>
                </Speedometer>
              </div>

              {/* <div style={{backgroundColor: 'transparent', margin: "auto auto auto 10%", position: "relative"}}>
                <div style={{background: "transparent"}}>
                  <Speedometer
                    value={showNum}
                    width={80}
                    max={100}
                    angle={360}
                    lineCap="round"
                    accentColor="#99cc00"
                    >
                    <Arc arcWidth={12} />
                    <Progress arcWidth={12} />
                    </Speedometer>
                </div>
                  <div style={{background: "transparent", left: "-50%", marginBottom: "1%", position: "relative"}}>
                    <p><span className='progressNum'>{countup(progressValue)}%</span></p>
                  </div>
                </div> */}

              <div style={{display: "grid", lineHeight: "0px", backgroundColor: '#e5f4e0'}}>
                <p><b>Eligible in:</b></p>
                <p>{countup(countDownMonths)} months</p>
                <p>{countup(countDownDays)} days</p>
              </div>
            </div> 
            </Link> :
            
            // <----------------------- Bonus Requirements ------------------------->
            <div className="bonus-card" style={{textAlign: "center",  width: "100%", display: "grid", backgroundColor: '#e5f4e0'}}>
              { true ? <Link to="/safetybonus" style={{textDecoration: "none"}}>
              <div style={{ margin: "10px auto 0px auto", borderBottom: "1px solid #00000099", borderRadius: "0px", width: "100%", justifyContent: "center", backgroundColor: '#e5f4e0'}}>
                <span style={{fontSize: "18px"}}><b>Monthly Safety Bonus Progress</b></span>
              </div>
              <div style={{display: "grid", textAlign: "center", gridTemplateColumns: "50% 50%", backgroundColor: '#e5f4e0', fontSize: "14px"}}>
                <div style={{display: "inline", backgroundColor: '#e5f4e0'}}>
                  <div style={{backgroundColor: '#e5f4e0'}}>
                    <span><b>Miles</b>: 6,000/   <span style={bonusData.totalMiles <= 1999 ? {color: "red", textShadow: "0px 1px 1.5px #00000094"} : bonusData.totalMiles <= 2999 ? {color: "orange", textShadow: "0px 1px 1.5px #00000094"} : bonusData.totalMiles <= 3999 ? {color: "yellow", textShadow: "0px 1px 1.5px #00000094"} : {color: "#5bc300", textShadow: "0px 1px 1.5px #00000094"}}>{countup(bonusData.totalMiles)}</span></span>
                  </div>
                  <div style={{backgroundColor: '#e5f4e0'}}>
                    <span><b>Motive Score</b>: <span style={bonusData.motiveScore <= 29 ? {color: "red", textShadow: "0px 1px 1.5px #00000094"} : bonusData.motiveScore <= 59 ? {color: "orange", textShadow: "0px 1px 1.5px #00000094"} : bonusData.motiveScore <= 89 ? {color: "yellow", textShadow: "0px 1px 1.5px #00000094"} : {color: "#5bc300", textShadow: "0px 1px 1.5px #00000094"}}>{countup(bonusData.motiveScore)}</span></span>
                  </div>
                </div>

                <div style={{display: "inline", backgroundColor: '#e5f4e0'}}>
                  <div style={{backgroundColor: '#e5f4e0'}}>
                    <span><b>Safety Videos</b>: </span>
                    <img src={bonusData.watchedVideos === 'Y' ? greenCircle : emptyCircle} style={{height: "20px", borderRadius: "50%", background: "#fff", margin: "auto"}} alt='status'/>
                  </div>
                  <div style={{backgroundColor: '#e5f4e0'}}>
                    <span><b>No Accidents</b>: </span><img src={bonusData.noAccident === 'Y' ? greenCircle : emptyCircle} style={{height: "20px", borderRadius: "50%", background: "#fff", margin: "auto"}} alt='status'/>
                  </div>
                </div>
              </div>
              </Link> : 

              <>
              <div style={{ margin: "10px auto 0px auto", borderBottom: "1px solid #00000099", borderRadius: "0px", width: "100%", justifyContent: "center"}}>
                <span style={{fontSize: "18px"}}><b>Monthly Safety Bonus Progress</b></span>
              </div>

              <AnimateHeight duration={200} height={ height2 === false ? 'auto' : 0 }>
                <div style={{textAlign: "center",  margin: "0px auto auto", width: "100%"}}>
                  <div style={{display: "inline",  width: "100%"}}>
                    <div style={{backgroundColor: '#fff'}}>
                      <span>Safety Bonus Goals Meet: <b>{countup(scoreTotal)} / 4</b></span>
                    </div>
                    <div style={{display: "grid", gridTemplateColumns: "90% 10%", backgroundColor: '#fff'}}>
                      <div className="progress-bar">
                        <div className="progress-bar-fill" style={{width:`${totalGoalsNum}%`}}></div>
                      </div>
                      <div style={{ justifyContent: "flex-end"}}>
                        <img src={scoreTotal === 4 ? greenCircle : emptyCircle} style={{height: "20px", borderRadius: "50%", background: "#cacaca"}} alt='status'/>
                      </div>
                    </div>
                  </div>
                </div>
              </AnimateHeight>

              <AnimateHeight style={height2 === false ? {display: "none"} : {}} duration={200} height={ height2 === true ? 'auto' : 0 }>
                <div style={{display: "inline",  fontSize: "14px", width: "100%"}}>
                  <div style={{textAlign: "center",  margin: "0px auto auto"}}>
                    <div style={{display: "inline",  width: "100%", margin: "-15px auto"}}>
                      <div style={{backgroundColor: '#fff'}}>
                        <span>Miles: <b>6,000 / {countup(bonusData.totalMiles)}</b></span>
                      </div>
                      <div style={{display: "grid", gridTemplateColumns: "90% 10%", backgroundColor: '#fff'}}>
                        <div className="progress-bar">
                          <div className="progress-bar-fill" style={{width:`${showMilesNum}%`}}></div>
                        </div>
                        <div style={{ justifyContent: "flex-end"}}>
                          <img src={bonusData.totalMiles >= 6000 ? greenCircle : emptyCircle} style={{height: "20px", borderRadius: "50%", background: "#cacaca"}} alt='status'/>
                        </div>
                      </div>
                    </div>
                  </div> 
                  <div style={{textAlign: "center",  margin: "0px auto auto"}}>
                    <div style={{display: "inline",  width: "100%"}}>
                      <div style={{backgroundColor: '#fff'}}>
                        <span>Motive Score: <b>90 / {countup(bonusData.motiveScore)}</b></span>
                      </div>
                      <div style={{display: "grid", gridTemplateColumns: "90% 10%", backgroundColor: '#fff'}}>
                        <div className="progress-bar">
                          <div className="progress-bar-fill" style={{width:`${showMotiveNum}%`}}></div>
                        </div>
                        <div style={{ justifyContent: "flex-end"}}>
                          <img src={bonusData.motiveScore >= 90 ? greenCircle : emptyCircle} style={{height: "20px", borderRadius: "50%", background: "#cacaca"}} alt='status'/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{display: "flex", textAlign: "center", margin: "0px auto 5px"}}>
                    <div style={{display: "inline", width: "100%", fontSize: "14px"}}>
                      <div style={{ margin: "auto auto 20px", display: "grid", gridTemplateColumns: "90% 10%"}}>
                        <div style={{backgroundColor: '#fff'}}>
                          <span>Safety Videos:&nbsp; </span>
                          {bonusData.watchedVideos === 'Y' ? <span style={{color: "#48b02c", textShadow: "0px 1px 1.5px #fff"}}>Completed</span> : <span style={{color: "#cb2728", textShadow: "0px 1px 1.5px #00000014"}}>Action Needed</span>}
                        </div>
                          <img src={bonusData.watchedVideos === "Y" ? greenCircle: emptyCircle} style={{margin: "auto", height: "20px", borderRadius: "50%", background: "#cacaca"}} alt="icon"/>
                      </div>
                      <div style={{ margin: "auto", display: "grid", gridTemplateColumns: "90% 10%"}}>
                        <div style={{backgroundColor: '#fff'}}>
                          <span>No Accidents:&nbsp;</span>
                          {bonusData.noAccident ? <span style={{color: "#48b02c", textShadow: "0px 1px 1.5px #fff"}}>On Track</span> : <span></span>}
                        </div>
                        <img src={bonusData.noAccident === "Y" ? greenCircle: emptyCircle} style={{margin: "auto", height: "20px", borderRadius: "50%", background: "#cacaca"}} alt="icon"/>
                      </div>
                    </div>
                  </div>
                  <Link to="/safetybonus" className='western-button-link'>
                    <div className="green" style={{background: "#E5F4E0", boxShadow: "0 4px 4px rgba(0,0,0,0.25)", marginBottom: "15px", textAlign: "center"}}>
                      <p style={{width: "100%"}}>More Details</p>
                    </div>
                  </Link>
                </div>
              </AnimateHeight>

              <div style={{background: "#fff", marginTop: "-18px"}}>
                <img src={upArrow} style={ height2 === false ? {height: "12px",  margin: "0px auto", transform: "rotate(180deg)"} : {height: "12px",  margin: "0px auto"}} alt="arrow" />
              </div>
              </>}
            </div>
            }
              {/* <img src={chevronRight} style={{ height: "1.8vh"}} alt="arrow"/> */}
          </div>
        </div> : null}

        <Link to="/currentload" className='western-button-link'>
          <div className="green" style={{background: "#E5F4E0", boxShadow: "0 4px 4px rgba(0,0,0,0.25)", marginTop: "15px", marginBottom: "15px"}}>
            <img src={trailer} style={{height: "18px"}} alt="trailer" className="link-icon" /><p>View Current Load</p>
          </div>
        </Link>

        <div className='western-button-link' onClick={() => setShowFuel(!showFuel)}>
          <div style={{background: "#fff", boxShadow: "0 4px 4px rgba(0,0,0,0.25)", marginTop: "15px", marginBottom: "15px"}}>
            <img style={{height: "18px"}} alt="key" className="link-icon" src={gasoline} /><p>Request Fuel Solution</p>
          </div>
        </div>

        <Link className="western-button-link" to="/registrations">
          <div style={{background: "#fff", boxShadow: "0 4px 4px rgba(0,0,0,0.25)", marginTop: "15px", marginBottom: "15px"}}>
            <img alt="key" className="link-icon" src={documentIcon} /><p>View Registrations</p>
          </div>
        </Link>
        
        <div style={{ marginBottom: "14px", textAlign: "center"}} onClick={ e => {setHeight1(!height1); console.log("expanded");}} aria-expanded={ height1 !== false }>
          <div className='western-button-link'>
            <div className="green" id="current-load-button">
              <p style={{paddingLeft: "15px"}}>Need Help?</p>
              <img src={height1 == true ? arrowUp : arrowDown} style={{ height: "2vh", paddingRight: "15px"}} alt="arrow"/>
            </div>
          </div>
        </div>
{/* 
        <div style={{ marginBottom: "14px", textAlign: "center"}} onClick={ e => {}} aria-expanded={ height1 !== false }>
          <div className='western-button-link'>
            <div className="green" id="current-load-button"  >
              <a style={{paddingLeft: "15px"}} href="https://westernexpressdrivers.com/photoupload">Test Deep</a> */}
              {/* <img src={height1 == true ? arrowUp : arrowDown} style={{ height: "2vh", paddingRight: "15px"}} alt="arrow"/> */}
            {/* </div>
          </div>
        </div> */}
        <AnimateHeight style={ height1 === true ? {border: "1px #c3c3c3 solid", overflowY: 'scroll', borderRadius: "10px" } : { overflowY: 'scroll'}} duration={500} height={ height1 === true ? 'auto' : 0 } id="loadSection">
          <HelpDirect/>
        </AnimateHeight>
{/* 
        <div style={{ display: "grid", gridTemplateColumns: "70% 30%" }}>
          <Link to="/editprofile" className='western-button-link'>
              <div className="outline" id="centered">
                  <p>Edit Profile</p>
              </div>
          </Link>
          <div className='western-button-link' onClick={() => setShowLatest(!showLatest)}>
              <div id="centered" style={{ minWidth: "10vw"}}>
                <p><IonIcon icon={ellipsisHorizontal} style={{ color: showLatest ? "#212121" : "#666667", fontSize: 'min(3.6vw, 18px)'}}></IonIcon></p>
              </div>
          </div>
  
        </div> */}
        {/* <div style={{  fontFamily: 'Montserrat, sans-serif', fontWeight: '600', margin: "8px", display: "grid", gridTemplateColumns: "50% 50%"}}>
          <p>Start Date: </p>
          <p>Next Raise In: </p>
        </div> */}
       
      </div>
  
    </IonItem>
  );
};

export default ProfileInfo;
