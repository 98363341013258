import React, { useEffect, useState } from 'react'
import {config} from '../Constants';
import pretripIcon from "../imgs/pretrip.png"
import breakdownIcon from "../imgs/breakdown.png"
import scanIcon from '../imgs/work/qr-scan.png';
import passengerIcon from "../imgs/add-passenger.png"
import camera from "../imgs/camera.png"
import cabin from "../imgs/transport.png"
import inspectionIcon from "../imgs/doc-check.png"
import {AppLauncher} from '@capacitor/app-launcher';
import {ApiService} from "../api/ApiService";
import {Link} from "react-router-dom";
import {UserState, userStore} from "../api/AuthService";
import { toastStore } from '../components/AppToast';
import { userInfo } from 'os';
import { DriverProfile } from '../api/model/DriverProfile';
import { IonPage, IonHeader, IonToolbar, IonContent, IonFooter, createGesture } from '@ionic/react';
import Nav from './Nav';
import Toolbar from './Toolbar';
import { checkOS } from '../components/OSCheck';
import { sidnavStore, SidenavState } from '../api/SidenavStore';



const Trip = () => {

    const {driver, loadNumber}: { driver: DriverProfile, loadNumber: string } = userStore((state: UserState) => ({driver: state.driver, loadNumber: state.loadNumber}));

    async function transfloClick() {
        ApiService.sendEventCommand("Tranflo Route", "eventCheck", "User has been routed to the Transflo app");
        const deepLink = `transflomobileplus://scan?recipientid=WSXI&sourceapp=westerndrivermobile&doctypes=TOD,BOL,TRP&driverid=${localStorage.getItem('username')?.toUpperCase()}&truckid=${userStore.getState().driver!.unit}`;
        AppLauncher.openUrl({url: deepLink});
    }

        const {open, close}: { open: boolean, close: () => void } = sidnavStore((state: SidenavState) => ({open: state.open, close: state.close}));
        const {toggleSidenav}: { toggleSidenav: () => void } = sidnavStore((state: SidenavState) => ({toggleSidenav: state.toggle}));
        
        const ref = React.useRef<HTMLInputElement>(null)
    
        useEffect(() => {
            gestureInit();
        }, [])
    
        const gestureInit = () => {
            const card = ref.current
            if (card) {
                const gesture = createGesture({
                    el: card,
                    gestureName: 'swipeMenu',
                    onMove: (detail) => {
                        console.log("moving");
                    },
                    onEnd: (detail) => {
                        console.log("move ended");
                        toggleSidenav();
                    }                 
                })
                gesture.enable();
            }
        }

    return (
        <IonPage>
        <IonHeader>
            <IonToolbar color="primary">
                <Toolbar/>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen color="light">
        <div ref={ref} onClick={() => close()} style={{height: "80vh"}}>
        <div className="item-container">
            <div className="grid50">
            { checkOS() ? 
            <Link to="/trailertracking" className="image-button">
                <div id="stock-map">
                    <p>Available Trailer</p>
                </div>
            </Link> : <></>
            }
            {driver.division !== "100" && driver.division !== "101" && driver.division !== "800" ?
            <Link to="/photoupload" className="image-button">
                <div id="last-tarp">
                    <p>Tarp Photo</p>
                </div>
            </Link> :                     
            <Link className="western-button-link" to="/leasepurchase">
                <div>
                <img src={inspectionIcon} alt="key" className="link-icon"/><p>Vehicle Inspection<br/><span style={{ color: "#D0A024", fontSize: "12px"}}>Lease Purchase Program Only</span></p>
                </div>
            </Link>}
                <div>
                    <Link className="western-button-link" to="/cabinphoto">
                        <div>
                        <img src={cabin} alt="key" className="link-icon"/><p>Cab Extender Photo</p>
                        </div>
                    </Link>
                    <div className="western-button-link" onClick={e => {transfloClick()}}>
                        <div id="centered">
                            <p style={{ textAlign: "left"}}>Submit Paperwork<br/><span style={{ color: "#D0A024", fontSize: "12px"}}>Transflo</span></p>
                            <img src={scanIcon} alt="scan"/>
                        </div>
                    </div>
                    { localStorage.getItem("type") == "inspector" ? <Link className="western-button-link" to="/borderinspections">
                        <div>
                        <img src={camera} alt="key" className="link-icon"/><p>TLC Photos</p>
                        </div>
                    </Link> : null}
                </div>
                <div>
                    <Link className="western-button-link" to="/passenger">
                        <div>
                        <img src={passengerIcon} alt="key" className="link-icon"/><p>Add Passenger</p>
                        </div>
                    </Link>
                    { driver.division !== "100" && driver.division !== "101" && driver.division !== "800" ?
                    <Link className="western-button-link" to="/leasepurchase">
                        <div>
                        <img src={inspectionIcon} alt="key" className="link-icon"/><p>Vehicle Inspection<br/><span style={{ color: "#D0A024", fontSize: "12px"}}>Lease Purchase Program Only</span></p>
                        </div>
                    </Link>
                    : null}
                </div>
            </div>

            {/* {false ? <p style={{ margin: "0 8px", color: "#5096FF"}}>Pretrip and breakdowns must be completed on the Platform Science tablet.</p>: <div className="grid50">
            <Link to="/pretrip" className='western-button-link'>
                <div>
                    <img src={pretripIcon} alt="pretrip" className="link-icon"/>
                    <p>Pretrip</p>
                </div>
            </Link>
            <Link to="/breakdown" className='western-button-link'>
                <div>
                    <img src={breakdownIcon} alt="breakdown" className="link-icon"/>
                    <p>Breakdown</p>
                </div>
            </Link>
            </div> } */}
        </div>
        </div>
        </IonContent>
        <IonFooter>
            <Nav onSearch={function (target: boolean): void {
                    console.log("Not ready");
                } }/>
        </IonFooter>
        </IonPage>
    )
}

export default Trip
