

import React, {useEffect, useRef, useState, useCallback} from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonItem,
  IonModal,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import {ApiService} from "../api/ApiService";
import arrowImg from '../imgs/arrow.png'
import truck from '../imgs/truck.png'
import trailer from '../imgs/trailer.png'
import navigation from '../imgs/navigation.png'
import {StopDetails as StoredStopInfo} from "../api/model/StopDetails";
import {LoadInfo as StoredLoadInfo} from "../api/model/LoadInfo";
import {DriverProfile} from "../api/model/DriverProfile";
import {UserState, userStore} from "../api/AuthService";
import {Map, AdvancedMarker, useAdvancedMarkerRef, useMap, APIProvider} from '@vis.gl/react-google-maps';
import { PluginListenerHandle } from '@capacitor/core';
import TrailerMarker from '../components/TrailerMarker';
import { decode, encode } from "@googlemaps/polyline-codec";
import { isPlatform } from '@ionic/react';

type trailer = {
  distance: number,
  lastPing: string,
  latitude: number,
  longitude: number,
  asset_id: string,
}

let routePolyline = new google.maps.Polyline({
  path: [],
  geodesic: true,
  strokeColor: "#99ff13",
  strokeOpacity: 1.0,
  strokeWeight: 8,
})

let timer: any = null;

let lati: any = 0;
let long: any = 0

const TrailerTracking: React.FC = () => {
  
  const trailerMap: any = useMap("trailerMap");
  
  const {userInfo}: { userInfo: DriverProfile } = userStore((state: UserState) => ({userInfo: state.driver}));
  const {storedStopInfo}: { storedStopInfo: StoredStopInfo } = userStore((state: UserState) => ({storedStopInfo: state.stopDetails}));
  const {storedLoadInfo}: { storedLoadInfo: StoredLoadInfo } = userStore((state: UserState) => ({storedLoadInfo: state.loadInfo}));
  
  let watchId: any
  let distanceArray: number[] = [];
  let accelHandler: PluginListenerHandle;
  let compassHold = 0;
  const [trailers, setTrailers] = useState<trailer[]>([]);
  const [routedTrailer, setRoutedTrailer] = useState<any>([]);
  const [centerLat, setCenterLat] = useState<number>(0);
  const [centerLong, setCenterLong] = useState<number>(0);
  // const [lati, setLati] = useState<number>(0);
  // const [long, setLong] = useState<number>(0);
  const [distance, setDistance] = useState<number>(0);
  const [dispatchedCustomerCode, setDispatchedCustomerCode] = useState<string>("");
  const [selectTerminal, setSelectTerminal] = useState(true);
  const [heading, setHeading] = useState<any>(0);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [hasPermission, setHasPermission] = useState<boolean>(false)
  const routingView = useRef<boolean>(false);

  
  function successWatch(position: any) {
    // console.log("position", position.coords.latitude, position.coords.longitude);
    setHeading(position.coords.heading === null ? heading : position.coords.heading);
      lati = position.coords.latitude;
      long = position.coords.longitude;
  }
    
  function errorWatch(err: any) {
    console.error(`ERROR(${err.code}): ${err.message}`);
  }
  
  let options = {
    enableHighAccuracy: true,
    timeout: 2000,
    maximumAge: 0,
  };
  
  useEffect(() => {
    let currentLocationId = navigator.geolocation.getCurrentPosition(successWatch, errorWatch)
    if (trailerMap) {
      ApiService.sendEventCommand("Trailer Check", "eventCheck", "User has view the Trailer Tracking page");
      console.log("stop Info", trailerMap);
    }
    return () => {
      clearInterval(timer);
      window.removeEventListener("deviceorientation", (e) => {handler(e)}, true);
      window.removeEventListener("deviceorientationabsolute", handler, true);
    }
  }, [trailerMap]);

  function permission () {
    console.log("permisson here", typeof( DeviceMotionEvent as any ).requestPermission() !== "undefined");
    (DeviceOrientationEvent as any).requestPermission()
    .then((response: any) => {
      if (response == 'granted') {
        console.log("permission");
        window.addEventListener('deviceorientation', (e) => {handler(e)}, true)
      }
    })
    .catch(console.error)
  }

  window.addEventListener("touchmove", (e) => {
    routingView.current = false;
    handler(e)
    // window.setTimeout(() => {
    //   routingView.current = true;
    //   console.log("drag", routingView.current);
    // }, 7000);
  })

  function handler(e: any) {
    let compass = e.webkitCompassHeading || Math.abs(e.alpha - 360);
    if (Number.isNaN(compass)) compass = compassHold
    // console.log("orientation", compass);
    let userMaker = document.getElementById("userMaker");
    if (userMaker !== null) {
      if(trailerMap) {
        let mapHeading = trailerMap.getHeading();
        mapHeading = 360 - mapHeading
        userMaker.style.transform = `rotate(${parseInt(compass) + mapHeading}deg)`
      }
    }
    compassHold = compass;
  }

  function checkForDMLocation() {
    if (typeof (DeviceMotionEvent as any).requestPermission === 'function') {
      permission();
      // window.addEventListener("deviceorientationabsolute", handler, true);
    } else {
      window.addEventListener("deviceorientationabsolute", handler, true);
    }
    let orderNumber: any = storedLoadInfo.orderNumber
    let unitNumber: any = userInfo.unit
    ApiService.trailerLocationGet("trailer tracking", {unitNumber, orderNumber}, true).then(it => {
      findTrailers(it)
    })
    .catch(error => {
      console.error("here it is", error);
      getCurrentDispatch();
    });
  }

  function checkPolyCode(dataPack: any) {
    console.log("data pack", dataPack);
    if (true) {
      findTrailers(it)
    } else {
      getCurrentDispatch();
    }
  }

  function getCurrentDispatch() {
    let currentLocationId = navigator.geolocation.getCurrentPosition(successWatch, errorWatch)
    let stopsArray: any = storedStopInfo
    stopsArray.forEach((element: any) => {
      if (element.sequenceNumber === parseInt(storedLoadInfo.dispatchNumber)) {
        setDispatchedCustomerCode(element.customerCode)
        const idObj: any = {
          polyId: element.customerCode
        }
        findTrailers(idObj)
        // console.log("customerCode", element.customerCode);
      }
    });
  }

  function userCenter(trailerVar?: any) {
    if (routedTrailer.length !== 0 || trailerVar !== undefined) routingView.current = !routingView.current;
    console.log("routingView", routingView.current, routedTrailer);
    let center = new google.maps.LatLng({lat: lati, lng: long})
    trailerMap.panTo(center)
    trailerMap.setCenter(center)
    trailerMap.setZoom(18)
  }

  function setPolygonCenter() {
    let center = new google.maps.LatLng({lat: centerLat, lng: centerLong})
    trailerMap.panTo(center)
    trailerMap.setZoom(16)
  }

  function findTrailers(idObj: any) {
    watchId = navigator.geolocation.watchPosition(successWatch, errorWatch, options)
    ApiService.trailerCustomerPoly("trailer tracking", {customerCode: idObj.polyId}).then(it => {
      processTrailers(it);
    });
  }
  
  async function processTrailers(trailerList: any) {
    let tempArray: any = [];
    trailerList.trailers.forEach((element: any) => {
      let tempObj = {trailerId: '', lastPing: '', latitude: 0, longitude: 0, status: ''}
      tempObj = element;
      tempArray.push(tempObj);
    });
    setTrailers(tempArray);
    setCenterLat(tempArray[0].centroid[0])
    setCenterLong(tempArray[0].centroid[1])
    let center = new google.maps.LatLng({lat: tempArray[0].centroid[0], lng: tempArray[0].centroid[1]})
    // console.log("Trailer loaded", trailerMap.useApiLoadingState(), center);
    trailerMap.setCenter(center)
  }

  function getRoute(trailer?: any) {
    if (lati !== 0 && long !== 0) {
      console.log("route call", lati, long);
      let tempCoords: any = {
        startLati: lati.toString(),
        startLong: long.toString(),
        destLati: trailer.latitude || routedTrailer.latitude,
        destLong: trailer.longitude || routedTrailer.longitude,
        travelMode: "DRIVE"
      }
      ApiService.routeCompute("Trailer Check", tempCoords)
      .then(it => {decodePath(it)})
    }
  }

  
  function decodePath(polylinePath:any) {
    setDistance(polylinePath.distanceMeters)
    let setTempPolyline: any = polylinePath.polyline.encodedPolyline
    let tempPath: any = decode(setTempPolyline, 5)
    let llArray: google.maps.LatLng[] = [];
    update(tempPath);
    tempPath.forEach((element:any) => {
      let tempObj = new google.maps.LatLng(element[0], element[1])
      llArray.push(tempObj)
    });
    routePolyline.setPath(llArray)
    routePolyline.setMap(trailerMap);
    trailerMap.setTilt(55)
    // console.log("polyline", routePolyline, routePolyline.getPath().getArray());
    // let mapElement = document.getElementById("trailerMap")
  }

  function update(path: any) {
    const mark1: any =  {lat: path[0][0], lng: path[0][1]}
    const mark2: any = {lat: path[1][0], lng: path[1][1]}
    // console.log("heading path", mark1, mark2);
    let heading = google.maps.geometry.spherical.computeHeading(mark1, mark2);
    if (heading < 0) heading = Math.round(360 + heading)
    // setRouteHeading();
    // console.log("heading", heading, routingView);
    if (routingView.current === true) {
      trailerMap.setHeading(heading);
      let center = new google.maps.LatLng({lat: lati, lng: long})
      trailerMap.setCenter(center)
    }
  }

  function trailerPoint(trailerInfo: any) {
    
    console.log("trailerInfo", trailerInfo);
    setRoutedTrailer(trailerInfo);
    clearInterval(timer);
    timer = setInterval(() => {
      // console.log("called in timer", trailerInfo);
      if (trailerInfo.length !== 0) {
        getRoute(trailerInfo);
        console.log("lati long", lati, long);
      }
    }, 2000, lati, long)
    console.log();
    userCenter(trailerInfo);
  }

  const exitRoute = () => {
    navigator.geolocation.clearWatch(watchId);
    setRoutedTrailer([])
    trailerMap.setZoom(17);
    trailerMap.setHeading(0);
    let center = new google.maps.LatLng({lat: centerLat, lng: centerLong})
    trailerMap.setCenter(center);
    routingView.current = false;
    trailerMap.setTilt(0)
    clearInterval(timer);
    routePolyline.setPath([])
    routePolyline.setMap(null);
  }

  return (
    <IonPage>
    <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start" onClick={() => {}}>
            <IonBackButton defaultHref="/Home" text=""/>
          </IonButtons>
          <IonTitle>Available Trailers</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {/* MAP */}
        <IonModal isOpen={selectTerminal} cssClass='my-custom-class'>
          <IonContent>
            <IonCard style={{marginTop: "30px"}}>
              <IonCardHeader>
                  <div className='item-container'>
                    <h1>Please Read the Following:</h1>
                  </div>
              </IonCardHeader>
              <IonCardContent>
                <div className='item-container'>
                  <p>UPON SELECTING A TRAILER, ALWAYS BE SURE TO CONFIRM IT IS ACTUALLY EMPTY. IF TRAILER IS SEALED, DO NOT TAKE TRAILER OR TAMPER WITH OR REMOVE SEAL.</p>
                </div>
              </IonCardContent>
            </IonCard>
            <IonButton style={{height: "8%"}} onClick={e => {checkForDMLocation(); setSelectTerminal(false)}}>Acknowledge</IonButton>
          </IonContent>
        </IonModal>
        <div style={{position: 'relative', height: '100%', top: 0, zIndex: 2, width: "100%", overflow: "hidden", scrollbarWidth: "none"}}>
          <Map  
            style={{width: '100vw', height: '100vh'}}
            defaultCenter={{lat: lati, lng: long}}
            defaultZoom={15}
            defaultTilt={16}
            mapTypeControl={true}
            gestureHandling={'greedy'}
            id={'trailerMap'}
            mapId={"4075003bf6f2d132"}
            disableDefaultUI={true}
            >
              <AdvancedMarker position={{lat: lati, lng: long}} ref={markerRef}>
                {/* <img src={arrowImg} id='userMaker' style={{width: "25px"}}></img> */}
                <div style={{position: "relative"}}>
                  <div className="userIcon"></div>
                  <div className='box' id='userMaker'></div>
                </div>
              </AdvancedMarker>
              { routedTrailer.length !== 0 ? <TrailerMarker element={routedTrailer}/> : trailers.map((element) => <TrailerMarker element={element} onSelect={trailerPoint}/>)}
          </Map>
        </div>
        <div style={{zIndex: 3, width: "100%", position: "relative", pointerEvents: "none", display: "flex", justifyContent: "end"}}>
          <div style={{bottom: "10px", position: "absolute", display: "flex"}}>
            { routedTrailer.length !== 0 ? <div style={{margin: "auto"}}>
              <IonItem lines="none" style={{"--background": "#272727", borderRadius: "6px", pointerEvents: "auto"}}>
                <div style={{display: "flex"}}>
                  <span style={{color: "#b3b3b3", fontSize: '15px', margin: "30px 5px 30px 10px"}}><b>Trailer:</b> {routedTrailer.trailerId}</span>
                  <span style={{color: "#b3b3b3", fontSize: '15px', margin: "30px 15px 30px 10px"}}><b>Distance:</b> {distance} m</span>
                  <div style={{margin: "auto 5px"}}>
                    <button className='western-button-cancel' onClick={exitRoute}>Exit</button>
                  </div>
                </div>
              </IonItem>
            </div> : null }
            <div style={{}}>
              <IonItem lines="none" color="none">
                <button style={{background: "white", borderRadius: "30px", width: "60px", height: "60px", boxShadow: "0px 0px 4px black", margin: "10px 2px", pointerEvents: "auto"}} onClick={e => {userCenter()}}>
                  { routingView.current === true ? <img src={navigation} width={"30px"}/>
                  : <img src={arrowImg} width={"30px"}></img>}
                </button>
              </IonItem>
              <IonItem lines="none" color="none">
                <button style={{background: "#99ff13", borderRadius: "30px", width: "60px", height: "60px", boxShadow: "0px 0px 4px black", margin: "10px 2px", pointerEvents: "auto"}} onClick={e => {routingView.current = false; setPolygonCenter()}}>
                  <img src={trailer} width={"30px"}/>
                </button>
              </IonItem>
            </div>
          </div>
        </div>
      </IonContent>
      
    </IonPage>
  );
};

export default TrailerTracking

  
