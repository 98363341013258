import React, {useEffect, useState} from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonToast, IonButtons, IonBackButton, IonModal } from '@ionic/react';
import {config} from '../Constants'
import payrollFAQ from "../imgs/payroll_faq.png"
import cone from "../imgs/cone.png"
import cone2 from "../imgs/pine-cone.png"
import trophy from "../imgs/trophy.png"
import check from "../imgs/check.png"
import close from "../imgs/close.png"
import playStore from '../imgs/google-play.png'
import appleStore from '../imgs/apple-logo.png'
import bell from "../imgs/bell.png"
import chevronRight from "../imgs/chevron-right.png";
//Google Analytics:
import { ApiService } from '../api/ApiService';
import {toastStore} from "../components/AppToast";
import Speedometer, { Background, Arc, Needle, Progress, Marks } from 'react-speedometer';
import { userStore, UserState } from '../api/AuthService';
import CountUp from 'react-countup';
import { AppLauncher } from '@capacitor/app-launcher';

let interval: any
let i = 0;

const SafetyBonus: React.FC = () => {
    const [showToast, setShowToast] = useState(false);
    const [toast, setToast] = useState('');
    const {division} = userStore((state: UserState) => ({division: state.driver?.division}));
    const [CPM, setCPM] = useState<string>("");
    const [lifeTimeMiles, setLifeTimeMiles] = useState<number>(0);
    const [bonusData, setBonusData] = useState<any>({});
    const [bonusEligible, setBonusEligible] = useState<boolean>(false);
    const [showNum, setShowNum] = useState(0);
    const [coneType, setConeType] = useState<boolean>(true);
    const [showHowTo, setShowHowTo] = useState<boolean>(false);
    const [showMotiveModal, setShowMotiveModal] = useState<boolean>(false)

    useEffect(() => {
        checkDivision();
        ApiService.getGamificationDriver2("game")
            .then(it => parseGameData(it))
            .catch(() => console.log("No Data"));
        ApiService.getSafetyBonus("profileInfo").then(it => parseBonusData(it));
    }, []);

    const checkDivision = () => {
        if (division === "VNASH" || division === "NER" || division === "VCT" || division === "VVA" || division === "100") {
            setCPM("05");
        } else if (division === "FNASH" || division === "FNR" || division === "500") {
            setCPM("07");
        }
    }

    const countup = (number: number) => {
        return (
          <CountUp end={number} duration={1.2} delay={0.2} separator=','/>
        )
    }

    function parseBonusData(data :any) {
        let tempData: any = data
        console.log("bonus data", tempData);
        let tempObj: any = {
          totalMiles: tempData.TOTAL_DISPMILES,
          motiveScore: tempData.MOTIVE_SCORE,
          noAccidents: tempData.ACCIDENT_ELIGIBLE,
          hasTenure: tempData.TENURE_ELIGIBLE,
          watchedVideos: tempData.ASSIGNMENT_ELIGIBLE, // NEEDS ITS DATA
          toBeEarned: tempData.SOLO_BONUS_AMT,
          totalBonus: tempData.EARNINGS_TO_DATE,
          newDate: new Date(tempData.ELIGIBLE_MONTH_START).toString()
        }
        console.log("pay miles", Math.abs(tempData.TOTAL_DISPMILES / 6000) * 100);
        interval = setInterval(() => {increment(Math.abs(tempData.TOTAL_DISPMILES / 6000) * 100)}, 20);
        setBonusData(tempObj);
        if (tempData.ACCIDENT_ELIGIBLE === "Y" && tempData.TENURE_ELIGIBLE === "Y") {
          setBonusEligible(true);
        } else {
          setBonusEligible(false);
        }
    }

    function increment(progress: number) {
        i = i + 1;
        if (i >= progress) {
          clearInterval(interval)
        }
        setShowNum(i);
    }

    function parseGameData(data: any) {
        let tempData: any = data
        console.log("tempData", tempData);
        setLifeTimeMiles(tempData.MILES_LIFETIME);
    }

    async function motiveClick() {
        if (config.isPlatformSci === true) {
            ApiService.sendEventCommand("Motive url", "eventCheck", "User has been routed to the Motive app");
            const deepLink = `kptrkn://performance/home`;
            AppLauncher.openUrl({url: deepLink});
        }
        else {
            setShowMotiveModal(true)
        }
    }

    async function infinitiClick() {
        if (config.isPlatformSci === false) {   
            ApiService.sendEventCommand("Infiniti url", "eventCheck", "User has been routed to the Infiniti website");
            const deepLink = `https://westernexpress.infinit-i.net/#/login`;
            AppLauncher.openUrl({url: deepLink});
        }
        else {
            toastStore.getState().setToast('This link cannot be opened on this device');
        }
    }

  return (
    <IonPage>
        <IonHeader >
            <IonToolbar color="primary">
            <IonButtons slot="start">
                <IonBackButton defaultHref="/Home"  text=""/>
            </IonButtons>
            <IonTitle>Safety Bonus Details</IonTitle>
            </IonToolbar>
        </IonHeader>

        <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={toast}
            color="danger"
            duration={3000}
        />

        <IonModal isOpen={showMotiveModal}>
            <IonHeader>
                    <IonToolbar color="primary">
                    <p style={{fontFamily: "Montserrat, sans-seriff", padding: "0 8px"}} onClick={() => setShowMotiveModal(false)}>Back</p>
                    </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem color='light' lines="none">
                <div className='item-container'>
                    <h1><strong>View Motive Score</strong></h1>
                        <p className='subtitle'><b>Attention!</b> The Motive app is already available on the PlatSci tablet in your unit. If you wish to use the app on your personal device, select one of the options to download the app to your device.</p>
                    <div style={{display: "grid", gridTemplateColumns: "50% 50%", gap: "2vw", justifyContent: "center", textAlign: "center"}}>
                        <div className="space-between" style={{background: "#fff", borderRadius: "10px", marginTop: "10px"}} onClick={e => {AppLauncher.openUrl({url: "https://play.google.com/store/apps/details?id=com.keeptruckin.android&pcampaignid=web_share"});}}>
                            <p style={{paddingLeft: "10px", fontSize: "12px"}}>Google Play Store</p>
                            <img src={playStore} style={{ height: "30px", paddingRight: '8px'}}alt="arrow"/>
                        </div>
                        <div className="space-between" style={{background: "#fff", borderRadius: "10px", marginTop: "10px"}} onClick={e => {AppLauncher.openUrl({url: "https://apps.apple.com/us/app/motive-driver/id706401738"})}}>
                            <p style={{paddingLeft: "10px", fontSize: "12px"}}>Apple App Store</p>
                            <img src={appleStore} style={{ height: "25px", paddingRight: '8px'}}alt="arrow"/>
                        </div>
                    </div>
                </div>
            </IonItem> 
            </IonContent>
        </IonModal>

        <IonContent fullscreen color="light">
            { (!bonusEligible && bonusData.noAccidents === "Y") || showHowTo ?
            <IonItem lines="none" color='white'>
                <div className="item-container">
                    <div className="game-stat" style={{display: "grid"}}>
                        <div style={{backgroundColor: '#e5f4e0', justifyContent: "center", borderRadius: "35px"}}>
                            <h1><strong>What is the Safety Bonus?</strong></h1>
                        </div>
                    </div>
                    <br/>
                    <div style={{textAlign: "center"}}>
                        <p><span style={{fontWeight: "600", fontSize: "16px"}}>At Western Express, we appreciate our drivers!</span></p>
                        <p><span style={{textAlign: "center"}}>When you make safety a priority, everyone wins. Not only does safe driving protect you and others on the road, but now it also pays! We’re excited to offer our Safety Bonus Program—giving you the opportunity to earn extra just for doing what you do best: driving safely.</span></p>
                        <p><span style={{textAlign: "center"}}>Meet the requirements below, and you’ll receive an extra $0.{CPM} CPM for the month!</span></p>
                        <p><span style={{textAlign: "center"}}>Safety Bonuses are paid on the 2nd Friday date after the last day of the month. You must be employed at schedule time of payment.</span></p>
                        <div>
                            <div style={{display: "grid", gridTemplateColumns: "20% 80%", marginTop: "20px", marginBottom: "20px"}}>
                                <img style={bonusData.hasTenure === "Y" ? {height: "20px", textAlign: "start"} : {height: "12px", textAlign: "start", marginLeft: "4px"}} src={bonusData.hasTenure === "Y" ? check : close}/>
                                <div style={{fontSize: "12px", fontWeight: "600"}}>6 months with Western Express, Inc</div>
                            </div>
                            <div style={{display: "grid", gridTemplateColumns: "20% 80%", marginBottom: "20px"}}>
                                <img style={bonusData.totalMiles >= 6000 ? {height: "20px", textAlign: "start"} : {height: "12px", textAlign: "start", marginLeft: "4px"}} src={bonusData.totalMiles >= 6000 ? check : close}/>
                                <div style={{fontSize: "12px", fontWeight: "600"}}>6,000 miles for the month</div>
                            </div>
                            <div style={{display: "grid", gridTemplateColumns: "20% 80%", marginBottom: "20px"}}>
                                <img style={bonusData.noAccidents === "Y" ? {height: "20px", textAlign: "start"} : {height: "12px", textAlign: "start", marginLeft: "4px"}} src={bonusData.noAccidents === "Y" ? check : close}/>
                                <div style={{fontSize: "12px", fontWeight: "600"}}>No accidents**</div>
                            </div>
                            <div style={{display: "grid", gridTemplateColumns: "20% 80%", marginBottom: "20px"}}>
                                <img style={bonusData.motiveScore >= 90 ? {height: "20px", textAlign: "start"} : {height: "12px", textAlign: "start", marginLeft: "4px"}} src={bonusData.motiveScore >= 90 ? check : close}/>
                                <div style={{fontSize: "12px", fontWeight: "600"}}>Motive score of 90 or higher</div>
                            </div>
                            <div style={{display: "grid", gridTemplateColumns: "20% 80%", marginBottom: "20px"}}>
                                <img style={bonusData.watchedVideos === "Y" ? {height: "20px", textAlign: "start"} : {height: "12px", textAlign: "start", marginLeft: "4px"}} src={bonusData.watchedVideos === "Y" ? check : close}/>
                                <div style={{fontSize: "12px", fontWeight: "600"}}>Complete all monthly safety videos</div>
                            </div>
                        </div>
                        <div>
                            <p style={{opacity: "50%", fontSize: "12px"}}>**An accident will disqualify you from participating for a month it occurred in as well as the following 2 months.**</p>
                        </div>
                    </div>
                </div>
            </IonItem>
            : bonusData.noAccidents === "N" ? 
            <IonItem lines="none" color='white'>
                <div className="item-container">
                    <div className="game-stat" style={{display: "grid"}}>
                        <div style={{backgroundColor: '#e5f4e0', justifyContent: "center", borderRadius: "35px"}}>
                            <h1><strong>What is the Safety Bonus?</strong></h1>
                        </div>
                    </div>
                    <br/>
                    <div style={{textAlign: "center"}}>
                        <p><span style={{fontWeight: "600", fontSize: "16px"}}>At Western Express, we appreciate our drivers!</span></p>
                        <p><span style={{textAlign: "center"}}>When you make safety a priority, everyone wins. Not only does safe driving protect you and others on the road, but now it also pays! We’re excited to offer our Safety Bonus Program—giving you the opportunity to earn extra just for doing what you do best: driving safely.</span></p>
                        <p><span style={{textAlign: "center"}}>Meet the requirements below, and you’ll receive an extra $0.{CPM} CPM for the month!</span></p>
                        <p><span style={{textAlign: "center"}}>Safety Bonuses are paid on the 2nd Friday date after the last day of the month. You must be employed at schedule time of payment.</span></p>
                        <div style={{background: "#890000ab", height: "100px", alignContent: "center", borderRadius: "35px"}}>
                            <p><span style={{textAlign: "center", color: "#fff", fontSize: "18px", padding: "10px"}}>Due to the accident, you will be eligible on: {bonusData.newDate.slice(0, 16)}</span></p>
                        </div> 
                        <div>
                            <div style={{display: "grid", gridTemplateColumns: "20% 80%", marginTop: "20px", marginBottom: "20px"}}>
                                <img style={bonusData.hasTenure === "Y" ? {height: "20px", textAlign: "start"} : {height: "12px", textAlign: "start", marginLeft: "4px"}} src={bonusData.hasTenure === "Y" ? check : close}/>
                                <div style={{fontSize: "12px", fontWeight: "600"}}>6 months with Western Express, Inc</div>
                            </div>
                            <div style={{display: "grid", gridTemplateColumns: "20% 80%", marginBottom: "20px"}}>
                                <img style={bonusData.totalMiles >= 6000 ? {height: "20px", textAlign: "start"} : {height: "12px", textAlign: "start", marginLeft: "4px"}} src={bonusData.totalMiles >= 6000 ? check : close}/>
                                <div style={{fontSize: "12px", fontWeight: "600"}}>6,000 miles for the month</div>
                            </div>
                            <div style={{display: "grid", gridTemplateColumns: "20% 80%", marginBottom: "20px"}}>
                                <img style={{height: "12px", textAlign: "start", marginLeft: "4px"}} src={close}/>
                                <div style={{fontSize: "12px", fontWeight: "600", color: "#d04e4e"}}>No accidents**</div>
                            </div>
                            <div style={{display: "grid", gridTemplateColumns: "20% 80%", marginBottom: "20px"}}>
                                <img style={bonusData.motiveScore >= 90 ? {height: "20px", textAlign: "start"} : {height: "12px", textAlign: "start", marginLeft: "4px"}} src={bonusData.motiveScore >= 90 ? check : close}/>
                                <div style={{fontSize: "12px", fontWeight: "600"}}>Motive score of 90 or higher</div>
                            </div>
                            <div style={{display: "grid", gridTemplateColumns: "20% 80%", marginBottom: "20px"}}>
                                <img style={bonusData.watchedVideos === "Y" ? {height: "20px", textAlign: "start"} : {height: "12px", textAlign: "start", marginLeft: "4px"}} src={bonusData.watchedVideos === "Y" ? check : close}/>
                                <div style={{fontSize: "12px", fontWeight: "600"}}>Complete all monthly safety videos</div>
                            </div>
                        </div>
                        <div>
                            <p style={{opacity: "50%", fontSize: "12px"}}>**An accident will disqualify you from participating for a month it occurred in as well as the following 2 months.**</p>
                        </div>
                    </div>
                </div>
            </IonItem>
            : <div style={{display: "inline", fontFamily: "Inria Sans"}}>
                <div style={{background: "#fff", borderRadius: "35px", padding: "5px", margin: "15px", boxShadow: "0px 4px 7px 0px #00000044"}}>
                    <div style={{margin: "auto", textAlign: "center"}}>
                        <span style={bonusData.motiveScore < 90 || bonusData.watchedVideos === "N" ? {color: "#890000ab", fontSize: "26px"} : {fontSize: "26px"}}><b>{bonusData.motiveScore < 90 || bonusData.watchedVideos === "N" ? "Items Need Attention!" : "On Tracks"}</b></span>
                    </div>
                </div>

                <div style={{display: "flex", gridTemplateColumns: "33% 33% 33%", marginBottom: "10%", fontSize: "15px"}}>
                    <div style={{textAlign: "center", display: "inline", margin: "auto"}}>
                        <div style={{margin: "auto auto 50%"}}>
                            <span style={{fontWeight: "600"}}>Safety Bonus:<br/><span style={{fontWeight: "600"}}>$0.{CPM}</span></span>
                        </div>
                        <div style={{margin: "auto"}}>
                            <span style={{fontWeight: "600"}}>YTD Miles:<br/><span style={{fontWeight: "600"}}>{countup(lifeTimeMiles)} mi</span></span>
                        </div>
                    </div>
                    <div style={{textAlign: "center", margin: "auto"}}>
                        <Speedometer max={100} value={showNum} fontFamily='squada-one' width={150}>
                            <Background color="transparent" />
                            <Arc color='#000' opacity={100} arcWidth={4}/>
                            <Needle baseWidth={5} offset={25} baseOffset={-10} circleRadius={15} circleColor='black' color='black'/>
                            <Progress arcWidth={20} color="#99cc00" lineCap="butt" />
                            <Marks step={20} fontSize={0} lineColor='black' lineSize={20}/>
                        </Speedometer>
                        <div style={{boxShadow: "1px -25px 8px #000000aa", width: "60%", height: "1px", margin: "auto"}}></div>
                    </div>
                    <div style={{textAlign: "center", display: "inline", margin: "auto"}}>
                        <div style={{margin: "auto auto 50%"}}>
                            <span style={{fontWeight: "600"}}>Current Miles:<br/><span style={{fontWeight: "600"}}>{countup(bonusData.totalMiles)} mi</span></span>
                        </div>
                        <div style={{margin: "auto"}}>
                            <span style={{fontWeight: "600"}}>Miles to Bonus:<br/><span style={{fontWeight: "600"}}>{countup(6000 - (bonusData.totalMiles >= 6000 ? 6000 :  bonusData.totalMiles))} mi</span></span>
                        </div>
                    </div>
                </div>

                <div style={{marginBottom: "20px"}}>
                    <div style={{backgroundColor: '#e5f4e0', textAlign: "center", boxShadow: "0px 4px 7px 0px #00000044"}}>
                        <div style={{paddingTop: "10px", textShadow: "1px 1px 3px #00000044", marginBottom: "10px"}}>
                            <span style={{fontWeight: "600", fontSize: "16px"}}>Month-To-Date Potential Payout</span><br/>
                            <span style={{fontSize: "35px", fontWeight: "600"}}><b>${bonusData.toBeEarned?.toFixed(2)}</b></span>
                        </div>
                        <div style={{paddingBottom: "10px", textShadow: "1px 1px 3px #00000044", marginBottom: "10px"}}>
                            <span style={{fontWeight: "600", fontSize: "16px"}}>Earnings Year-To-Date</span><br/>
                            <span style={{fontSize: "35px", fontWeight: "600"}}><b>${bonusData.totalBonus?.toFixed(2)}</b></span>
                        </div>
                    </div>
                </div>

                <div>
                    <div style={{display: "grid", gridTemplateColumns: "50% 50%", textAlign: "center"}}>
                        <div className='western-button-link' style={{gap: "2vw"}}>
                            <div className="game-stat" style={ bonusData.motiveScore < 90 ? {display: "grid", height: "60px", borderRadius: "25px", background: "#890000ab", boxShadow: "0 4px 4px rgba(0,0,0,0.25)"} : {display: "grid", height: "60px", borderRadius: "25px", boxShadow: "0 4px 4px rgba(0,0,0,0.25)"}}>
                                <div style={{margin: "auto", justifyContent: "center", fontSize: '16px', background: "unset"}}>
                                    {bonusData.motiveScore < 90 ? <img src={bell} style={{height: "25px", margin: "auto 15px auto auto"}} alt="bell" /> : null}<strong>{bonusData.motiveScore} Motive Score</strong>
                                </div>
                            </div>
                        </div>
                        <div className='western-button-link' style={{gap: "2vw"}}>
                            <div className="game-stat" style={{display: "grid", height: "60px", borderRadius: "25px", boxShadow: "0 4px 4px rgba(0,0,0,0.25)", marginBottom: "20px"}}>
                                <div style={{margin: "auto", justifyContent: "center", fontSize: '16px'}}>
                                    <strong>Miles on Track <br/><b>{bonusData.totalMiles >= 6000 ? 6000 : bonusData.totalMiles} / 6000</b></strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display: "grid", gridTemplateColumns: "50% 50%", textAlign: "center"}}>
                        <div className='western-button-link' style={{gap: "2vw"}}>
                            <div className="game-stat" style={ bonusData.watchedVideos === 'N' ? {display: "grid", height: "60px", borderRadius: "25px", background: "#890000ab", boxShadow: "0 4px 4px rgba(0,0,0,0.25)"} : {display: "grid", height: "60px", borderRadius: "25px", boxShadow: "0 4px 4px rgba(0,0,0,0.25)"}}>
                                <div style={{margin: "auto", justifyContent: "center", fontSize: '16px', background: "unset"}}>
                                    {bonusData.watchedVideos === 'N' ? <img src={bell} style={{height: "25px", margin: "auto 15px auto auto"}} alt="bell" /> : null}<strong>Safety Videos</strong>
                                </div>
                            </div>
                        </div>
                        <div className='western-button-link' style={{gap: "2vw"}}>
                            <div className="game-stat" style={{display: "grid", height: "60px", borderRadius: "25px", boxShadow: "0 4px 4px rgba(0,0,0,0.25)"}}>
                                <div style={{margin: "auto", justifyContent: "center", fontSize: '16px'}}>
                                    <strong>No Accidents</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='western-button-link' style={{ margin: "20px auto 30px auto", width: "50%"}} onClick={e => setShowHowTo(true)}>
                        <div className="game-stat green" style={{display: "grid", height: "60px", borderRadius: "25px", boxShadow: "0 4px 4px rgba(0,0,0,0.25)"}}>
                            <div style={{margin: "auto", justifyContent: "center", fontSize: '16px', background: "unset"}}>
                                <img src={payrollFAQ} style={{height: "30px", margin: "auto 15px auto auto"}} alt="bell" /><strong>How to Qualify</strong>
                            </div>
                        </div>
                    </div>

                    <div className='western-button-link' style={{ margin: "auto auto 30px auto", width: "50%"}}>
                        <div className="game-stat green" style={{display: "grid", height: "60px", borderRadius: "25px", boxShadow: "0 4px 4px rgba(0,0,0,0.25)"}}  onClick={e => {infinitiClick()}}>
                            <div style={{margin: "auto", justifyContent: "center", fontSize: '16px', background: "unset"}}>
                                <img src={coneType ? cone : cone2} style={{height: "30px", margin: "auto 15px auto auto"}} alt="bell" /><strong>Safety Videos</strong>
                            </div>
                        </div>
                    </div>
                        
                    <div className='western-button-link' style={{ margin: "auto auto 30px auto", width: "50%"}} onClick={e => {motiveClick()}}>
                        <div className="game-stat green" style={{display: "grid", height: "60px", borderRadius: "25px", boxShadow: "0 4px 4px rgba(0,0,0,0.25)"}}>
                            <div style={{margin: "auto", justifyContent: "center", fontSize: '16px', background: "unset"}}>
                                <img src={trophy} style={{height: "30px", margin: "auto 15px auto auto"}} alt="bell" /><strong>My Motive</strong>
                            </div>
                        </div>
                    </div>
                </div>

            </div> }
        </IonContent>
    </IonPage>
  );
};

export default SafetyBonus;
