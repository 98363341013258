import moment from "moment";

export class DateUtils {

    public static US_DATE_FORMAT = 'MM/DD/YYYY';

    public static formatDate(date?: string): string {
        if (!date) {
            return '';
        }

        return moment(date).format(DateUtils.US_DATE_FORMAT);
    }

    public static subtractDays(date: string, daysToSubtract: number): string {
        if (!date) {
            return '';
        }

        return moment(date).subtract(daysToSubtract, 'day').format(DateUtils.US_DATE_FORMAT);
    }

}
