import {create} from "zustand";

export interface SidenavState {
    open: boolean;
    toggle: () => void;
    close: () => void;
}

export const sidnavStore = create<SidenavState>((set, get) => ({
    open: false,
    toggle: () => {
        set({open: !get().open});
    },
    close: () => {
        set({open: false});
    }
}));
