import { useState, useEffect, createRef } from 'react';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonModal, IonButtons, IonBackButton, IonCardContent, IonTextarea, IonItemGroup, IonItemDivider, IonItem, IonLabel, IonSearchbar, IonSelect, IonSelectOption, IonToast, IonIcon } from '@ionic/react';
import { base64FromPath } from '@ionic/react-hooks/filesystem';
import {config} from '../Constants';
import CryptoJS from 'crypto-js';
import { camera, cameraOutline, trashOutline, arrowDown, arrowUp } from 'ionicons/icons';
//Google Analytics:
import AnimateHeight from 'react-animate-height';
import {sendErrorMsg} from '../components/DiscordHookError';
import SignaturePad from "react-signature-canvas"
import { Camera } from '@capacitor/camera';
import React from 'react';

// Example images //
import cabLeft from "../imgs/cab_extender/left_side.jpeg";
import cabCenter from "../imgs/cab_extender/center.jpeg";
import cabRight from "../imgs/cab_extender/right_side.jpeg";
import { ApiService } from '../api/ApiService';
import { toastStore } from '../components/AppToast';
import ReactSignatureCanvas from 'react-signature-canvas';

const timages: any[] = [];

interface Photo {
  filepath: string;
  webviewPath?: string;
  data: any;
}

var c = document.getElementById("canv");
// var $ = c!.getContext("2d");
let hashArray: any = [];

const CabinPhoto: React.FC<{moduleHistory: string}> = ({ moduleHistory }) => {

  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorModal2, setShowErrorModal2] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successPhoto, setSuccess] = useState(false);
  const [toast, setToast] = useState<string>("")
  const [photoMsg, setPhotoMsg] = useState<string>("Take photo");
  const [images, setImages] = useState<Photo[]>([]);
  const [userLat, setUserLat] = useState<number>(36.1627);
  const [userLong, setUserLong] = useState<number>(-86.7816);
  const [loadNumber, setLoadNumber] = useState<string>("");
  const [dispatchNumber, setDispatchNumber] = useState<string>("");
  const [unitNumber, setUnitNumber] = useState("");
  const [dmEmail, setDmEmail] = useState<string>("");
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [trailerNumber, setTrailerNumber] = useState("");
  const [dmCode, setDmCode] = useState("");
  const [division, setDivision] = useState("");
  const [height1, setHeight1] = useState(false);
  const [height2, setHeight2] = useState(false);
  const [height3, setHeight3] = useState(false);
  const [driverSignature, setDriverSignature] = useState<any>("");
  const [showDriverSignature, setShowDriverignature] = useState(true);
  const driverSigCanvas = createRef<ReactSignatureCanvas>()
  const [signatureImage, setSignatureImage] = useState<string>("");


  useEffect(() => {
    // get unit number from userProfile
    var data = new FormData()
    data.append("payload", JSON.stringify({ cmds: [{cmd: "driverProfile", args: {driverCode: localStorage.getItem('username')?.toUpperCase(), loginToken: localStorage.getItem('token')}}] }))
    const requestOptions = {
      method: 'POST',
      headers: {},
      body: data
    };
    //console.log(requestOptions)
    fetch(config.url.BASE_URL, requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json.results[0].errcode === 'NOTLOGGEDIN') {localStorage.removeItem('token')} 
        else if (json.results[0].errmsg) {
          
          console.error("Error: ", json.results[0].errmsg);
        }
          else {
            setDmCode(json.results[0].dmCode)
            setDmEmail(json.results[0].dmEmail)
            setTrailerNumber(json.results[0].trailer)
            setUnitNumber(json.results[0].unit)
            getLoadNumber(json.results[0].unit)
            setDivision(json.results[0].division)
          }
      })
          // get load number from loadCurrent
  }, [])

  enum CameraResultType {
    Uri = 'uri',
    Base64 = 'base64',
    DataUrl = 'dataUrl'
  } 
  
  function getLoadNumber(unitNumber: string) {
    var data = new FormData()
    data.append("payload", JSON.stringify({ cmds: [{cmd: "loadDetails", args: {unit: unitNumber, username: localStorage.getItem('username')?.toUpperCase(), loginToken: localStorage.getItem('token')}}] }))
    const requestOptions = {
      method: 'POST',
      headers: {},
      body: data
    };
    fetch(config.url.BASE_URL, requestOptions)
    .then(response => response.json())
    .then(json => {if (json.results[0].errcode === 'NOTLOGGEDIN') {localStorage.removeItem('token')} else if (json.results[0].errcode === "SYSERR") {sendErrorMsg("SYSERR: loadDetails - PhotoUpload")} else if (json.results[0].load) { setLoadNumber(json.results[0].load.loadNumber); setDispatchNumber(json.results[0].load.dispatchNumber)} })
  }

  const b64toBlob = (base64:any) => 
  fetch(base64).then(res => res.blob())
  
  async function submitReport() {
    setSubmitDisabled(true);
    if (images.length >= 3) {
      setLoading(true);
      let ln = loadNumber;
      if (!ln) {console.log('returned'); setToast("No active load found"); setShowToast(true); setShowLoadingModal(false); return;}
      // Submit record
      var data = new FormData()
      data.append("payload", JSON.stringify({ cmds: [{cmd: "genericReportSubmission", args: {driverCode: localStorage.getItem('username')?.toUpperCase(), loginToken: localStorage.getItem('token'), load: ln, trailer: trailerNumber, unit: unitNumber, comment: 'Empty'}}] }))
      const requestOptions = {
        method: 'POST',
        headers: {},
        body: data
      };
      fetch(config.url.BASE_URL, requestOptions)
      .then(response => response.json())
      .then(async json => {if (json.results[0].errcode === "SYSERR") {sendErrorMsg("SYSERR: genericReportSubmission - PhotoUpload")} else if (json.results[0].load) { setLoadNumber(json.results[0].load.loadNumber)}{let refId = json.results[0].genericReportId; submitPhotos(refId, ln);}})
    } else {
        setToast("Please ensure you have the required number of photos for a submission (3)")
        setShowToast(true);
        setSubmitDisabled(false);
        console.log("showToast", showToast);
      }
    }



    async function bufferToB64() {
      const lesseeSignatureBytes = await fetch(driverSignature).then((res) => res.arrayBuffer())
      const binary: any = new Uint8Array(lesseeSignatureBytes);
      let base64Data: any = `data:image/png;base64,` + btoa(String.fromCharCode(...binary));
      console.log("btoa", typeof base64Data);
      return base64Data;
  }

    function createCabReport(refId: any) {
      var data = new FormData()
      data.append("payload", JSON.stringify({ cmds: [{cmd: "cabExtenderCreate", args: {refId: refId, loginToken: localStorage.getItem('token')}}] }))
      const requestOptions = {
          method: 'POST',
          headers: {},
          body: data
      };

      fetch(config.url.BASE_URL, requestOptions)
      .then(response => response.json())
      .then(async json => {submitCabReport(refId, "pending");})
  }

  async function submitCabReport(refId: any, newStatus: any) {
    let sigData = await bufferToB64()
    var data = new FormData()
    data.append("payload", JSON.stringify({ cmds: [{cmd: "cabExtenderUpdate", args: {refId: refId, driverCode: localStorage.getItem("username"), loginToken: localStorage.getItem('token'), exists: 1, damaged: 0, division: division, unitNumber: unitNumber, dmCode: dmCode, leftSide: 0, topLeft: 0, top: 0, topRight: 0, rightSide: 0, status: newStatus, signatureData: sigData}}] }))
    const requestOptions = {
        method: 'POST',
        headers: {},
        body: data
    };

    fetch(config.url.BASE_URL, requestOptions)
    .then(response => response.json())
    .then(async json => {
      // redirectPage()
    })
  }

  async function submitPhotos(refId: any, ln: any) {
    var data = new FormData()
    data.append("payload", JSON.stringify({ cmds: [{cmd: "photoSubmission", args: {driverCode: localStorage.getItem('username')?.toUpperCase(), loginToken: localStorage.getItem('token'), refId: refId, load: ln, division: division, trailer: trailerNumber, unit: unitNumber, type: "cabExt", dmCode: dmCode, dispatchNumber: dispatchNumber, uploaderType: "app"}}] }))
    const requestOptions = {
      method: 'POST',
      headers: {},
      body: data
    };
    fetch(config.url.BASE_URL, requestOptions)
    .then(response => response.json())
    .then( async () => {uploadPhotos(refId);})
  }

    async function uploadPhotos(refId: any) {
      var data = new FormData();
      data.append("payload", JSON.stringify({ cmds: [{cmd: "photoUpload", args: {loginToken: localStorage.getItem('token'), refId: refId, type: "cabExt", dmCode: dmCode}}] }))
      for (let i = 0; i < images.length; i++) {
        data.append(`photos${i}`, new Blob([(await b64toBlob(images[i].data))],{type: 'application/octet-stream'}));
      }
      const requestOptions = {
        method: 'POST',
        body: data
      };
      fetch(config.url.BASE_URL, requestOptions)
      .then(response => response.json())
      .then(json => {if(json.results[0].errmsg){setShowErrorModal(true); removeHashes(hashArray); setLoading(false);}else{setLoading(false); setSuccess(true); console.log("photos successful", json.results[0]); createCabReport(refId)}})
    }

    const redirectPage = () => {
      setTimeout(function() {
        window.location.replace('./Home');
      }, 3000)
    }
    
    async function takePicture() {
      const image = await Camera.getPhoto({
        quality: 30,
        allowEditing: false,
        resultType: CameraResultType.Uri
      });
      const fileName = new Date().getTime() + '.jpeg';
      // await addDateTime(image.webPath!)
      const base64Data = await base64FromPath(image.webPath!);
        console.log("data");
        const newPhotos = [{
          filepath: fileName,
          webviewPath: image.webPath, 
          data: base64Data
        }, ...images];
        setImages(newPhotos)
        timages.push(image)
        if (timages.length > 0) {
          setPhotoMsg("Take another photo")
        }
        console.log("hashArray", hashArray);
    }

    const removePhoto = (e: any) => {
      console.log("hashArasyaya", hashArray);
      const name = e.filepath;
      var hash = CryptoJS.MD5(e.data).toString();
      console.log("test remove", hash, hashArray);
      hashArray.forEach(async (element: any) => {
        console.log("hashArray removal", hash, element);
        if (hash === element) {
          await removeHashes([element])
          hashArray = hashArray.filter((hash: any) => hash !== element)
        }
      });
      setImages(images.filter(image => image.filepath !== name));
    }

    async function removeHashes(removalArray: any) {
      removalArray.forEach(async (element: any) => {
        await ApiService.photoChecksumRevoke("tarpPhotos", {checksum: element}).then(it => {
          hashArray = hashArray.filter((hash: any) => hash !== element)
        })
      });
    }

    const save = () => {
      const signed = driverSigCanvas.current!.toDataURL()
      setDriverSignature(signed)
      setShowDriverignature(!showDriverSignature)
  }

  const clear = () => driverSigCanvas.current!.clear()

    async function success(pos: {coords: any; }) {
      setUserLat(await pos.coords.latitude);
      setUserLong(await pos.coords.longitude);
    }

    function error(err: any) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    
  return (
    <IonPage>
    <IonHeader>
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home"  text=""/>
          </IonButtons>
          <IonTitle>Cab Extender Photo</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toast}
        color="danger"
        duration={5000}
      />
       <IonModal isOpen={loading} id="transparent">
        <div className="modal-div">
          <div id="modal">
            <div className='loading-div'>
              <p>Please wait... your photos are being submitted.</p>
              <div className="snippet" data-title=".dot-flashing" style={{ margin: "4px 16px"}}>
                <div className="stage">
                  <div className="dot-flashing"></div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </IonModal>
      <IonModal isOpen={showErrorModal2} id="transparent">
        <div className="modal-div">
          <div id="modal">
            <div className='warning-div' style={{backgroundColor: "#e9e9e9 !important", color: "#fff !important"}}>
              <h3 style={{textAlign: "center", fontWeight: "600"}}>Error!</h3>
              <p>This photo has already been submitted. Please select a new photo that has not been previously submitted.</p>
              <div className="snippet" data-title=".dot-flashing" style={{ margin: "4px 16px"}}>
                <div className="stage">
                  <IonButton id="western-button" expand="block" onClick={e => {setShowErrorModal2(false)}}><span style={{fontWeight: "600"}}>I Understand</span></IonButton>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </IonModal>
      <IonToast
        isOpen={successPhoto}
        onDidDismiss={() => {setLoading(false); setSuccess(false)}}
        message="Your photos were submitted."
        color="success"
        duration={3000}
      />
       <IonToast
        isOpen={showErrorModal}
        message="Error! One or more photos could not be uploaded, check your connection and please try again."
        color="danger"
        duration={5000}
      />
      <IonContent color='white'>
        <div className='main-2'>
          <div>
          <IonItem color="white" lines="none">
            <div className='item-container'>
              <h1>Submit Cab Extender Photos</h1>
          
              <p style={{fontSize: "medium"}}><b>Please submit the following photos: </b>(Examples below)</p><br/>

          <div style={{ textAlign: "center"}} onClick={ e => {setHeight1(!height1); console.log("expanded");}} aria-expanded={ height1 !== false }>
            <div className='western-button-link'>
                <div id="current-load-button"  style={{background: "#e9e9e9"}} >
                    <p style={{paddingLeft: "15px"}}><b>Left</b> view of the cab extenders</p>
                    <img src={height1 == true ? arrowUp : arrowDown} style={{ height: "2vh", paddingRight: "15px"}} alt="arrow"/>
                </div>
            </div>
          </div>
          <AnimateHeight style={ height1 === true ? {overflowY: 'scroll', borderRadius: "10px", textAlign: "center" } : { overflowY: 'scroll'}} duration={500} height={ height1 === true ? 'auto' : 0 } id="loadSection">
            <img src={cabLeft} alt="Rear Trailer Image" style={{borderRadius: "8px", textAlign: "center"}}/>
          </AnimateHeight>

          <div style={{ textAlign: "center"}} onClick={ e => {setHeight2(!height2); console.log("expanded");}} aria-expanded={ height2 !== false }>
            <div className='western-button-link'>
                <div id="current-load-button" style={{background: "#e9e9e9"}}>
                    <p style={{paddingLeft: "15px"}}><b>Center </b> view of the cab extenders</p>
                    <img src={height2 === true ? arrowUp : arrowDown} style={{ height: "2vh", paddingRight: "15px"}} alt="arrow"/>
                </div>
            </div>
          </div>
          <AnimateHeight style={ height2 === true ? {overflowY: 'scroll', borderRadius: "10px", textAlign: "center" } : { overflowY: 'scroll'}} duration={500} height={ height2 === true ? 'auto' : 0 } id="loadSection">
            <img src={cabCenter} alt="Side Trailer Image" style={{borderRadius: "8px"}}/>
          </AnimateHeight>

          <div style={{ marginBottom: "14px", textAlign: "center"}} onClick={ e => {setHeight3(!height3); console.log("expanded");}} aria-expanded={ height3 !== false }>
            <div className='western-button-link'>
                <div id="current-load-button"  style={{background: "#e9e9e9"}} >
                    <p style={{paddingLeft: "15px"}}><b>Right</b> view of the cab extenders</p>
                    <img src={height3 === true ? arrowUp : arrowDown} style={{ height: "2vh", paddingRight: "15px"}} alt="arrow"/>
                </div>
            </div>
          </div>
          <AnimateHeight style={ height3 === true ? {overflowY: 'scroll', borderRadius: "10px", textAlign: "center" } : { overflowY: 'scroll'}} duration={500} height={ height3 === true ? 'auto' : 0 } id="loadSection">
            <img src={cabRight} alt="Front Trailer Image" style={{borderRadius: "8px"}}/>
          </AnimateHeight>
          <div>
            <div style={{ display: "grid", gridTemplateColumns: '1fr 1fr 1fr', padding: "8px", backgroundColor: "#ffffff", margin: "auto", textAlign: "center"}}>
              {images.map(function(img, index) {
                return <div key={index} onClick={e => {removePhoto(img)}} style={{position: "relative"}}>
                  <img alt="tarppay img"  style={{width: "100px", borderRadius: "10px"}}src={img.webviewPath} />
                  <button style={{ backgroundColor: "rgba(0,0,0,0)", borderRadius: "50%", width: "32px", height: "32px", position: "absolute", top: "0", left: "0"}}><IonIcon style={{color: "#ff0000", fontSize: "large"}} icon={trashOutline}></IonIcon></button>
                </div>
              })}
            </div>
          </div>
              <button className='button' style={{margin: "18px auto"}} onClick={e => {takePicture()}}><p>Take Photo</p><IonIcon icon={cameraOutline}></IonIcon></button>
            </div>
          </IonItem>
          <IonItem lines="none" color="white">
            <div className='item-container'>
              <span style={{fontSize: "12px", color: "#4f4f4f", fontWeight: "600"}}>*Driver has had an opportunity to inspect the truck at the time of assignment and to report any damages or disrepair before taking it 
                over the road. Driver acknowledges and agrees that they are responsible for maintaining the condition of the truck in the same or 
                better condition as it was at the time of assignment, minus normal wear and tear. Driver will be responsible for any damages or disrepair 
                caused to the truck after acceptance.
              </span>
            </div>
          </IonItem>
          <IonItem lines="none" color="light">
              <div className="item-container">
                <p>Driver Signature</p>
                <div hidden={!showDriverSignature} style={{textAlign: "center", width: "90%", overflow: "hidden", backgroundColor: "white", margin: "auto", borderRadius: "20px"}}>
                    <SignaturePad
                        ref={driverSigCanvas}
                        canvasProps={{width: 500, height: 125, className: 'sigCanvas'}}
                    />
                </div>
                <div className="item-container" style={{display: "flex", fontSize: "17px"}}>
                    {showDriverSignature ? <p onClick={save} style={{color: "#212121", margin: "8px 10px"}}>Save</p> : <button onClick={save} className="button">Re-sign</button>}
                    {showDriverSignature ? <p style={{color: "#5096FF", margin: "8px 10px"}} onClick={clear}>Clear</p> : null}
                </div>
              </div>
          </IonItem>
          </div>
        </div>
          <IonItem lines="none" color="white">
            <div className='item-container'>
              <button className="western-button submit" disabled={submitDisabled}  onClick={e => {submitReport()}}>SUBMIT CAB EXT PHOTOS</button>
            </div>
          </IonItem>
      </IonContent>
 
    </IonPage>
  );
};

export default CabinPhoto;

