import React, { useEffect } from "react";
import passengerIcon from "../imgs/add-passenger.png"
import safetyIcon from "../imgs/traffic-light.png"
import maintenanceIcon from "../imgs/maintenance.png"
import benefitsIcon from "../imgs/benefits.png"
import book from "../imgs/open-book.png"
import accidentsIcon from "../imgs/airbag.png"
import documentIcon from "../imgs/document.png"
import inspectionIcon from "../imgs/doc-check.png"


import scanIcon from '../imgs/work/qr-scan.png';
import {Link} from "react-router-dom";
import { IonPage, IonHeader, IonToolbar, IonContent, IonFooter, createGesture } from "@ionic/react";
import Nav from "./Nav";
import Toolbar from "./Toolbar";
import { sidnavStore, SidenavState } from "../api/SidenavStore";
const Docs = () => {

        const {open, close}: { open: boolean, close: () => void } = sidnavStore((state: SidenavState) => ({open: state.open, close: state.close}));
        const {toggleSidenav}: { toggleSidenav: () => void } = sidnavStore((state: SidenavState) => ({toggleSidenav: state.toggle}));
        
        const ref = React.useRef<HTMLInputElement>(null)
    
        useEffect(() => {
            gestureInit();
        }, [])
    
        const gestureInit = () => {
            const card = ref.current
            if (card) {
                const gesture = createGesture({
                    el: card,
                    gestureName: 'swipeMenu',
                    onMove: (detail) => {
                        console.log("moving");
                    },
                    onEnd: (detail) => {
                        console.log("move ended");
                        toggleSidenav();
                    }                 
                })
                gesture.enable();
            }
        }

    return (
        <IonPage>
        <IonHeader>
            <IonToolbar color="primary">
                <Toolbar/>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen color="light">
        <div ref={ref} onClick={() => close()} style={{height: "80vh"}}>
        <div className="item-container">
            <div className="grid50">
                <Link className="western-button-link" to="/safety">
                    <div>
                        <img src={safetyIcon} alt="key" className="link-icon"/><p>Safety</p>
                    </div>
                </Link>
              
                <Link className="western-button-link" to="/maintenancevids">
                    <div>
                        <img src={maintenanceIcon} alt="key" className="link-icon"/><p>Maintenance</p>
                    </div>
                </Link>
                {/* <Link className="western-button-link" to="/safetyscore">
                    <div>
                        <img src={inspectionIcon} alt="key" className="link-icon"/><p>Safety Score</p>
                    </div>
                </Link> */}
                <Link className="western-button-link" to="/permits">
                    <div>
                        <img alt="key" className="link-icon" src={documentIcon} /><p>Permits</p>
                    </div>
                </Link>
                <Link className="western-button-link" to="/benefits">
                    <div>
                        <img src={benefitsIcon} alt="key" className="link-icon"/><p>Benefits</p>
                    </div>
                </Link>
            </div>
            <Link className="western-button-link" to="/driverhandbook">
                <div>
                    <img alt="key" className="link-icon" src={book} /><p>Driver Handbook</p>
                </div>
            </Link>
            <Link className="western-button-link" to="/accidentinfo">
                <div className="red">
                    <img src={accidentsIcon} alt="key" className="link-icon"/><p>Accidents</p>
                </div>
            </Link>
        </div>
        </div>
        </IonContent>
        <IonFooter>
            <Nav onSearch={function (target: boolean): void {
                    console.log("Not ready");
                } }/>
        </IonFooter>
        </IonPage>
    )
}

export default Docs
