import {IonButton, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonPage, IonRadio, IonRadioGroup, IonTitle, IonToast, IonToggle, IonToolbar} from "@ionic/react"
import React, {useState} from "react"
import {chevronDown, chevronForward} from 'ionicons/icons';
// import "jspdf"
// import "jspdf-autotable"
import OrientationDriverEvalItem from "./OrientationDriverEvalItem";
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";
import {useHistory} from "react-router-dom";

interface Props {
    name: string,
    governmentId: string,
    hideTest: () => void
}

const TrainerEval: React.FC<Props> = ({name, governmentId, hideTest}) => {

    const history = useHistory();

    const { jsPDF } = require("jspdf")
    require("jspdf-autotable")
    const today = new Date().toLocaleString()
    const [showGeneral, setShowGeneral] = useState(true)
    const [showCityDriving, setShowCityDriving] = useState(true)
    const [showHighwayDriving, setShowHighwayDriving] = useState(true)
    const [showLaneControl, setShowLaneControl] = useState(true)
    const [showSignKnowledge, setShowSignKnowledge] = useState(true)
    const [showRailroadSafety, setShowRailroadSafety] = useState(true)
    const [showPretrip, setShowPretrip] = useState(true)
    const [showCoupling, setShowCoupling] = useState(true)
    const [showBackingEvaluation, setShowBackingEvaluation] = useState(true)
    const [notes, setNotes] = useState('')
    const [deducted, setDeducted] = useState(0)
    const [ready, setReady] = useState(false)
    const [explained, setExplained] = useState(false)
    const [cdl, setCDL] = useState(false)
    const [medcert,setMedcert] = useState(false)
    const [evalType, setEvalType] = useState("Eval")
    const [timeIn, setTimeIn] = useState(today)
    const [timeOut, setTimeOut] = useState(today)
    const [moreInfo, setMoreinfo] = useState(false)
    //GENERAL
    const [truckInMotion, setTruckInMotion] = useState(true)
    const [threePoint, setThreePoint] = useState(true)
    const [seatBelt, setSeatBelt] = useState(true)
    const [mirrorUse, setMirrorUse] = useState(true)
    //CITYDRIVING
    const [turnup, setTurnup] = useState(true)
    const [twoTires, setTwoTires] = useState(false)
    const [fourTires, setFourTires] = useState(false)
    const [maxTurnClearance, setMaxTurnClearance] = useState(true)
    const [signal, setSignal] = useState(true)
    const [rightOfWay, setRightOfWay] = useState(true)
    const [turnSpeed, setTurnSpeed] = useState(true)
    const [speedingLow, setSpeedingLow] = useState(false)
    const [speedingHigh, setSpeedingHigh] = useState(false)
    const [speedingSchoolZone, setSpeedingSchoolZone] = useState(false)
    const [brakesSmoothly, setBrakesSmoothly] = useState(true)
    const [runsStopLight, setRunsStopLight] = useState(false)
    const [runsStopSign, setRunsStopSign] = useState(false)
    const [rollsBack, setRollsBack] = useState(false)
    const [usesFlashers, setUsesFlashers] = useState(true)
    const [safeLaneChange, setSafeLaneChange] = useState(true)
    const [unsafeAction, setUnsafeAction] = useState(false)
    //HIGHWAYDRIVING
    const [entersSafely, setEntersSafely] = useState(true)
    const [highwaySignals, setHighwaySignals] = useState(true)
    const [highwayLaneChange, setHighwayLaneChange] = useState(true)
    const [distance, setDistance] = useState(true)
    const [distracted, setDistracted] = useState(false)
    const [highwayFlashers, setHighwayFlashers] = useState(true)
    const [highwayRightOfWay, setHighWayRightOfWay] = useState(true)
    //LANECONTROL
    const [drifts, setDrifts] = useState(false)
    const [turnLane, setTurnLane] = useState(true)
    const [overtake, setOvertake] = useState(true)
    //SIGNKNOWLEDGE
    const [roadsigns, setRoadSigns] = useState(true)
    //RAILROAD
    const [windows, setWindows] = useState(true)
    const [stopsCrossing, setStopsCrossing] = useState(false)
    const [trackSpeed, setTrackSpeed] = useState(true)
    const [looksBothWays, setLooksBothWays] = useState(true)
    const [railFlashers, setRailFlashers] = useState(true)
    const [toast, setToast] = useState('')
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [ssn, setSsn] = useState('');
    const [licenseNumber, setLicenseNumber] = useState('');
    const [licensingState, setLicensingState] = useState('');
    const [pretrip1, setPretrip1] = useState(true);
    const [pretrip2, setPretrip2] = useState(true);
    const [pretrip3, setPretrip3] = useState(true);
    const [coupling1, setCoupling1] = useState(true);
    const [coupling2, setCoupling2] = useState(true);
    const [backingTime, setBackingTime] = useState<number | null>(null);
    const [backingHorn, setBackingHorn] = useState<number | null>(null);
    const [backingPullups, setBackingPullups] = useState<number | null>(null);
    const [backingHits, setBackingHits] = useState<number | null>(null);
    const [backingPf, setBackingPf] = useState<number | null>(null);

    const questions = () => {
        const array = [
            {
                'category': 'Pretrip',
                'description' : 'The driver shows competence in knowing and understanding the anatomy of the truck. (Ensure the driver talks about what they’re looking at and what they’re looking for)',
                'value':pretrip1 ? 0 : -1,
                'func': setPretrip1,
                'status': pretrip1
            },
            {
                'category': 'Pretrip',
                'description' : 'Driver’s process during the inspection is thorough and organized.',
                'value':pretrip2 ? 0 : -1,
                'func': setPretrip2,
                'status': pretrip2
            },
            {
                'category': 'Pretrip',
                'description' : 'Driver shows proficiency in completing DVIR.',
                'value':pretrip3 ? 0 : -1,
                'func': setPretrip3,
                'status': pretrip3
            },
            {
                'category': 'Coupling and Uncoupling',
                'description' : 'Driver can properly couple (hook) to the trailer and completes both a tug test and Flashlight Test.',
                'value':coupling1 ? 0 : -1,
                'func': setCoupling1,
                'status': coupling1
            },
            {
                'category': 'Coupling and Uncoupling',
                'description' : 'Driver can properly uncouple (unhook) from the trailer.',
                'value':coupling2 ? 0 : -1,
                'func': setCoupling2,
                'status': coupling2
            },
        ]
        var total = deducted
        array.forEach(question => {
            total = total + question.value    
        })
        return {array, total}
    }
    const sqlDateReturn = (time: string) => {
        const date = new Date()
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        return year + "-" + month + "-" + day + " " + time
    }

    const sendRoadTestData = () => {
        const deductions = questions().total
        const score = 100 + deductions
        const passed = deductions <= -26 ? 0 : 1
        let issues: any = {}
        questions().array.forEach(item => {
            issues[item.description] = {'description': item.description, 'category': item.category, 'status': item.status, 'value': item.value}
        })
        issues = JSON.stringify(issues)
        ApiService.submitOrientationEval("orientation", {
            name: name,
            governmentId: governmentId,
            instructor: localStorage.getItem('username')!,
            reportData: issues,
            passed: passed,
            score: score,
            timeIn: sqlDateReturn(timeIn),
            timeOut: sqlDateReturn(timeOut),
            cdl: cdl ? 1 : 0,
            medcert: medcert ? 1 : 0,
            evalType: evalType,
            notes: notes
        });
    }

    const submitRoadTest = () => {
        const unit = 'in'
        const size = 'A4'
        const orientation = 'p'
        const doc = new jsPDF(orientation, unit, size)
        const header = [['General', 'Status', 'Points']]
        const cityHeader = [['City Driving', 'Status', 'Points']]
        const highwayHeader = [['Highway Driving', 'Status', 'Points']]
        const signHeader = [['Sign Knowledge', 'Status', 'Points']]
        const laneHeader = [['Lane Control', 'Status', 'Points']]
        const railHeader = [['Railroad Safety', 'Status', 'Points']]
        const driverHeader =[['Driver Name', 'Instructor']]
        const dateHeader = [['Date', 'Time In', 'Time Out']]
        const additionalHeader = [['CDL', 'Med Cert', 'Eval Type']]
        const additionalInfo = [[ cdl, medcert, evalType]]
        const dateInfo =[[today, timeIn, timeOut]]
        const driverInfo = [[name, localStorage.getItem('username')]]
        const generalData = questions().array.filter(el => el.category === 'General').map(el => [el.description,  el.status ? ' YES ' : ' NO ',  el.value])
        const cityData = questions().array.filter(el => el.category === 'City Driving').map(el => [el.description,  el.status ? ' YES ' : ' NO ',  el.value])
        const highwayData = questions().array.filter(el => el.category === 'Highway Driving').map(el => [el.description,  el.status ? ' YES ' : ' NO ',  el.value])
        const signData = questions().array.filter(el => el.category === 'Sign Knowledge').map(el => [el.description,  el.status ? ' YES ' : ' NO ',  el.value])
        const laneData = questions().array.filter(el => el.category === 'Lane Control').map(el => [el.description,  el.status ? ' YES ' : ' NO ',  el.value])
        const railData = questions().array.filter(el => el.category === 'Railroad Safety').map(el => [el.description,  el.status ? ' YES ' : ' NO ',  el.value])


       
        const columnStyle ={ 0: {cellWidth: 2.0}, 1: {cellWidth: 1.0}, 2: {cellWidth: 1.0}}
        const dateColumnStyle ={ 0: {cellWidth: 1.33}, 1: {cellWidth: 1.33}, 2: {cellWidth: 1.33}}
        const driverColumnStyle ={ 0: {cellWidth: 2.0}, 1: {cellWidth: 2.0}}
        const columns = [
            { dataKey: 'category', header: '%' },
            { dataKey: 'status', header: 'Status' },
            { dataKey: 'points', header: 'Points' },
        ]
        const content = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            columnStyles: columnStyle,
            willDrawCell: function (data: { row: { section: string; }; column: { dataKey: string; }; cell: { raw: number; }; }) {
                if (data.column.dataKey === 'points') {
                  if (data.cell.raw < 0) {
                    doc.setFillColor(255, 198, 198)
                    doc.setTextColor(255, 0, 0) // Red
                  }
                }
            },
            columns: columns,
            startY:3.0,
            margin: 0.1,
            head: header,
            body: generalData
        }
        const content2 = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            willDrawCell: function (data: { row: { section: string; }; column: { dataKey: string; }; cell: { raw: number; }; }) {
                if (data.column.dataKey === 'points') {
                  if (data.cell.raw < 0) {
                    doc.setFillColor(255, 198, 198)
                    doc.setTextColor(255, 0, 0) // Red
                  }
                }
            },
            columns: columns,
            columnStyles: columnStyle,
            startY: doc.lastAutoTable.finalY,
            margin: 0.1,
            head: cityHeader,
            body: cityData
        }
        const content3 = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            columnStyles: columnStyle,
            willDrawCell: function (data: { row: { section: string; }; column: { dataKey: string; }; cell: { raw: number; }; }) {
                if (data.column.dataKey === 'points') {
                  if (data.cell.raw < 0) {
                    doc.setFillColor(255, 198, 198)
                    doc.setTextColor(255, 0, 0) // Red
                  }
                }
            },
            columns: columns,
            startY: 3.0,
            margin: {
                top: 0.1,
                left: 4.2
            },
            head: highwayHeader,
            body: highwayData
        }
        const content4 = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            willDrawCell: function (data: { row: { section: string; }; column: { dataKey: string; }; cell: { raw: number; }; }) {
                if (data.column.dataKey === 'points') {
                  if (data.cell.raw < 0) {
                    doc.setFillColor(255, 198, 198)
                    doc.setTextColor(255, 0, 0) // Red
                  }
                }
            },
            columns: columns,
            columnStyles: columnStyle,
            startY: doc.lastAutoTable.finalY,
            margin: {
                top: 0.1,
                left: 4.2
            },
            head: signHeader,
            body: signData
        }
        const content5 = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            willDrawCell: function (data: { row: { section: string; }; column: { dataKey: string; }; cell: { raw: number; }; }) {
                if (data.column.dataKey === 'points') {
                  if (data.cell.raw < 0) {
                    doc.setFillColor(255, 198, 198)
                    doc.setTextColor(255, 0, 0) // Red
                  }
                }
            },
            columns: columns,
            columnStyles: columnStyle,
            startY: doc.lastAutoTable.finalY,
            margin: {
                top: 0.1,
                left: 4.2
            },
            head: laneHeader,
            body: laneData
        }
        const content6 = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            willDrawCell: function (data: { row: { section: string; }; column: { dataKey: string; }; cell: { raw: number; }; }) {
                if (data.column.dataKey === 'points') {
                  if (data.cell.raw < 0) {
                    doc.setFillColor(255, 198, 198)
                    doc.setTextColor(255, 0, 0) // Red
                  }
                }
            },
            columns: columns,
            columnStyles: columnStyle,
            startY: doc.lastAutoTable.finalY,
            margin: {
                top: 0.1,
                left: 4.2
            },
            head: railHeader,
            body: railData
        }
        const noteHeader = [['Notes:']]
        const noteContent = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },

            startY: 11,
            margin: {
                top: 0.1,
                left: 0.1,
                right: 0.1
            },
            head: noteHeader,
            body: [[notes]]
        }
        const driverContent = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            columnStyle: driverColumnStyle,
            columns: [ { dataKey: 'name', header: 'Driver Name' },  { dataKey: 'instructor', header: 'Instructor' } ],
            startY: 0.5,
            margin: {
                top: 0.1,
                left: 0.1,
                right: 0.1
          
            },
            head: driverHeader,
            body: driverInfo
        }
        const dateContent = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            columnStyle: dateColumnStyle,
            startY: doc.lastAutoTable.finalY,
            margin: {
                top: 0.1,
                left: 0.1,
                right: 0.1
        
            },
            head: dateHeader,
            body: dateInfo
        }
        const addContent = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            startY: doc.lastAutoTable.finalY,
            margin: {
                top: 0.1,
                left: 0.1,
                right: 0.1
 
            },
            head: additionalHeader,
            body: additionalInfo
        }
        var scoreInfo = new Image()
        scoreInfo.src = '/assets/driver_eval.JPG'
        
        const score = 100 + questions().total
        const pass = questions().total <= -26 ? 'NO' : 'YES'
        doc.text('Driver Evaluation Form', 3.0, 0.3)
        doc.autoTable(driverContent)
        doc.autoTable(dateContent)
        doc.autoTable(addContent)
        doc.autoTable(content)
        doc.autoTable(content2)
        doc.autoTable(content3)
        doc.autoTable(content4)
        doc.autoTable(content5)
        doc.autoTable(content6)
        doc.text('Score:'+ score + '/100 Points \nDeducted: ' + questions().total +  '\nPASS: ' + pass, 4.2, doc.lastAutoTable.finalY + 0.5)
        doc.autoTable(noteContent)
        const fileData = doc.output('datauristring').split('base64,')[1];
        // console.log(fileData)
        var blobPDF =  new Blob([ doc.output() ], { type : 'application/pdf'});
        const fileSize = blobPDF.size
        
        if (ready && explained) {
           sendRoadTestData()
           submitTest(governmentId, fileData, fileSize)
        } else {
            toastStore.getState().setToast('Please confirm you are ready to perform an evaluation, and you have explained all rules and requirements.');
        }
    }

    async function submitTest(ssn: string, data: string, size: number) {
        const passed = questions().total <= -26 ? 0 : 1
        ApiService.submitTenstreetDocument("orientation", {
            ssn: ssn,
            fileId: 1,
            fileCode: 'TrainerEval',
            fileStorageType: 'encoded string',
            fileCategoryName: 'DQF',
            filename: name + '_trainer_evaluation_' + (passed ? 'pass' : 'fail') + '.pdf',
            fileSize: size,
            fileType: 'application/pdf',
            fileData: data,
            mode: 'PROD'
        }).then(() => ApiService.updateOrientation("orientation", {ssn, passed, completed: 1}))
            .then(() => {
                toastStore.getState().setSuccessToast('Success! Your test was submitted.');
                history.push('/home');
            })
            .finally(() => setLoading(false));
    }
    const pretrip = questions().array.filter(el => el.category === "Pretrip").map((item ,index) => {
        return (
            <div onClick={() => item.func(!item.status)}className="item-container">
                <OrientationDriverEvalItem
                    key={index}
                    category={item.category}
                    description={item.description}
                    status={item.status}
                    value={item.value}
                ></OrientationDriverEvalItem>
            </div>
        )
    })
    const coupling = questions().array.filter(el => el.category === "Coupling and Uncoupling").map((item ,index) => {
        return (
            <div onClick={() => item.func(!item.status)}className="item-container">
                <OrientationDriverEvalItem
                    key={index}
                    category={item.category}
                    description={item.description}
                    status={item.status}
                    value={item.value}
                ></OrientationDriverEvalItem>
            </div>
        )
    })

    const getRadioRatingValues = () => <>
        <div style={{display: "flex", gap: "10px", flexWrap: "wrap", minWidth: "100%", justifyContent: "space-between", padding: "0 30px"}}>
            <div style={{display: "flex", gap: "5px", alignItems: "center"}}><IonLabel>1</IonLabel><IonRadio value={1}></IonRadio></div>
            <div style={{display: "flex", gap: "5px", alignItems: "center"}}><IonLabel>2</IonLabel><IonRadio value={2}></IonRadio></div>
            <div style={{display: "flex", gap: "5px", alignItems: "center"}}><IonLabel>3</IonLabel><IonRadio value={3}></IonRadio></div>
            <div style={{display: "flex", gap: "5px", alignItems: "center"}}><IonLabel>4</IonLabel><IonRadio value={4}></IonRadio></div>
            <div style={{display: "flex", gap: "5px", alignItems: "center"}}><IonLabel>5</IonLabel><IonRadio value={5}></IonRadio></div>
            <div style={{display: "flex", gap: "5px", alignItems: "center"}}><IonLabel>6</IonLabel><IonRadio value={6}></IonRadio></div>
            <div style={{display: "flex", gap: "5px", alignItems: "center"}}><IonLabel>7</IonLabel><IonRadio value={7}></IonRadio></div>
            <div style={{display: "flex", gap: "5px", alignItems: "center"}}><IonLabel>8</IonLabel><IonRadio value={8}></IonRadio></div>
            <div style={{display: "flex", gap: "5px", alignItems: "center"}}><IonLabel>9</IonLabel><IonRadio value={9}></IonRadio></div>
        </div>
    </>;

    const backingEvaluation = [
        {name: 'Time', value: backingTime, setter: setBackingTime},
        {name: 'Horn / GOAL', value: backingHorn, setter: setBackingHorn},
        {name: '# of Pull-ups', value: backingPullups, setter: setBackingPullups},
        {name: '# of Hit(s)', value: backingHits, setter: setBackingHits},
        {name: 'P/F', value: backingPf, setter: setBackingPf}
    ].map(it => {
        return <IonRadioGroup name={it.name} value={it.value} onIonChange={e => it.setter(e.detail.value)}>
            <span style={{paddingLeft: "20px", display: "block", marginTop: "20px", paddingBottom: "10px", fontWeight: "bold"}}>{it.name} <span className="color-danger">*</span></span>{getRadioRatingValues()}
        </IonRadioGroup>
    });

    function handleType(type: React.SetStateAction<string>) {
        setEvalType(type)
        if (type === 'CHAT TN' || type === 'PEGRAM') {
            setWindows(true)
            setTrackSpeed(true)
            setLooksBothWays(true)
            setRailFlashers(true)
        } else {
            setWindows(false)
            setTrackSpeed(false)
            setLooksBothWays(false)
            setRailFlashers(false)
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
            <IonButton slot="start" style={{padding: 0}}><p style={{fontFamily: "Montserrat, sans-seriff"}} onClick={() => hideTest()}>Back</p></IonButton>
            <IonTitle>Driver Evaluation Form</IonTitle>
          </IonToolbar>
        </IonHeader>
           
        <IonContent fullscreen color="light">
        <div className='item-container' style={{ position: "sticky", top: "16px", zIndex: 10, backgroundColor: "#ffffff", boxShadow: "0 4px 4px rgba(0,0,0,0.25)", borderRadius:"10px", padding: "8px"}} onClick={() => setMoreinfo(!moreInfo)}>
            <p className="subtitle" style={{width: "100%", textAlign: "center", padding: 0, color: "#D0A024"}}>{moreInfo ? "Less Detail" : "More Detail"}</p>
            <div style={{ display: "flex"}}>
                <p className='title'>{name}</p>
                <p className='subtitle'>Test Date: {today}</p>
            </div>
           
            <div style={{display: "flex", gap: "32px"}}>
                    <p>Score: <span style={{ color: 100 + questions().total <= 74 ? "#FF0000" : "#2fd573"}}>{100 + questions().total} / 100</span></p>
                    <p>Points deducted: <span style={{ color :  "#FF0000"}}>{questions().total}</span></p>
            </div>
            <p style={{ margin: 0}}>Pass: {questions().total <= -26 ? 'NO' : 'YES'}</p>
            {/* <div style={{ display: "flex"}}>
                <p style={{ fontSize: "small", padding: "0 8px", color: "#2fd573"}}>Completion: {getCompletetion()} </p>
                <p style={{ fontSize: "small", padding: "0 8px"}}>Grade: <span style={{ color: grade === "Pass" ? "#2fd573" : "#FF0000"}}>{grade}</span></p>
            </div> */}
                {moreInfo ? <div>
                    <div style={{ display: "flex"}}>
                        <p className="subtitle">Instructor: {localStorage.getItem('username')}</p>
                        <p className="subtitle">Government ID: {'xxx-xx-'+ governmentId.toString().slice(5,9)}</p>
                    </div>
                    
                    <p style={{ marginBottom: "8px", borderBottom: "1px solid #d3d3d3"}}>Scoring Info</p>
                    <strong >Total Points: 100</strong><br/>
                    <strong >Pass: 75+</strong><br/>
                    <strong >Fail: 74-</strong><br/>
                    <br/>
                    <p>PEGRAM, CHAT TN - No Railroad Safety Portion, Applicant Gets All Points Automatically</p>
                    <strong >Automatic Failure Conditions:</strong><br/>
                    <ul>
                        <li>Speeding 10+ MPH over</li>
                        <li>Speeding in a School Zone</li>
                        <li>Runs Stop Light</li>
                        <li>Runs Stop Sign</li>
                        <li>Unsafe Action (Please note)</li>
                        <li>Stops on Crossing</li>
                    </ul>
                </div> : null}
            </div>
            <IonItem lines="none" color="light" style={{marginTop: "20%"}}>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <div className="item-container">
                        <div className="input-container">
                            <input type="text" className="input" value={ssn} onChange={e => setSsn(e.target.value!)}/>
                            <p className="placeholder">Driver SSN</p>
                        </div>
                    </div>
                    <div className="item-container">
                        <div className="input-container">
                            <input type="text" className="input" value={licenseNumber} onChange={e => setLicenseNumber(e.target.value!)}/>
                            <p className="placeholder">License Number</p>
                        </div>
                    </div>
                    <div className="item-container">
                        <div className="input-container">
                            <input type="text" className="input" value={licensingState} onChange={e => setLicensingState(e.target.value!)}/>
                            <p className="placeholder">Licensing State</p>
                        </div>
                    </div>
                    <div className="item-container" style={{display: "flex", gap: "8px"}}>
                        <div className="input-container">
                            <input type="time" className="input" value={timeIn} onChange={e => setTimeIn(e.target.value!)}/>
                            <p className="placeholder">Time In</p>
                        </div>
                        <div className="input-container">
                            <input type="time" className="input" value={timeOut} onChange={e => setTimeOut(e.target.value!)}/>
                            <p className="placeholder">Time Out</p>
                        </div>
                    </div>
                </div>
            </IonItem>

            <div className="orientation-roadtest">
                {/* <IonToast
            isOpen={loading}
            message="Please wait... your test is being submitted."
            color="warning"
            duration={2000}
        /> */}
                <IonModal isOpen={loading} id="transparent">
                    <div className="modal-div">
                        <div id="modal">
                            <div className='loading-div'>
                                <p>Please wait... your test is being submitted.</p>
                                <div className="snippet" data-title=".dot-flashing" style={{ margin: "4px 16px"}}>
                            <div className="stage">
                              <div className="dot-flashing"></div>
                            </div> 
                          </div>
                        </div>
                    </div>
                </div>
            </IonModal>
            <IonItem  color="light" lines="none">CDL<IonToggle slot="end" checked={cdl} onIonChange={e => setCDL(e.detail.checked)}color="success" /></IonItem>
            <IonItem  color="light" lines="none">Med Cert<IonToggle slot="end" checked={medcert} onIonChange={e => setMedcert(e.detail.checked)}color="success" /></IonItem>
            <IonItem color="light" lines="none">
                <div className="item-container">
                <div className="input-container">
                    <select id="eval" className="input" placeholder=" " value={evalType} onChange={e => handleType(e.target.value!)} style={{ height: "20px"}}>
                    <option value="Eval">Eval</option>
                    <option value="City">City</option>
                    <option value="AC 2 LN">AC 2 LN</option>
                    <option value="600">600</option>
                    <option value="CHAT TN">CHAT TN</option>
                    <option value="PEGRAM">PEGRAM</option>        
                </select>
                <p className="placeholder" >Eval Type</p>
              </div>
                </div>
            </IonItem>
            <IonItem lines="none" color="light">
                <div className="item-container">
                    <div style={{display:"flex", justifyContent: "space-between"}}>
                        <p>I am ready and able to perform this evaluation</p>
                        <input type="checkbox" onChange={e => setReady(e.target.checked!)}/>
                    </div>
                    <div style={{display:"flex", justifyContent: "space-between"}}>
                        <p>I have explained the exceptions and requirements, including the conditions that will result in an automatic fail</p>
                        <input type="checkbox" onChange={e => setExplained(e.target.checked!)}/>
                    </div>
                </div>
            </IonItem>

        <IonItem color="light" lines="none" className="roadtest-category"onClick={() => setShowPretrip(!showPretrip)}><strong>Pretrip</strong><IonIcon slot="end" icon={showPretrip ? chevronDown : chevronForward}></IonIcon></IonItem>
        {showPretrip ? pretrip : ''}

        <IonItem color="light" lines="none" className="roadtest-category"onClick={() => setShowCoupling(!showCoupling)}><strong>Coupling and Uncoupling</strong><IonIcon slot="end" icon={showCoupling ? chevronDown : chevronForward}></IonIcon></IonItem>
        {showCoupling ? coupling : ''}

        <IonItem color="light" lines="none" className="roadtest-category"onClick={() => setShowBackingEvaluation(!showBackingEvaluation)}><strong>Backing Evaluation</strong><IonIcon slot="end" icon={showBackingEvaluation ? chevronDown : chevronForward}></IonIcon></IonItem>
        {showBackingEvaluation ? backingEvaluation.map(it => it) : ''}

     <IonItem lines="none" color="light">
        <div className='item-container'>
        <div className="input-container">
            <textarea id="note-box" className="input"  placeholder=" " value={notes} onChange={e => setNotes(e.target.value!)}/>
            <p className="placeholder" >Notes</p>
        </div>
        </div>
    </IonItem>
    <IonItem lines="none" color="light">
        <div className="item-container">
            <button className="western-button submit" onClick={() => {submitRoadTest()}}>SUBMIT</button>
        </div>
    </IonItem>
        </div>
        </IonContent>
   
        </IonPage>
    )
}

export default TrainerEval
