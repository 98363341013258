import React, { useEffect } from "react"
import historyIcon from "../imgs/history.png"
import preplanIcon from "../imgs/preplan.png"
import {LoadInfo} from "../api/model/LoadInfo";
import {Link} from "react-router-dom";
import {UserState, userStore} from "../api/AuthService";
import {DateUtils} from "../utils/DateUtils";
import { createGesture, IonContent, IonFooter, IonHeader, IonPage, IonToolbar, Gesture } from "@ionic/react";
import Search from "./Search";
import Toolbar from "./Toolbar";
import Nav from "./Nav";
import { sidnavStore, SidenavState } from "../api/SidenavStore";

const Load = () => {

    const {load}: { load: LoadInfo } = userStore((state: UserState) => ({load: state.loadInfo}));
    const {open, close}: { open: boolean, close: () => void } = sidnavStore((state: SidenavState) => ({open: state.open, close: state.close}));
    const {toggleSidenav}: { toggleSidenav: () => void } = sidnavStore((state: SidenavState) => ({toggleSidenav: state.toggle}));
    
    const ref = React.useRef<HTMLInputElement>(null)

    useEffect(() => {
        gestureInit();
    }, [])

    const gestureInit = () => {
        const card = ref.current
        if (card) {
            const gesture = createGesture({
                el: card,
                gestureName: 'swipeMenu',
                onMove: (detail) => {
                    console.log("moving");
                },
                onEnd: (detail) => {
                    console.log("move ended");
                    toggleSidenav();
                }                 
            })
            gesture.enable();
        }
    }

    return (
        <IonPage>
        <IonHeader>
            <IonToolbar color="primary">
                <Toolbar/>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen color="light">
            <div ref={ref} onClick={() => close()} style={{height: "80vh"}}>
                <div className="item-container">
                    <div className="grid50">
                        <Link to="/currentload" className="western-button-link">
                            <div id="centered">
                                <p>Current Load</p>
                                {load ? <>
                                    <p>{DateUtils.formatDate(load?.deliveryStartTime)}</p>
                                    <p>{load?.consigneeCity}, {load?.consigneeState}</p>
                                </> : <p className="color-danger">No active load found!</p>}
                            </div>
                        </Link>
                        <div>
                            <Link to="/loadhistory" className="western-button-link">
                                <div>
                                    <img src={historyIcon} alt="key" className="link-icon"/><p>Load History</p>
                                </div>
                            </Link>
                            <Link to="/preplanned" className="western-button-link">
                                <div>
                                    <img src={preplanIcon} alt="key" className="link-icon"/><p>Preplanned</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </IonContent>
        <IonFooter>
            <Nav onSearch={function (target: boolean): void {
                    console.log("Not ready");
                } }/>
        </IonFooter>
        </IonPage>
    )
}
export default Load
