import {IonButton, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonPage, IonRadio, IonRadioGroup, IonSelect, IonSelectOption, IonTitle, IonToast, IonToggle, IonToolbar} from "@ionic/react"
import React, {createRef, useEffect, useState} from "react"
import {chevronDown, chevronForward} from 'ionicons/icons';
// import "jspdf"
// import "jspdf-autotable"
import OrientationDriverEvalItem from "./OrientationDriverEvalItem";
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";
import {useHistory} from "react-router-dom";
import { PDFDocument } from "pdf-lib";
import { userStore, UserState } from "../api/AuthService";
import SignaturePad from "react-signature-canvas"
import ReactSignatureCanvas from "react-signature-canvas"
import { UserDetails } from "../api/model/UserDetails";
import { userInfo } from "os";

interface Props {
    name: string,
    driverId: string,
    governmentId: string,
    hideTest: () => void
}

const OrientationRoadTest: React.FC<Props> = ({name, driverId, governmentId, hideTest}) => {

    const history = useHistory();

    const { jsPDF } = require("jspdf")
    require("jspdf-autotable")
    const today = new Date().toLocaleString()
    const {userDetails}: { userDetails: UserDetails } = userStore((state: UserState) => ({userDetails: state.user}));
    const [dataURL, setDataURL] = useState("")
    const [signatureData, setSignatueData] = useState("")
    const [showGeneral, setShowGeneral] = useState(true)
    const [showCityDriving, setShowCityDriving] = useState(true)
    const [showHighwayDriving, setShowHighwayDriving] = useState(true)
    const [showLaneControl, setShowLaneControl] = useState(true)
    const [showSignKnowledge, setShowSignKnowledge] = useState(true)
    const [showSignature, setShowSignature] = useState(true);
    const [showRailroadSafety, setShowRailroadSafety] = useState(true)
    const [showPretrip, setShowPretrip] = useState(true)
    const [showCoupling, setShowCoupling] = useState(true)
    const [showBackingEvaluation, setShowBackingEvaluation] = useState(true)
    const [notes, setNotes] = useState('')
    const [deducted, setDeducted] = useState(0)
    const [ready, setReady] = useState(false)
    const [explained, setExplained] = useState(false)
    const [cdl, setCDL] = useState(false)
    const [medcert,setMedcert] = useState(false)
    const [evalType, setEvalType] = useState("Eval")
    const [timeIn, setTimeIn] = useState(today)
    const [timeOut, setTimeOut] = useState(today)
    const [moreInfo, setMoreinfo] = useState(false)
    //GENERAL
    const [truckInMotion, setTruckInMotion] = useState(true)
    const [threePoint, setThreePoint] = useState(true)
    const [seatBelt, setSeatBelt] = useState(true)
    const [mirrorUse, setMirrorUse] = useState(true)
    //CITYDRIVING
    const [turnup, setTurnup] = useState(true)
    const [twoTires, setTwoTires] = useState(false)
    const [fourTires, setFourTires] = useState(false)
    const [maxTurnClearance, setMaxTurnClearance] = useState(true)
    const [signal, setSignal] = useState(true)
    const [rightOfWay, setRightOfWay] = useState(true)
    const [turnSpeed, setTurnSpeed] = useState(true)
    const [speedingLow, setSpeedingLow] = useState(false)
    const [speedingHigh, setSpeedingHigh] = useState(false)
    const [speedingSchoolZone, setSpeedingSchoolZone] = useState(false)
    const [brakesSmoothly, setBrakesSmoothly] = useState(true)
    const [runsStopLight, setRunsStopLight] = useState(false)
    const [runsStopSign, setRunsStopSign] = useState(false)
    const [rollsBack, setRollsBack] = useState(false)
    const [usesFlashers, setUsesFlashers] = useState(true)
    const [safeLaneChange, setSafeLaneChange] = useState(true)
    const [unsafeAction, setUnsafeAction] = useState(false)
    //HIGHWAYDRIVING
    const [entersSafely, setEntersSafely] = useState(true)
    const [highwaySignals, setHighwaySignals] = useState(true)
    const [highwayLaneChange, setHighwayLaneChange] = useState(true)
    const [distance, setDistance] = useState(true)
    const [distracted, setDistracted] = useState(false)
    const [highwayFlashers, setHighwayFlashers] = useState(true)
    const [highwayRightOfWay, setHighWayRightOfWay] = useState(true)
    //LANECONTROL
    const [drifts, setDrifts] = useState(false)
    const [turnLane, setTurnLane] = useState(true)
    const [overtake, setOvertake] = useState(true)
    //SIGNKNOWLEDGE
    const [roadsigns, setRoadSigns] = useState(true)
    //RAILROAD
    const [windows, setWindows] = useState(true)
    const [stopsCrossing, setStopsCrossing] = useState(false)
    const [trackSpeed, setTrackSpeed] = useState(true)
    const [looksBothWays, setLooksBothWays] = useState(true)
    const [railFlashers, setRailFlashers] = useState(true)
    const [toast, setToast] = useState('')
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [ssn, setSsn] = useState('');
    const [licenseNumber, setLicenseNumber] = useState('');
    const [licensingState, setLicensingState] = useState('');
    const [pretrip1, setPretrip1] = useState(true);
    const [pretrip2, setPretrip2] = useState(true);
    const [pretrip3, setPretrip3] = useState(true);
    const [coupling1, setCoupling1] = useState(true);
    const [coupling2, setCoupling2] = useState(true);
    const [backingTime, setBackingTime] = useState<string>("Fail");
    const [backingHorn, setBackingHorn] = useState<string>("Pass");
    const [backingPullups, setBackingPullups] = useState<any>(1);
    const [backingHits, setBackingHits] = useState<any>(1);
    const [backingPf, setBackingPf] = useState<any>(1);
    const [formType, setFormType] = useState<string>('DRIVER');

    const sigCanvas = createRef<ReactSignatureCanvas>()

    const questions = () => {
        const array = [
            {
                'category': 'General',
                'description' : 'Properly Putting Truck in Motion',
                'value': truckInMotion ? 0 : -1,
                'func': setTruckInMotion,
                'status': truckInMotion
            },
            {
                'category': 'General',
                'description' : '3-point Contact in/out',
                'value': threePoint ? 0 : -1,
                'func': setThreePoint,
                'status': threePoint
            },
            {
                'category': 'General',
                'description' : 'Seat belt on',
                'value': seatBelt ? 0 : -1,
                'func': setSeatBelt,
                'status': seatBelt
            },
            {
                'category': 'General',
                'description' : 'Mirror Use',
                'value': mirrorUse ? 0 : -5,
                'func': setMirrorUse,
                'status': mirrorUse
            },
            {
                'category': 'City Driving',
                'description' : 'Sets Turn Up Correctly',
                'value': turnup ? 0 : -4,
                'func': setTurnup,
                'status': turnup
            },
            {
                'category': 'City Driving',
                'description' : '2 Tires on Curb While Driving',
                'value': twoTires ? -4 : 0,
                'func': setTwoTires,
                'status': twoTires
            },
            {
                'category': 'City Driving',
                'description' : '4 Tires on Curb While Driving',
                'value': fourTires ? -4 : 0,
                'func': setFourTires,
                'status': fourTires
            },
            {
                'category': 'City Driving',
                'description' : 'Max of 18in Side Clearance While Turning',
                'value': maxTurnClearance ? 0 : -4,
                'func': setMaxTurnClearance,
                'status': maxTurnClearance
            },
            {
                'category': 'City Driving',
                'description' : 'Signals Before Turn',
                'value': signal ? 0 : -2,
                'func': setSignal,
                'status': signal
            },
            {
                'category': 'City Driving',
                'description' : 'Right of Way',
                'value': rightOfWay ? 0 : -2,
                'func': setRightOfWay,
                'status': rightOfWay
            },
            {
                'category': 'City Driving',
                'description' : 'Safe Turn Speed',
                'value': turnSpeed ? 0 : -5,
                'func': setTurnSpeed,
                'status': turnSpeed
            },
            {
                'category': 'City Driving',
                'description' : 'Speeding 5-9 MPH over',
                'value': speedingLow ? -4 : 0,
                'func': setSpeedingLow,
                'status': speedingLow
            },
            {
                'category': 'City Driving',
                'description' : 'Speeding 10+ MPH over',
                'value': speedingHigh ? -26 : 0,
                'func': setSpeedingHigh,
                'status': speedingHigh
            },
            {
                'category': 'City Driving',
                'description' : 'Speeding in School Zone',
                'value': speedingSchoolZone ? -26 : 0,
                'func': setSpeedingSchoolZone,
                'status': speedingSchoolZone
            },
            {
                'category': 'City Driving',
                'description' : 'Brakes Smoothly',
                'value': brakesSmoothly ? 0 : -4,
                'func': setBrakesSmoothly,
                'status': brakesSmoothly
            },
            {
                'category': 'City Driving',
                'description' : 'Runs Stop Light',
                'value': runsStopLight ? -26 : 0,
                'func': setRunsStopLight,
                'status': runsStopLight
            },
            {
                'category': 'City Driving',
                'description' : 'Runs Stop Sign',
                'value': runsStopSign ? -26 : 0,
                'func': setRunsStopSign,
                'status': runsStopSign
            },
            {
                'category': 'City Driving',
                'description' : 'Rolls Back More Than 3ft',
                'value': rollsBack ? -10 : 0,
                'func': setRollsBack,
                'status': rollsBack
            },
            {
                'category': 'City Driving',
                'description' : 'Uses Flashers Moving 10 Under Posted Limit',
                'value': usesFlashers ? 0 : -3,
                'func': setUsesFlashers,
                'status': usesFlashers
            },
            {
                'category': 'City Driving',
                'description' : 'Safe Lane Change',
                'value': safeLaneChange ? 0 : -4,
                'func': setSafeLaneChange,
                'status': safeLaneChange
            },
            {
                'category': 'City Driving',
                'description' : 'Unsafe Action(Please Note)',
                'value': unsafeAction ? -26 : 0,
                'func': setUnsafeAction,
                'status': unsafeAction
            },
            {
                'category': 'Highway Driving',
                'description' : 'Enters Highway Safety',
                'value': entersSafely ? 0 : -2,
                'func': setEntersSafely,
                'status': entersSafely
            },
            {
                'category': 'Highway Driving',
                'description' : 'Used Signals Properly',
                'value': highwaySignals ? 0 : -2,
                'func': setHighwaySignals,
                'status': highwaySignals
            },
            {
                'category': 'Highway Driving',
                'description' : 'Safe Lane Change',
                'value': highwayLaneChange ? 0 : -4,
                'func': setHighwayLaneChange,
                'status': highwayLaneChange
            },
            {
                'category': 'Highway Driving',
                'description' : 'Following Distance',
                'value': distance ? 0 : -4,
                'func': setDistance,
                'status': distance
            },
            {
                'category': 'Highway Driving',
                'description' : 'Distracted/Eyes off road',
                'value': distracted ? -5 : 0,
                'func': setDistracted,
                'status': distracted
            },
            {
                'category': 'Highway Driving',
                'description' : 'Uses Flashers Moving Under 45 MPH',
                'value': highwayFlashers ? 0 : -2,
                'func': setHighwayFlashers,
                'status': highwayFlashers
            },
            {
                'category': 'Highway Driving',
                'description' : 'Right of Way',
                'value': highwayRightOfWay ? 0 : -2,
                'func': setHighWayRightOfWay,
                'status': highwayRightOfWay
            },
            {
                'category': 'Lane Control',
                'description' : 'Drifts',
                'value': drifts ? -10 : 0,
                'func': setDrifts,
                'status': drifts
            },
            {
                'category': 'Lane Control',
                'description' : 'Turn into Proper Lane',
                'value': turnLane ? 0 : -4,
                'func': setTurnLane,
                'status': turnLane
            },
            {
                'category': 'Lane Control',
                'description' : 'Overtaking in Correct Lane',
                'value': overtake ? 0 : -1,
                'func': setOvertake,
                'status': overtake
            },
            {
                'category': 'Sign Knowledge',
                'description' : 'Recognizes Road Signs',
                'value': roadsigns ? 0 : -1,
                'func': setRoadSigns,
                'status': roadsigns
            },
            {
                'category': 'Railroad Safety',
                'description' : 'Rolls both Windows Down',
                'value': windows ? 0 : -2,
                'func': setWindows,
                'status': windows
            },
            {
                'category': 'Railroad Safety',
                'description' : 'Stops on Crossing',
                'value': stopsCrossing ? -26 : 0,
                'func': setStopsCrossing,
                'status': stopsCrossing
            },
            {
                'category': 'Railroad Safety',
                'description' : 'Slows Speed Across Tracks',
                'value':trackSpeed ? 0 : -2,
                'func': setTrackSpeed,
                'status': trackSpeed
            },
            {
                'category': 'Railroad Safety',
                'description' : 'Looks Both Ways for Train',
                'value': looksBothWays ? 0 : -2,
                'func': setLooksBothWays,
                'status': looksBothWays
            },
            {
                'category': 'Railroad Safety',
                'description' : 'Uses Flashers',
                'value':railFlashers ? 0 : -2,
                'func': setRailFlashers,
                'status': railFlashers
            },
            {
                'category': 'Pretrip',
                'description' : 'The driver shows competence in knowing and understanding the anatomy of the truck. (Ensure the driver talks about what they’re looking at and what they’re looking for)',
                'value':pretrip1 ? 0 : -1,
                'func': setPretrip1,
                'status': pretrip1
            },
            {
                'category': 'Pretrip',
                'description' : 'Driver’s process during the inspection is thorough and organized.',
                'value':pretrip2 ? 0 : -1,
                'func': setPretrip2,
                'status': pretrip2
            },
            {
                'category': 'Pretrip',
                'description' : 'Driver shows proficiency in completing DVIR.',
                'value':pretrip3 ? 0 : -1,
                'func': setPretrip3,
                'status': pretrip3
            },
            {
                'category': 'Coupling and Uncoupling',
                'description' : 'Driver can properly couple (hook) to the trailer and completes both a tug test and Flashlight Test.',
                'value':coupling1 ? 0 : -1,
                'func': setCoupling1,
                'status': coupling1
            },
            {
                'category': 'Coupling and Uncoupling',
                'description' : 'Driver can properly uncouple (unhook) from the trailer.',
                'value':coupling2 ? 0 : -1,
                'func': setCoupling2,
                'status': coupling2
            },
        ]
        var total = deducted
        array.forEach(question => {
            total = total + question.value    
        })
        return {array, total}
    }
    const sqlDateReturn = (time: string) => {
        const date = new Date()
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        return year + "-" + month + "-" + day + " " + time
    }

    const sendRoadTestData = () => {
        const deductions = questions().total
        const score = 100 + deductions
        const passed = deductions <= -26 ? 0 : 1
        let issues: any = {}
        questions().array.forEach(item => {
            issues[item.description] = {'description': item.description, 'category': item.category, 'status': item.status, 'value': item.value}
        })
        issues = JSON.stringify(issues)
        ApiService.submitOrientationEval("orientation", {
            name: name,
            governmentId: governmentId,
            instructor: localStorage.getItem('username')!,
            reportData: issues,
            passed: passed,
            score: score,
            timeIn: sqlDateReturn(timeIn),
            timeOut: sqlDateReturn(timeOut),
            cdl: cdl ? 1 : 0,
            medcert: medcert ? 1 : 0,
            evalType: evalType,
            notes: notes
        });
    }

    const submitRoadTest = () => {
        const unit = 'in'
        const size = 'A4'
        const orientation = 'p'
        const doc = new jsPDF(orientation, unit, size)
        const header = [['General', 'Status', 'Points']]
        const cityHeader = [['City Driving', 'Status', 'Points']]
        const highwayHeader = [['Highway Driving', 'Status', 'Points']]
        const signHeader = [['Sign Knowledge', 'Status', 'Points']]
        const laneHeader = [['Lane Control', 'Status', 'Points']]
        const railHeader = [['Railroad Safety', 'Status', 'Points']]
        const driverHeader =[['Driver Name', 'Instructor']]
        const dateHeader = [['Date', 'Time In', 'Time Out']]
        const additionalHeader = [['CDL', 'Med Cert', 'Eval Type']]
        const additionalInfo = [[ cdl, medcert, evalType]]
        const dateInfo =[[today, timeIn, timeOut]]
        const driverInfo = [[name, localStorage.getItem('username')]]
        const generalData = questions().array.filter(el => el.category === 'General').map(el => [el.description,  el.status ? ' YES ' : ' NO ',  el.value])
        const cityData = questions().array.filter(el => el.category === 'City Driving').map(el => [el.description,  el.status ? ' YES ' : ' NO ',  el.value])
        const highwayData = questions().array.filter(el => el.category === 'Highway Driving').map(el => [el.description,  el.status ? ' YES ' : ' NO ',  el.value])
        const signData = questions().array.filter(el => el.category === 'Sign Knowledge').map(el => [el.description,  el.status ? ' YES ' : ' NO ',  el.value])
        const laneData = questions().array.filter(el => el.category === 'Lane Control').map(el => [el.description,  el.status ? ' YES ' : ' NO ',  el.value])
        const railData = questions().array.filter(el => el.category === 'Railroad Safety').map(el => [el.description,  el.status ? ' YES ' : ' NO ',  el.value])

        const columnStyle ={ 0: {cellWidth: 2.0}, 1: {cellWidth: 1.0}, 2: {cellWidth: 1.0}}
        const dateColumnStyle ={ 0: {cellWidth: 1.33}, 1: {cellWidth: 1.33}, 2: {cellWidth: 1.33}}
        const driverColumnStyle ={ 0: {cellWidth: 2.0}, 1: {cellWidth: 2.0}}
        const columns = [
            { dataKey: 'category', header: '%' },
            { dataKey: 'status', header: 'Status' },
            { dataKey: 'points', header: 'Points' },
        ]
        const content = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            columnStyles: columnStyle,
            willDrawCell: function (data: { row: { section: string; }; column: { dataKey: string; }; cell: { raw: number; }; }) {
                if (data.column.dataKey === 'points') {
                  if (data.cell.raw < 0) {
                    doc.setFillColor(255, 198, 198)
                    doc.setTextColor(255, 0, 0) // Red
                  }
                }
            },
            columns: columns,
            startY:3.0,
            margin: 0.1,
            head: header,
            body: generalData
        }
        const content2 = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            willDrawCell: function (data: { row: { section: string; }; column: { dataKey: string; }; cell: { raw: number; }; }) {
                if (data.column.dataKey === 'points') {
                  if (data.cell.raw < 0) {
                    doc.setFillColor(255, 198, 198)
                    doc.setTextColor(255, 0, 0) // Red
                  }
                }
            },
            columns: columns,
            columnStyles: columnStyle,
            startY: doc.lastAutoTable.finalY,
            margin: 0.1,
            head: cityHeader,
            body: cityData
        }
        const content3 = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            columnStyles: columnStyle,
            willDrawCell: function (data: { row: { section: string; }; column: { dataKey: string; }; cell: { raw: number; }; }) {
                if (data.column.dataKey === 'points') {
                  if (data.cell.raw < 0) {
                    doc.setFillColor(255, 198, 198)
                    doc.setTextColor(255, 0, 0) // Red
                  }
                }
            },
            columns: columns,
            startY: 3.0,
            margin: {
                top: 0.1,
                left: 4.2
            },
            head: highwayHeader,
            body: highwayData
        }
        const content4 = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            willDrawCell: function (data: { row: { section: string; }; column: { dataKey: string; }; cell: { raw: number; }; }) {
                if (data.column.dataKey === 'points') {
                  if (data.cell.raw < 0) {
                    doc.setFillColor(255, 198, 198)
                    doc.setTextColor(255, 0, 0) // Red
                  }
                }
            },
            columns: columns,
            columnStyles: columnStyle,
            startY: doc.lastAutoTable.finalY,
            margin: {
                top: 0.1,
                left: 4.2
            },
            head: signHeader,
            body: signData
        }
        const content5 = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            willDrawCell: function (data: { row: { section: string; }; column: { dataKey: string; }; cell: { raw: number; }; }) {
                if (data.column.dataKey === 'points') {
                  if (data.cell.raw < 0) {
                    doc.setFillColor(255, 198, 198)
                    doc.setTextColor(255, 0, 0) // Red
                  }
                }
            },
            columns: columns,
            columnStyles: columnStyle,
            startY: doc.lastAutoTable.finalY,
            margin: {
                top: 0.1,
                left: 4.2
            },
            head: laneHeader,
            body: laneData
        }
        const content6 = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            willDrawCell: function (data: { row: { section: string; }; column: { dataKey: string; }; cell: { raw: number; }; }) {
                if (data.column.dataKey === 'points') {
                  if (data.cell.raw < 0) {
                    doc.setFillColor(255, 198, 198)
                    doc.setTextColor(255, 0, 0) // Red
                  }
                }
            },
            columns: columns,
            columnStyles: columnStyle,
            startY: doc.lastAutoTable.finalY,
            margin: {
                top: 0.1,
                left: 4.2
            },
            head: railHeader,
            body: railData
        }
        const noteHeader = [['Notes:']]
        const noteContent = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },

            startY: 11,
            margin: {
                top: 0.1,
                left: 0.1,
                right: 0.1
            },
            head: noteHeader,
            body: [[notes]]
        }
        const driverContent = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            columnStyle: driverColumnStyle,
            columns: [ { dataKey: 'name', header: 'Driver Name' },  { dataKey: 'instructor', header: 'Instructor' } ],
            startY: 0.5,
            margin: {
                top: 0.1,
                left: 0.1,
                right: 0.1
          
            },
            head: driverHeader,
            body: driverInfo
        }
        const dateContent = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            columnStyle: dateColumnStyle,
            startY: doc.lastAutoTable.finalY,
            margin: {
                top: 0.1,
                left: 0.1,
                right: 0.1
        
            },
            head: dateHeader,
            body: dateInfo
        }
        const addContent = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            startY: doc.lastAutoTable.finalY,
            margin: {
                top: 0.1,
                left: 0.1,
                right: 0.1
 
            },
            head: additionalHeader,
            body: additionalInfo
        }
        var scoreInfo = new Image()
        scoreInfo.src = '/assets/driver_eval.JPG'
        
        const score = 100 + questions().total
        const pass = questions().total <= -26 ? 'NO' : 'YES'
        doc.text('Driver Evaluation Form', 3.0, 0.3)
        doc.autoTable(driverContent)
        doc.autoTable(dateContent)
        doc.autoTable(addContent)
        doc.autoTable(content)
        doc.autoTable(content2)
        doc.autoTable(content3)
        doc.autoTable(content4)
        doc.autoTable(content5)
        doc.autoTable(content6)
        doc.text('Score:'+ score + '/100 Points \nDeducted: ' + questions().total +  '\nPASS: ' + pass, 4.2, doc.lastAutoTable.finalY + 0.5)
        doc.autoTable(noteContent)
        const fileData = doc.output('datauristring').split('base64,')[1];
        // console.log(fileData)
        var blobPDF =  new Blob([ doc.output() ], { type : 'application/pdf'});
        const fileSize = blobPDF.size
        
        if (ready && explained) {
           sendRoadTestData()
           submitTest(governmentId, fileData, fileSize)
        if (formType === 'DRIVER_TRAINER') {
            fillForm();
        }      
        } else {
            toastStore.getState().setToast('Please confirm you are ready to perform an evaluation, and you have explained all rules and requirements.');
        }
    }

    async function submitTest(ssn: string, data: string, size: number) {
        const passed = questions().total <= -26 ? 0 : 1
        ApiService.submitTenstreetDocument("orientation", {
            ssn: ssn,
            fileId: 1,
            fileCode: 'RoadTest',
            fileStorageType: 'encoded string',
            fileCategoryName: 'DQF',
            filename: name + '_evaluation_' + (passed ? 'pass' : 'fail') + '.pdf',
            fileSize: size,
            fileType: 'application/pdf',
            fileData: data,
            mode: 'PROD'
        }).then(() => ApiService.updateOrientation("orientation", {ssn, passed, completed: 1}))
            .then(() => {
                toastStore.getState().setSuccessToast('Success! Your test was submitted.');
                history.push('/orientationroadtests');
            })
            .finally(() => setLoading(false));
    }

    const general = questions().array.filter(el => el.category === "General").map((item ,index) => {
        return (
            <div key={index} onClick={() => item.func(!item.status)} className="item-container">
                <OrientationDriverEvalItem
                    key={index}
                    category={item.category}
                    description={item.description}
                    status={item.status}
                    value={item.value}
                ></OrientationDriverEvalItem>
            </div>
        )
    })
    const cityDriving = questions().array.filter(el => el.category === "City Driving").map((item ,index) => {
        return (
            <div onClick={() => item.func(!item.status)}className="item-container">
                <OrientationDriverEvalItem
                    key={index}
                    category={item.category}
                    description={item.description}
                    status={item.status}
                    value={item.value}
                ></OrientationDriverEvalItem>
            </div>
        )
    })
    const highwayDriving = questions().array.filter(el => el.category === "Highway Driving").map((item ,index) => {
        return (
            <div onClick={() => item.func(!item.status)}className="item-container">
                <OrientationDriverEvalItem
                    key={index}
                    category={item.category}
                    description={item.description}
                    status={item.status}
                    value={item.value}
                ></OrientationDriverEvalItem>
            </div>
        )
    })
    const laneControl = questions().array.filter(el => el.category === "Lane Control").map((item ,index) => {
        return (
            <div onClick={() => item.func(!item.status)}className="item-container">
                <OrientationDriverEvalItem
                    key={index}
                    category={item.category}
                    description={item.description}
                    status={item.status}
                    value={item.value}
                ></OrientationDriverEvalItem>
            </div>
        )
    })
    const signKnowledge = questions().array.filter(el => el.category === "Sign Knowledge").map((item ,index) => {
        return (
            <div onClick={() => item.func(!item.status)}className="item-container">
                <OrientationDriverEvalItem
                    key={index}
                    category={item.category}
                    description={item.description}
                    status={item.status}
                    value={item.value}
                ></OrientationDriverEvalItem>
            </div>
        )
    })
    const railroadSafety = questions().array.filter(el => el.category === "Railroad Safety").map((item ,index) => {
        return (
            <div onClick={() => item.func(!item.status)}className="item-container">
                <OrientationDriverEvalItem
                    key={index}
                    category={item.category}
                    description={item.description}
                    status={item.status}
                    value={item.value}
                ></OrientationDriverEvalItem>
            </div>
        )
    })
    const pretrip = questions().array.filter(el => el.category === "Pretrip").map((item ,index) => {
        return (
            <div onClick={() => item.func(!item.status)}className="item-container">
                <OrientationDriverEvalItem
                    key={index}
                    category={item.category}
                    description={item.description}
                    status={item.status}
                    value={item.value}
                ></OrientationDriverEvalItem>
            </div>
        )
    })
    const coupling = questions().array.filter(el => el.category === "Coupling and Uncoupling").map((item ,index) => {
        return (
            <div onClick={() => item.func(!item.status)}className="item-container">
                <OrientationDriverEvalItem
                    key={index}
                    category={item.category}
                    description={item.description}
                    status={item.status}
                    value={item.value}
                ></OrientationDriverEvalItem>
            </div>
        )
    })

    const getRadioRatingValues = () => <>
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
        <option value={5}>5</option>
        <option value={6}>6</option>
        <option value={7}>7</option>
        <option value={8}>8</option>
        <option value={9}>9</option>
    </>;

    const backingEvaluationNumber = [
        {name: 'Time', value: backingTime, setter: setBackingTime},
        {name: '# of Pull-ups', value: backingPullups, setter: setBackingPullups},
        {name: '# of Hit(s)', value: backingHits, setter: setBackingHits},
    ].map(it => {
        
        return <div style={{margin: "auto 25px"}}>
            <span style={{paddingLeft: "20px", display: "block", marginTop: "20px", paddingBottom: "10px", fontWeight: "bold"}}>{it.name}<span className="color-danger">*</span></span>
            <select name={it.name} className="input" onChange={e => it.setter(e.target.value!)}>
                {getRadioRatingValues()}
            </select>
        </div>
    });

    const backingEvaluationYN = [
        {name: 'Horn / GOAL', value: backingHorn, setter: setBackingHorn},
        {name: 'P/F', value: backingPf, setter: setBackingPf}
    ].map(it => {
        
        return <div style={{margin: "auto 25px"}}>
            <span style={{paddingLeft: "20px", display: "block", marginTop: "20px", paddingBottom: "10px", fontWeight: "bold"}}>{it.name}<span className="color-danger">*</span></span>
            <select name={it.name} className="input" onChange={e => it.setter(e.target.value!)}>
                <option value={"Yes"}>Yes</option>
                <option value={"No"}>No</option>
            </select>
        </div>
    });

    function handleType(type: React.SetStateAction<string>) {
        setEvalType(type)
        if (type === 'CHAT TN' || type === 'PEGRAM') {
            setWindows(true)
            setTrackSpeed(true)
            setLooksBothWays(true)
            setRailFlashers(true)
        } else {
            setWindows(false)
            setTrackSpeed(false)
            setLooksBothWays(false)
            setRailFlashers(false)
        }
    }

    const b64toBlob = (base64:any) => 
    fetch(base64).then(res => res.blob())

    function getIntials(name:string) {
        const first = name.substring(0, name.indexOf(' '))[0]
        const last = name.substring(name.indexOf(' ') + 1)[0];
        const intials = first + " " + last
        return intials;
    }

    async function fillForm() {
      if(signatureData === ""){
          toastStore.getState().setToast('Please save your signature.');
      }
        const formURL = "./assets/driverTrainerRoadEvaluationForm.pdf"
        const pdfFormBytes = await fetch(formURL).then(res => res.arrayBuffer())

        const pdfDoc = await PDFDocument.load(pdfFormBytes)
        const form = pdfDoc.getForm()

        const fields = form.getFields()

        fields.forEach((field: any) => {
            const type = field.constructor.name
            const name = field.getName()
        })

        const deductions = questions().total
        const score = 100 + deductions
        const passed = deductions <= -26 ? "FAIL" : "PASS"
        const intials = getIntials(name)

        var driverNameField = form.getTextField('Drivers Name');
        var driverCodeField = form.getTextField('Driver Code');
        var ssn4Field = form.getTextField('SSN Last 4');
        var licenseNumberField = form.getTextField('License Number');
        var LicensingStateField = form.getTextField('Licensing State');
        var typeOfTrailer = form.getTextField("Type of Trailer");
        var dateOfEval = form.getTextField("Date of the Evaluation");
        var totalScoreField = form.getTextField('Total Score');
        var passFailField = form.getTextField('Pass  Fail');
        var Comments1 = form.getTextField('Comments 1');
        var Pretrip1 = form.getTextField(pretrip1 ? "Yes_1" : "No_1");
        var Pretrip2 = form.getTextField(pretrip2 ? "Yes_2" : "No_2");
        var Pretrip3 = form.getTextField(pretrip3 ? "Yes_3" : "No_3");
        var Coupling1 = form.getTextField(coupling1 ? "Yes_4" : "No_4");
        var Coupling2 = form.getTextField(coupling2 ? "Yes_5" : "No_5");
        var Comments2 = form.getTextField("Comments 1_2");

        var DriversPrintName = form.getTextField("Drivers Name PRINT FULL GIVEN NAME");
        var Date1 = form.getTextField("Date");
        var SSN = form.getTextField("SSN");
        var driverCodeField2 = form.getTextField("Driver Code 2");
        var general1 = form.getTextField(truckInMotion ? "Yes 2-1" : "NO 2-1");
        var general2 = form.getTextField(threePoint ? "Yes 2-2" : "NO 2-2");
        var general3 = form.getTextField(seatBelt ? "Yes 2-3" : "NO 2-3");
        var general4 = form.getTextField(mirrorUse ? "Yes 2-4" : "NO 2-4");
        var city1 = form.getTextField(turnup ? "Yes 2-5" : "NO 2-5");
        var city2 = form.getTextField(twoTires ? "NO 2-6" : "Yes 2-6");
        var city3 = form.getTextField(fourTires ? "NO 2-7" : "Yes 2-7");
        var city4 = form.getTextField(maxTurnClearance ? "Yes 2-8" : "NO 2-8");
        var city5 = form.getTextField(signal ? "Yes 2-9" : "NO 2-9");
        var city6 = form.getTextField(rightOfWay ? "Yes 2-10" : "NO 2-10");
        var city7 = form.getTextField(turnSpeed ? "Yes 2-11" : "NO 2-11");
        var city8 = form.getTextField(speedingLow ? "NO 2-12" : "Yes 2-12");
        var city9 = form.getTextField(speedingHigh ? "NO 2-13" : "Yes 2-13");
        var city10 = form.getTextField(speedingSchoolZone ? "NO 2-14" : "Yes 2-14");
        var city11 = form.getTextField(brakesSmoothly ? "Yes 2-15" : "NO 2-15");
        var city12 = form.getTextField(runsStopLight ? "NO 2-16" : "Yes 2-16");
        var city13 = form.getTextField(runsStopSign ? "NO 2-17" : "Yes 2-17");
        var city14 = form.getTextField(rollsBack ? "NO 2-18" : "Yes 2-18");
        var city15 = form.getTextField(usesFlashers ? "Yes 2-19" : "NO 2-19");
        var city16 = form.getTextField(safeLaneChange ? "Yes 2-20" : "NO 2-20");
        var city17 = form.getTextField(unsafeAction ? "NO 2-21" : "Yes 2-21");
        var highway1 = form.getTextField(entersSafely ? "Yes 2-22" : "NO 2-22");
        var highway2 = form.getTextField(highwaySignals ? "Yes 2-23" : "NO 2-23");
        var highway3 = form.getTextField(highwayLaneChange ? "Yes 2-24" : "NO 2-24");
        var highway4 = form.getTextField(distance ? "Yes 2-25" : "NO 2-25");
        var highway5 = form.getTextField(distracted ? "Yes 2-26" : "NO 2-26");
        var highway6 = form.getTextField(highwayFlashers ? "Yes 2-27" : "NO 2-27");
        var highway7 = form.getTextField(highwayRightOfWay ? "Yes 2-28" : "NO 2-28");
        var lane1 = form.getTextField(drifts ? "NO 2-29" : "Yes 2-29");
        var lane2 = form.getTextField(turnLane ? "Yes 2-30" : "NO 2-30");
        var lane3 = form.getTextField(overtake ? "Yes 2-31" : "NO 2-31");
        var signs1 = form.getTextField(roadsigns ? "Yes 2-32" : "NO 2-32");
        var rail1 = form.getTextField(windows ? "Yes 2-33" : "NO 2-33");
        var rail2 = form.getTextField(stopsCrossing ? "NO 2-34" : "Yes 2-34");
        var rail3 = form.getTextField(trackSpeed ? "Yes 2-35" : "NO 2-35");
        var rail4 = form.getTextField(looksBothWays ? "Yes 2-36" : "NO 2-36");
        var rail5 = form.getTextField(railFlashers ? "Yes 2-37" : "NO 2-37");
        var totalYes = form.getTextField("YES 2TOTALS");
        var totalNo = form.getTextField("NO 2TOTALS");
        var driverInitials = form.getTextField("Driver Initial")
        var Comments3 = form.getTextField('Comments 2_1');
        var totalDeductions = form.getTextField(questions().total <= -26 ? "Fail" : "Pass");
        var Date2 = form.getTextField("Date_2");
        var Date3 = form.getTextField("Date_3");

        var DriversPrintName2 = form.getTextField("NAME PRINT FULL GIVEN NAME");
        var SSN2 = form.getTextField("SSN_2");
        var Date4 = form.getTextField("DATE BACKING");
        var backing1 = form.getTextField(backingTime.toString() + "Time");
        var backing2 = form.getTextField("1Horn  GOAL");
        var backing3 = form.getTextField(backingPullups.toString() + " of PullUps");
        var backing4 = form.getTextField(backingHits.toString() + " of Hits");
        var backing5 = form.getTextField("1PF");
        var instructorName = form.getTextField("INSTRUCTOR NAME PRINT")


        driverNameField.setText(name);
        driverCodeField.setText(driverId);
        ssn4Field.setText("xxx-xx-" + ssn);
        licenseNumberField.setText(licenseNumber);
        LicensingStateField.setText(licensingState);
        typeOfTrailer.setText(" ");
        dateOfEval.setText(new Date().toLocaleDateString().slice(0,10));
        totalScoreField.setText(score.toString());
        passFailField.setText(passed);
        Comments1.setText(notes);
        Pretrip1.setText(" ----------- ");
        Pretrip2.setText(" ----------- ");
        Pretrip3.setText(" ----------- ");
        Coupling1.setText(" ----------- ");
        Coupling2.setText(" ----------- ");
        Comments2.setText("");
        DriversPrintName.setText(name);
        Date1.setText(new Date().toLocaleDateString().slice(0,10));
        SSN.setText("xxx-xx=" + ssn)
        driverCodeField2.setText(driverId)
        general1.setText(" ----------- ");
        general2.setText(" ----------- ");
        general3.setText(" ----------- ");
        general4.setText(" ----------- ");
        city1.setText(" ----------- ");
        city2.setText(" ----------- ");
        city3.setText(" ----------- ");
        city4.setText(" ----------- ");
        city5.setText(" ----------- ");
        city6.setText(" ----------- ");
        city7.setText(" ----------- ");
        city8.setText(" ----------- ");
        city9.setText(" ----------- ");
        city10.setText(" ----------- ");
        city11.setText(" ----------- ");
        city12.setText(" ----------- ");
        city13.setText(" ----------- ");
        city14.setText(" ----------- ");
        city15.setText(" ----------- ");
        city16.setText(" ----------- ");
        city17.setText(" ----------- ");
        highway1.setText(" ----------- ");
        highway2.setText(" ----------- ");
        highway3.setText(" ----------- ");
        highway4.setText(" ----------- ");
        highway5.setText(" ----------- ");
        highway6.setText(" ----------- ");
        highway7.setText(" ----------- ");
        lane1.setText(" ----------- ");
        lane2.setText(" ----------- ");
        lane3.setText(" ----------- ");
        signs1.setText(" ----------- ");
        rail1.setText(" ----------- ");
        rail2.setText(" ----------- ");
        rail3.setText(" ----------- ");
        rail4.setText(" ----------- ");
        rail5.setText(" ----------- ");
        // totalYes.setText()
        // totalNo.setText()
        driverInitials.setText(intials)
        Comments3.setText(notes)
        totalDeductions.setText(deductions.toString())
        Date2.setText(new Date().toLocaleDateString().slice(0,10))
        Date3.setText(new Date().toLocaleDateString().slice(0,10))
        DriversPrintName2.setText(name);
        SSN2.setText("xxx-xx=" + ssn);
        Date4.setText(new Date().toLocaleDateString().slice(0,10));
        backing1.setText(" ----------- ");
        backing2.setText(" ----------- ");
        backing3.setText(" ----------- ");
        backing4.setText(" ----------- ");
        backing5.setText("");
        instructorName.setText(userDetails.firstName + " " + userDetails.lastName);
    
        const passengerSignatureBytes = await fetch(signatureData).then((res) => res.arrayBuffer())
        const passengerImageSignature = await pdfDoc.embedPng(passengerSignatureBytes)


        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const secondPage = pages[2];
        const pngDims = passengerImageSignature.scale(0.2);

        firstPage.drawImage(passengerImageSignature, {
            x: firstPage.getWidth() / 2 - pngDims.width / 2 - 75 ,
            y: firstPage.getHeight() / 2 - pngDims.height / 2 - 170,
            width: pngDims.width,
            height: pngDims.height,
          })
          secondPage.drawImage(passengerImageSignature, {
            x: firstPage.getWidth() / 2 - pngDims.width / 2 - 100 ,
            y: firstPage.getHeight() / 2 - pngDims.height / 2 - 330,
            width: pngDims.width,
            height: pngDims.height,
          })
        
       
        const usefulData = await pdfDoc.saveAsBase64({dataUri: true})
        setDataURL(usefulData)
        submitForm(usefulData)
    }
    
    // useEffect(() => {
    //     getIntials(name);
    //   }, []);

    async function submitForm(data: string) {
        console.log("data", data);
        let tempFile = new Blob([(await b64toBlob(data))], {type: 'application/pdf'})
        const fileSize = tempFile.size
        ApiService.submitTenstreetDocument("orientation", {
            ssn: "xxx-xx=" + ssn,
            fileId: 1,
            fileCode: 'DriverTrainerEval',
            fileStorageType: 'encoded string',
            fileCategoryName: 'DQF',
            filename: name + '_trainer_evaluation' + '.pdf',
            fileSize: fileSize,
            fileType: 'application/pdf',
            fileData: data,
            mode: 'PROD'
        })
        .then(() => {
            toastStore.getState().setSuccessToast('Success! Your test was submitted.');
            history.push('/orientationroadtests');
        })
        .finally(() => setLoading(false));
    }

    const save = () => {
        const signed = sigCanvas.current!.toDataURL()
        setSignatueData(signed)
        setShowSignature(!showSignature)
    }

    const clear = () => sigCanvas.current!.clear()

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
            <IonButton slot="start" style={{padding: 0}}><p style={{fontFamily: "Montserrat, sans-seriff"}} onClick={() => hideTest()}>Back</p></IonButton>
            <IonTitle>Driver Evaluation Form</IonTitle>
          </IonToolbar>
        </IonHeader>
           
        <IonContent fullscreen color="light">
        <div className='item-container' style={{ position: "sticky", top: "16px", zIndex: 10, backgroundColor: "#ffffff", boxShadow: "0 4px 4px rgba(0,0,0,0.25)", borderRadius:"10px", padding: "8px"}} onClick={() => setMoreinfo(!moreInfo)}>
            <p className="subtitle" style={{width: "100%", textAlign: "center", padding: 0, color: "#D0A024"}}>{moreInfo ? "Less Detail" : "More Detail"}</p>
            <div style={{ display: "flex"}}>
                <p className='title'>{name}</p>
                <p className='subtitle'>Test Date: {today}</p>
            </div>
           
            <div style={{display: "flex", gap: "32px"}}>
                    <p>Score: <span style={{ color: 100 + questions().total <= 74 ? "#FF0000" : "#2fd573"}}>{100 + questions().total} / 100</span></p>
                    <p>Points deducted: <span style={{ color :  "#FF0000"}}>{questions().total}</span></p>
            </div>
            <p style={{ margin: 0}}>Pass: {questions().total <= -26 ? 'NO' : 'YES'}</p>
            {/* <div style={{ display: "flex"}}>
                <p style={{ fontSize: "small", padding: "0 8px", color: "#2fd573"}}>Completion: {getCompletetion()} </p>
                <p style={{ fontSize: "small", padding: "0 8px"}}>Grade: <span style={{ color: grade === "Pass" ? "#2fd573" : "#FF0000"}}>{grade}</span></p>
            </div> */}
                {moreInfo ? <div>
                    <div style={{ display: "flex"}}>
                        <p className="subtitle">Instructor: {localStorage.getItem('username')}</p>
                        <p className="subtitle">Government ID: {'xxx-xx-'+ governmentId.toString().slice(5,9)}</p>
                    </div>
                    
                    <p style={{ marginBottom: "8px", borderBottom: "1px solid #d3d3d3"}}>Scoring Info</p>
                    <strong >Total Points: 100</strong><br/>
                    <strong >Pass: 75+</strong><br/>
                    <strong >Fail: 74-</strong><br/>
                    <br/>
                    <p>PEGRAM, CHAT TN - No Railroad Safety Portion, Applicant Gets All Points Automatically</p>
                    <strong >Automatic Failure Conditions:</strong><br/>
                    <ul>
                        <li>Speeding 10+ MPH over</li>
                        <li>Speeding in a School Zone</li>
                        <li>Runs Stop Light</li>
                        <li>Runs Stop Sign</li>
                        <li>Unsafe Action (Please note)</li>
                        <li>Stops on Crossing</li>
                    </ul>
                </div> : null}
            </div>

            <IonItem color="light" lines="none">
                <div className="item-container">
                    <div className="input-container">
                        <select id="eval" className="input" placeholder=" " value={formType} onChange={e => setFormType(e.target.value!)} style={{ height: "20px"}}>
                            <option value="DRIVER">Driver</option>
                            <option value="DRIVER_TRAINER">Driver - Trainer</option>
                        </select>
                        <p className="placeholder" >Form type</p>
                    </div>
                </div>
            </IonItem>

            <IonItem lines="none" color="light">
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    {formType === 'DRIVER_TRAINER' ?
                        <div>
                            <div className="item-container">
                                <div className="input-container">
                                    <input type="number" className="input" value={ssn} onChange={e => setSsn(e.target.value!)}/>
                                    <p className="placeholder">SSN Last 4</p>
                                </div>
                            </div>
                            <div className="item-container">
                                <div className="input-container">
                                    <input type="text" className="input" value={licenseNumber} onChange={e => setLicenseNumber(e.target.value!)}/>
                                    <p className="placeholder">License Number</p>
                                </div>
                            </div>
                            <div className="item-container">
                                <div className="input-container">
                                    <input type="text" className="input" value={licensingState} onChange={e => setLicensingState(e.target.value!)}/>
                                    <p className="placeholder">Licensing State</p>
                                </div>
                            </div>
                        </div>
                        : ''}
                    <div className="item-container" style={{display: "flex", gap: "8px"}}>
                        <div className="input-container">
                            <input type="time" className="input" value={timeIn} onChange={e => setTimeIn(e.target.value!)}/>
                            <p className="placeholder">Time In</p>
                        </div>
                        <div className="input-container">
                            <input type="time" className="input" value={timeOut} onChange={e => setTimeOut(e.target.value!)}/>
                            <p className="placeholder">Time Out</p>
                        </div>
                    </div>
                </div>
            </IonItem>

            <div className="orientation-roadtest">
                {/* <IonToast
            isOpen={loading}
            message="Please wait... your test is being submitted."
            color="warning"
            duration={2000}
        /> */}
                <IonModal isOpen={loading} id="transparent">
                    <div className="modal-div">
                        <div id="modal">
                            <div className='loading-div'>
                                <p>Please wait... your test is being submitted.</p>
                                <div className="snippet" data-title=".dot-flashing" style={{ margin: "4px 16px"}}>
                            <div className="stage">
                              <div className="dot-flashing"></div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </IonModal>
            <IonItem  color="light" lines="none">CDL<IonToggle slot="end" checked={cdl} onIonChange={e => setCDL(e.detail.checked)}color="success" /></IonItem>
            <IonItem  color="light" lines="none">Med Cert<IonToggle slot="end" checked={medcert} onIonChange={e => setMedcert(e.detail.checked)}color="success" /></IonItem>
            <IonItem color="light" lines="none">
                <div className="item-container">
                <div className="input-container">
                    <select id="eval" className="input" placeholder=" " value={evalType} onChange={e => handleType(e.target.value!)} style={{ height: "20px"}}>
                    <option value="Eval">Eval</option>
                    <option value="City">City</option>
                    <option value="AC 2 LN">AC 2 LN</option>
                    <option value="600">600</option>
                    <option value="CHAT TN">CHAT TN</option>
                    <option value="PEGRAM">PEGRAM</option>        
                </select>
                <p className="placeholder" >Eval Type</p>
              </div>
                </div>
            </IonItem>
            <IonItem lines="none" color="light">
                <div className="item-container">
                    <div style={{display:"flex", justifyContent: "space-between"}}>
                        <p>I am ready and able to perform this evaluation</p>
                        <input type="checkbox" onChange={e => setReady(e.target.checked!)}/>
                    </div>
                    <div style={{display:"flex", justifyContent: "space-between"}}>
                        <p>I have explained the exceptions and requirements, including the conditions that will result in an automatic fail</p>
                        <input type="checkbox" onChange={e => setExplained(e.target.checked!)}/>
                    </div>
                </div>
            </IonItem>
   
         <IonItem color="light" lines="none" className="roadtest-category"onClick={() => setShowGeneral(!showGeneral)}><strong>General</strong><IonIcon slot="end" icon={showGeneral ? chevronDown : chevronForward}></IonIcon></IonItem>
         {showGeneral ? general : ''}
 

         <IonItem color="light" lines="none" className="roadtest-category"onClick={() => setShowCityDriving(!showCityDriving)}><strong>City Driving</strong><IonIcon slot="end" icon={showCityDriving ? chevronDown : chevronForward}></IonIcon></IonItem>
         {showCityDriving ? cityDriving : ''}

  
         <IonItem color="light" lines="none" className="roadtest-category"onClick={() => setShowHighwayDriving(!showHighwayDriving)}><strong>Highway Driving</strong><IonIcon slot="end" icon={showHighwayDriving ? chevronDown : chevronForward}></IonIcon></IonItem>
         {showHighwayDriving ? highwayDriving : ''}
  

         <IonItem color="light" lines="none" className="roadtest-category"onClick={() => setShowLaneControl(!showLaneControl)}><strong>Lane Control</strong><IonIcon slot="end" icon={showLaneControl ? chevronDown : chevronForward}></IonIcon></IonItem>
         {showLaneControl ? laneControl : ''}


         <IonItem color="light" lines="none" className="roadtest-category"onClick={() => setShowSignKnowledge(!showSignKnowledge)}><strong>Sign Knowledge</strong><IonIcon slot="end" icon={showSignKnowledge ? chevronDown : chevronForward}></IonIcon></IonItem>
         {showSignKnowledge ? signKnowledge : ''}

         <IonItem color="light" lines="none" className="roadtest-category"onClick={() => setShowRailroadSafety(!showRailroadSafety)}><strong>Railroad Safety</strong><IonIcon slot="end" icon={showRailroadSafety ? chevronDown : chevronForward}></IonIcon></IonItem>
         {showRailroadSafety ? railroadSafety : ''}

        {formType === 'DRIVER_TRAINER' ?
        <div>
            <IonItem color="light" lines="none" className="roadtest-category" onClick={() => setShowPretrip(!showPretrip)}><strong>Pretrip</strong><IonIcon slot="end" icon={showPretrip ? chevronDown : chevronForward}></IonIcon></IonItem>
            {showPretrip ? pretrip : ''}

            <IonItem color="light" lines="none" className="roadtest-category" onClick={() => setShowCoupling(!showCoupling)}><strong>Coupling and Uncoupling</strong><IonIcon slot="end" icon={showCoupling ? chevronDown : chevronForward}></IonIcon></IonItem>
            {showCoupling ? coupling : ''}

            <IonItem color="light" lines="none" className="roadtest-category" onClick={() => setShowBackingEvaluation(!showBackingEvaluation)}><strong>Backing Evaluation</strong><IonIcon slot="end" icon={showBackingEvaluation ? chevronDown : chevronForward}></IonIcon></IonItem>
            {showBackingEvaluation ? backingEvaluationNumber.map(it => it) : ''}
            
            {showBackingEvaluation ? backingEvaluationYN.map(it => it) : ''}

            <IonItem lines="none" color="light">
                    <div className="item-container">
                      <p>Instructor Signature</p>
                      <div hidden={!showSignature}style={{textAlign: "center", width: "90%", overflow: "hidden", backgroundColor: "white",  margin: "auto", borderRadius: "20px" }}>
                          <SignaturePad
                          ref={sigCanvas}
                          canvasProps={{ width: 500, height: 175, className: 'sigCanvas'}}
                          />
                      </div>
                      <div className="item-container" style={{ display: "flex", justifyContent: "space-between"}}>
                        {showSignature ? <p onClick={save} style={{ color: "#212121"}}>Save</p> : <button onClick={save} className="button">Re-sign</button>}
                        {showSignature ? <p style={{color: "#5096FF"}} onClick={clear}>Clear</p>: null}
                      </div>

                    </div>

                  </IonItem>
        </div>
        : ''}
     <IonItem lines="none" color="light">
        <div className='item-container'>
        <div className="input-container">
            <textarea id="note-box" className="input"  placeholder=" " value={notes} onChange={e => setNotes(e.target.value!)}/>
            <p className="placeholder" >Notes</p>
        </div>
        </div>
    </IonItem>
    <IonItem lines="none" color="light">
        <div className="item-container">
            <button className="western-button submit" onClick={() => {submitRoadTest()}}>SUBMIT</button>
        </div>
    </IonItem>
        </div>
        </IonContent>
   
        </IonPage>
    )
}

export default OrientationRoadTest
