import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {Link} from "react-router-dom";
import {ApiService} from "../api/ApiService";
import {LoadHistoryInfo} from "../api/model/LoadHistory";
import {DateUtils} from "../utils/DateUtils";
import '../theme/loadHistory.css'
import {chevronDownOutline, chevronUpOutline} from "ionicons/icons";

const LoadHistory: React.FC = () => {

    const [lastNum, setLastNum] = useState(0);
    const [loads, setLoads] = useState<{ [key: string]: LoadHistoryInfo[] }>({});
    const [openDates, setOpenDates] = useState<string[]>([]);
    const [allLoaded, setAllLoaded] = useState<boolean>(false);

    useEffect(() => {
        loadMore(0);
    }, []);

    const loadMore = (add: number) => {
        ApiService.getLoadHistory("loadHistory", {start: lastNum + add, length: 24}, true).then(it => {
            setLoads(parseLoads(it));
            setAllLoaded(it.length !== 24);
            setLastNum(lastNum + 24);
            if (add === 0) {
                setOpenDates([Object.keys(loads)[0]]);
            }
        })
    }

    const parseLoads = (data: LoadHistoryInfo[]) => data.reduce((loads, load) => {
        const loadArray = loads[load.paidDate] || [];
        loadArray.push(load);
        loads[load.paidDate] = loadArray;
        return loads;
    }, loads);

    const toggleDate = (date: string) => {
        const newOpenDates = openDates.concat([]);
        newOpenDates.includes(date) ? newOpenDates.splice(newOpenDates.indexOf(date), 1) : newOpenDates.push(date);
        setOpenDates(newOpenDates);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Load History</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="light">
                <div className="container" style={{height: "70%"}}>
                    {Object.keys(loads).map((loadDate) =>
                        <IonItem key={loadDate} button mode={'ios'} detail={false} color={'light'} className={'load-date ' + (openDates.includes(loadDate) ? 'open' : '')}
                                 onClick={() => toggleDate(loadDate)}>
                            <div className="load-date-content">
                                <div className="load-date-header d-flex justify-content-between w-100 align-items-center gap-2">
                                    <div>
                                        <p>Pay Period: {loadDate && loadDate !== 'null' ? loadDate : 'Active'}</p>
                                        <p className="load-date-sub">Loads : {loads[loadDate].length}</p>
                                    </div>
                                    <IonIcon icon={openDates.includes(loadDate) ? chevronUpOutline : chevronDownOutline} slot={'end'} className="mx-3"></IonIcon>
                                </div>
                                {openDates.includes(loadDate) &&
                                    loads[loadDate].map((load, index) => {
                                        let isFiled = !(load.emptyDate && !load.paperworkDate)
                                        return <div key={loadDate + index}>{isFiled ?
                                            <Link to={"/loadhistory/" + load.orderNumber} className="load-history">
                                                <IonItem color="light" lines="none">
                                                    <div className='item-container mx-0 my-1 w-100'>
                                                        <h4 className='title boldest' style={{color: "#99cc00"}}>{load.orderNumber}</h4>
                                                        <p>Complete: {DateUtils.formatDate(load.emptyDate)}</p>
                                                        <p>Filed: {DateUtils.formatDate(load.paperworkDate)}{load.paidDate ?
                                                            <span style={{color: "#a7a7a7"}}>(Paid: {DateUtils.formatDate(load.paidDate)})</span> : ""}</p>
                                                        <p className='subtitle'>See Detail</p>
                                                    </div>
                                                </IonItem>
                                            </Link> : <div className='load-history'>
                                                <IonItem color="light" lines="none">
                                                    <div className='item-container mx-0 my-1 w-100'>
                                                        <h4 className='title boldest' style={{color: "#99cc00"}}>{load.orderNumber}</h4>
                                                        <p>Complete: {DateUtils.formatDate(load.emptyDate)}</p>
                                                        <br/>
                                                        <br/>
                                                        <Link to={"/loadhistory/" + load.orderNumber} style={{textDecoration: "none"}}><p className='subtitle'>See Detail</p></Link>
                                                    </div>
                                                </IonItem>
                                            </div>
                                        }</div>
                                    })
                                }
                            </div>
                        </IonItem>
                    )}
                    {!allLoaded && <IonItem lines="none" color="light">
                        <div className='item-container'>
                            <button onClick={() => loadMore(24)} className="western-button submit">LOAD MORE</button>
                        </div>
                    </IonItem>}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default LoadHistory;
