import React from "react";
import '../theme/sidenav.css';
import {SidenavState, sidnavStore} from "../api/SidenavStore";
import homeIcon from "../imgs/home-nav.png"
import coin from "../imgs/coin.png"
import newsIcon from "../imgs/news.png"
import distance from "../imgs/distance.png"
import trailer from "../imgs/trailer icon.png"
import right from "../imgs/right-arrow.png"
import {Link} from "react-router-dom";
import westernIcon from "./assets/Western-Logo-WHITE.png"
import { IonItem } from "@ionic/react";

const Sidenav: React.FC = () => {

    const {open, close}: { open: boolean, close: () => void } = sidnavStore((state: SidenavState) => ({open: state.open, close: state.close}));

    const links = [
        {
            name: 'Home', link: '/home', icon: homeIcon, active: false, children: []
        },
        {
            name: 'Load', link: '/load', icon: trailer, active: false, children: [
                {name: 'Current Load', link: '/currentload', active: false},
                {name: 'Load History', link: '/loadhistory', active: false},
                {name: 'Preplanned', link: '/preplanned', active: false}
            ]
        },
        {
            name: 'Trip', link: '/trip', icon: distance, active: false, children: [
                {name: 'Available Trailers', link: '/trailertracking', active: false},
                {name: 'Tarp Pay', link: '/photoupload', active: false},
                {name: 'Cab Extender Photos', link: '/cabinphoto', active: false},
                {name: 'Passenger Authorization', link: '/passenger', active: false},
                {name: 'Vehicle Inspection', link: '/leasepurchase', active: false},
            ]
        },
        {
            name: 'Pay', link: '/money', icon: coin, active: false, children: [
                {name: 'Payroll History', link: '/paychecks', active: false},
                {name: 'Driver Referral', link: '/referral', active: false},
                {name: 'Personal Advance', link: '/personaladvance', active: false},
                {name: 'Contracts', link: '/contracts', active: false},
                {name: 'Payroll F.A.Q.', link: '/payrollfaq', active: false}
            ]
        },
        {
            name: 'Docs', link: '/docs', icon: newsIcon, active: false, children: [
                {name: 'Safety', link: '/safety', active: false},
                {name: 'Safety Score', link: '/safetyscore', active: false},
                {name: 'Maintenance', link: '/maintenancevids', active: false},
                {name: 'Benefits', link: '/benefits', active: false},
                {name: 'Registrations', link: '/registrations', active: false},
                {name: 'Permits', link: '/permits', active: false},
                {name: 'Accident Information', link: '/accidentinfo', active: false}
            ]
        }
    ];

    links.forEach(parent => {
        const currentPage = window.location.href.toLowerCase();
        if (currentPage.match(parent.link)) {
            parent.active = true;
        }
        const activeChild = parent.children.find(child => currentPage.match(child.link));
        if (activeChild) {
            parent.active = true;
            activeChild.active = true;
        }
    });

    return (
        <div className={`sidenav ${open ? 'sidenav-open' : ''}`} onClick={() => close()}>
            <div className="sidenav-content">
                <div style={{textAlign: "center", borderBottom: "1px #3b3b3b solid", marginBottom: "10px"}}>
                    <img src={"./assets/Western-Logo-WHITE.png"} style={{height: "50px", marginBottom: "10px"}} alt="western" />  
                </div>
                {links.map(parent => <div key={parent.link} style={{borderBottom: "1px #3b3b3b solid", marginBottom: "10px"}}>
                    <Link to={parent.link} style={{ textDecoration: "none"}}>
                        <div className={`sidenav-link ${parent.active ? 'active' : ''}`}>
                            <img className="sidenav-icon" src={parent.icon} alt={parent.name}/>
                            <div>{parent.name}</div>
                            <img src={right} style={{height: "12px", opacity: '50%'}} alt="arrow right"/>
                        </div>
                    </Link>
                    {parent.children.map(it => <div key={it.link}>
                        <Link to={it.link} style={{ textDecoration: "none"}}>
                            <div className={`sidenav-link sidenav-sublink ${it.active ? 'active' : ''}`}>
                                <div>{it.name}</div>
                                <img src={right} style={{height: "10px", opacity: '50%'}} alt="arrow right"/>
                            </div>
                        </Link>
                    </div>)}
                </div>)}
            </div>
        </div>
    )
}
export default Sidenav;
