import React, { useRef, useState, useEffect } from 'react'
import { IonButton, IonContent, IonHeader, IonIcon, IonItem, IonPage, IonSlide, IonSlides, IonToolbar} from '@ionic/react'
import SafetyBonusSlide from './SafetyBonusSlides'
import appPhoneIcon from '../imgs/phone.gif'
import load1Img from '../imgs/load1.png'
import load2Img from '../imgs/load2.png'
import load3Img from '../imgs/load3.png'
import { informationCircleOutline, warning, warningOutline } from 'ionicons/icons'
import pretripIcon from "../imgs/pretrip.png"
import passengerIcon from "../imgs/add-passenger.png"
import searchIcon from "../imgs/search.png"
import searchNavIcon from "../imgs/search-nav.png"
import searchingGif from "../imgs/search.gif"
import check from "../imgs/check.png"
import close from "../imgs/close.png"
import { config } from '../Constants'
import {Link, useHistory} from "react-router-dom";
import { ApiService } from '../api/ApiService'
import { userStore, UserState } from '../api/AuthService'


const SafetyBonusDetails = () => {

    const history = useHistory();
    const [cpm, setCPM] = useState("05")
    const {division} = userStore((state: UserState) => ({division: state.driver?.division}));
    const [has6Months, setHas6Months] = useState<boolean>(true);
    const [has6KMiles, setHas6KMiles] = useState<boolean>(true);
    const [noAccidents, setNoAccidents] = useState<boolean>(false);
    const [motiveScore90, setMotiveScore90] = useState<boolean>(true);
    const [watchedVideos, setWatchVideos] = useState<boolean>(true);
    const [index, setIndex] = useState(0)

    const mySlides = useRef<HTMLIonSlidesElement>(null)
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      loop: true
      // autoplay: 5000
    }

    useEffect(() => {
        ApiService.sendEventCommand("Viewed Guide", "eventCheck", "User has viewed the Mobile Experience Learning Center");
        checkDivision();
      }, []);

    const checkDivision = () => {
        if (division === "VNASH" || division === "NER" || division === "VCT" || division === "VVA" || division === "100") {
            setCPM("05");
        } else if (division === "FNASH" || division === "FNR" || division === "500") {
            setCPM("07");
        }
    }

    function checkEligibility(data:any) {
        let tempData: any = data;
        // if(tempData.) setHas6KMonths()
        // if(tempData.) setHas6KMiles()
        // if(tempData.) setNoAccidents()
        // if(tempData.) setMotiveScore90()
        // if(tempData.) setWatchVideos()
    }

    const handleSlideChange = (slide: number) => { let slides = document.getElementById("slides") as HTMLIonSlidesElement; slides.slideTo(slide)}

    function homePageContent () {
        return <div style={{textAlign: "center"}}>
                <p><span style={{fontWeight: "600", fontSize: "16px"}}>At Western Express, we appreciate our drivers!</span></p>
                <p><span style={{textAlign: "center"}}>When you make safety a priority, everyone wins. Not only does safe driving protect you and others on the road, but now it also pays! We’re excited to offer our Safety Bonus Program—giving you the opportunity to earn extra just for doing what you do best: driving safely.</span></p>
                <p><span style={{textAlign: "center"}}>Meet the requirements below, and you’ll receive an extra $0.{cpm} CPM for the month!</span></p>
                <p><span style={{textAlign: "center"}}>Safety Bonuses are paid on the 2nd Friday date after the last day of the month. You must be employed at schedule time of payment.</span></p>
                <div>
                    <div style={{display: "grid", gridTemplateColumns: "20% 80%", marginTop: "20px", marginBottom: "20px"}}>
                        <img style={has6Months ? {height: "20px", textAlign: "start"} : {height: "12px", textAlign: "start", marginLeft: "4px"}} src={has6Months ? check : close}/>
                        <div style={{fontSize: "12px", fontWeight: "600"}}>6 months with Western Express, Inc</div>
                    </div>
                    <div style={{display: "grid", gridTemplateColumns: "20% 80%", marginBottom: "20px"}}>
                        <img style={has6KMiles ? {height: "20px", textAlign: "start"} : {height: "12px", textAlign: "start", marginLeft: "4px"}} src={has6KMiles ? check : close}/>
                        <div style={{fontSize: "12px", fontWeight: "600"}}>6,000 miles for the month</div>
                    </div>
                    <div style={{display: "grid", gridTemplateColumns: "20% 80%", marginBottom: "20px"}}>
                        <img style={noAccidents ? {height: "20px", textAlign: "start"} : {height: "12px", textAlign: "start", marginLeft: "4px"}} src={noAccidents ? check : close}/>
                        <div style={{fontSize: "12px", fontWeight: "600"}}>No accidents**</div>
                    </div>
                    <div style={{display: "grid", gridTemplateColumns: "20% 80%", marginBottom: "20px"}}>
                        <img style={motiveScore90 ? {height: "20px", textAlign: "start"} : {height: "12px", textAlign: "start", marginLeft: "4px"}} src={motiveScore90 ? check : close}/>
                        <div style={{fontSize: "12px", fontWeight: "600"}}>Motive score of 90 or higher</div>
                    </div>
                    <div style={{display: "grid", gridTemplateColumns: "20% 80%", marginBottom: "20px"}}>
                        <img style={watchedVideos ? {height: "20px", textAlign: "start"} : {height: "12px", textAlign: "start", marginLeft: "4px"}} src={watchedVideos ? check : close}/>
                        <div style={{fontSize: "12px", fontWeight: "600"}}>Complete all monthly safety videos</div>
                    </div>
                </div>
                <div>
                    <p style={{opacity: "50%", fontSize: "12px"}}>**An accident will disqualify you from participating for a month it occurred in as well as the following 2 months.**</p>
                </div>
            </div>
    }

    const homePageOptions = {
        title: "What is the Safety Bonus?",
        content: homePageContent()
    }
    
     function resetPageContent () {
        return <div>

        </div>
    }

    const resetPageOptions = {
        title: "COUNTDOWN TO ELIGIBILITY",
        subtitle: "Safety Bonus: $0." + cpm + " cpm",
        content: resetPageContent()
    }
  
  
    const slides = [
        <SafetyBonusSlide
            options={homePageOptions}
            next={nextPage}
            previous={previousPage}
        />,
        // <SafetyBonusSlide
        //     options={resetPageOptions}
        //     next={nextPage}
        //     previous={previousPage}
        // />
    ]
      const getSlides = slides.map((slide,index) => {
        return <IonSlide key={index}>{slide}</IonSlide>
    })
    
    function nextPage () {
        let tmp = index
        if(tmp < slides.length) {
            tmp++
            setIndex(tmp)
        }
    }
    function previousPage () {
        let tmp = index
        if(tmp >= 1) {
            tmp--
            setIndex(tmp)
        }
    }
    return (
        <IonPage>
             <IonHeader>
          <IonToolbar color="primary">
            <IonButton slot="start" style={{padding: 0}}><p style={{fontFamily: "Montserrat, sans-seriff"}} onClick={() => history.push('/home')}>Back</p></IonButton>
          </IonToolbar>
        </IonHeader>
            <IonContent fullscreen color="light">
            <div style={{height: "100%", background: "white"}}>
                <IonSlides id="slides" options={slideOpts} ref={mySlides}>
                    {getSlides}
                </IonSlides>
            </div>
            </IonContent>
        </IonPage>
    )
}

export default SafetyBonusDetails
