import React, { useState }  from "react";

import homeIcon from "../imgs/home-nav.png"
import coin from "../imgs/coin.png"
import newsIcon from "../imgs/news.png"
import distance from "../imgs/distance.png"
import trailer from "../imgs/trailer icon.png"
import { body } from "ionicons/icons";
import {Link} from "react-router-dom";

type Props = {
    onSearch: (target: boolean) => void
}
function isSearching () {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const product = urlParams.get('search')
    if(product) {
      return true
    } else {
      return false
    }
}
const Nav = ({ onSearch}: Props) => {
    const currentPage = window.location.href.toLowerCase();

    return (
        <div className="item-container" id="navi">
            <div className="nav-items">
                <Link to="/Home" style={{ textDecoration: "none"}}>
                    <div>
                        <img src={homeIcon} alt="home"/>
                        <p style={{ color: currentPage.match('/home')? "#99cc00" : "#ffffff"}}>Home</p>
                    </div>
                </Link>
                <Link to="/Load" style={{ textDecoration: "none"}}>
                    <div>
                        <img src={trailer} alt="load"/>
                        <p style={{ color: currentPage.match('/load')? "#99cc00" : "#ffffff"}}>Load</p>
                    </div>
                </Link>
                <Link to="/Trip" style={{ textDecoration: "none"}}>
                    <div>
                        <img src={distance} alt="trip"/>
                        <p style={{ color: currentPage.match('/trip') ? "#99cc00" : "#ffffff"}}>Trip</p>
                    </div>
                </Link>
                <Link to="/Money" style={{ textDecoration: "none"}}>
                    <div>
                        <img src={coin} alt="pay"/>
                        <p style={{ color: currentPage.match('/money')? "#99cc00" : "#ffffff"}}>Pay</p>
                    </div>
                </Link>
                <Link to="/Docs" style={{ textDecoration: "none"}}>
                    <div>
                        <img src={newsIcon} alt="docs"/>
                        <p style={{ color: currentPage.match('/docs')? "#99cc00" : "#ffffff"}}>Docs</p>
                    </div>
                </Link>
            </div>

        </div>
    )
}

export default Nav
