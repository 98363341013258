import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonModal, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {Link, useParams} from "react-router-dom";
import YouTube from 'react-youtube';
import {ApiService} from "../api/ApiService";
import {PaycheckDetailItem} from "../api/model/PaycheckDetail";
import '../theme/paycheckDetail.css';
import {userStore} from "../api/AuthService";
import {DateUtils} from "../utils/DateUtils";
import moment from "moment/moment";
import {MileageDetail} from "../api/model/MileageDetail";

let mustWatch = false;
let watchedVid = false;

type CheckInfo = {
    earnings: PaycheckDetailItem[],
    deductions: PaycheckDetailItem[],
    taxes: PaycheckDetailItem[],
    reimbursements: PaycheckDetailItem[],
    net: any
}

const PaycheckDetail: React.FC = () => {

    // const {division} = userStore((state: UserState) => ({division: state.driver?.division}));
    const {checkDate} = useParams<any>();
    const [net, setNet] = useState<number>(0);
    const [earnings, setEarnings] = useState<PaycheckDetailItem[]>([]);
    const [deductions, setDeductions] = useState<PaycheckDetailItem[]>([]);
    const [taxes, setTaxes] = useState<PaycheckDetailItem[]>([]);
    const [is800, setIs800] = useState<boolean>(false)
    const [reimbursements, setReimbursements] = useState<PaycheckDetailItem[]>([]);
    const [mileageDetail, setMileageDetail] = useState<MileageDetail[]>([]);

    useEffect(() => {
        if (window.location.href.toString().indexOf("/CA") !== -1) {
            setIs800(true);
            ApiService.getMileageDetail("mileageDetail", {checkDate}).then(it => setMileageDetail(it));
        }
        ApiService.getPaycheckDetail("payCheckDetail", {checkDate}).then(it => {
            console.log(JSON.stringify(it));
            setNet(parseFloat(it.net));
            setEarnings(it.items.filter(item => item.itemType === 'earnings'));
            setDeductions(it.items.filter(item => item.itemType === 'deductions'));
            setTaxes(it.items.filter(item => item.itemType === 'taxes'));
            setReimbursements(it.items.filter(item => item.itemType === 'reimbursements' || item.itemType === 'other'));
        });
        ApiService.sendEventCommand("Check Paycheck", "eventCheck", "User viewed paycheck details");
    }, []);

    const parseNumber = (item: string) => {
        return item ? parseFloat(item) : 0;
    }

    const formatCurrency = (item: number) => {
        return (item || 0).toFixed(2);
    }

    const formatNegativeCurrency = (item: number) => {
        return item ? item.toFixed(2).replace('-', '') + '-' : '0.00';
    }

    const getCpm = (earnings: PaycheckDetailItem[]) => {
        const totalEarnings = earnings.reduce((total, it) => total + parseNumber(it.amount), 0);
        const miles = earnings.reduce((total, it) => total + it.miles, 0);
        const result = (totalEarnings / miles).toFixed(3);
        return result.startsWith('0') ? result.slice(1) : result;
    }

    const defaultTemplate = <div className="paycheck-detail-body pb-5" style={{fontSize: "10px"}}>
        <table>
            <tbody>
            <tr>
                <td colSpan={11} className="py-3 text-center">
                    <div>Western Express, Inc.</div>
                    <div>7135 Centennial Place</div>
                    <div>Nashville, TN 37209</div>
                </td>
            </tr>
            <tr>
                <td colSpan={11} className="py-3 text-center">
                    <div>Western Express Weekly Recap</div>
                    <div>***** THIS IS NOT A PAYSTUB *****</div>
                    <div>Your Paystub is available on Workday</div>
                    <div>https://wd5.myworkday.com/wday/authgwy/westernexp/login.htmld</div>
                </td>
            </tr>
            <tr>
                <td colSpan={2} className="vertical-align-top py-3">
                    Employee: {userStore.getState().driver?.name.split(' ')[0]}
                </td>
                <td colSpan={3} className="vertical-align-top py-3">
                    <div>{userStore.getState().driver?.name.split(' ')[1]}</div>
                    <div></div>
                    <div></div>
                </td>
                <td colSpan={4} className="vertical-align-top py-3 text-right">
                    <div>Pay Date: {DateUtils.formatDate(checkDate)}</div>
                    <div>Pay Period Start Date: {DateUtils.subtractDays(checkDate, 13)}</div>
                    <div>Pay Period End Date: {DateUtils.subtractDays(checkDate, 6)}</div>
                </td>
                <td colSpan={2} className="py-3"></td>
            </tr>
            <tr>
                <td className="vertical-align-bottom py-3">
                    <div>Empty Date/</div>
                    <div>Earning Date</div>
                </td>
                <td className="vertical-align-bottom py-3">Order</td>
                <td></td>
                <td className="vertical-align-bottom py-3">Description</td>
                <td></td>
                <td className="vertical-align-bottom py-3">
                    <div>Order In</div>
                    <div>Pay Period</div>
                </td>
                <td className="vertical-align-bottom py-3">
                    <div>Taxable</div>
                    <div>Income</div>
                </td>
                <td className="vertical-align-bottom text-center py-3">
                    <div>Non Taxable</div>
                    <div>Income</div>
                    <div>(Per Diem)</div>
                </td>
                <td className="vertical-align-bottom text-center py-3">
                    <div>Taxable Plus</div>
                    <div>Non Taxable</div>
                    <div>Income</div>
                </td>
                <td className="vertical-align-bottom py-3">Miles</td>
                <td className="vertical-align-bottom py-3">CPM</td>
            </tr>
            {earnings.map((it, index) => <tr key={index}>
                <td></td>
                <td>{it.orderNumber ? it.orderNumber : ''}</td>
                <td className="px-1">{it.orderNumber ? '0' + it.dispatchNumber : ''}</td>
                <td colSpan={2}>{it.description}</td>
                <td className="text-center text-danger"></td>
                <td className="text-right pe-5">{formatCurrency(parseNumber(it.amount) - parseNumber(it.perDiemAmount))}</td>
                <td className="text-right pe-5">{it.orderNumber ? formatCurrency(parseNumber(it.perDiemAmount)) : ''}</td>
                <td className="text-right pe-5">{formatCurrency(parseNumber(it.amount))}</td>
                <td>{it.orderNumber ? it.miles : ''}</td>
                <td>{it.orderNumber && it.costPerMile ? it.costPerMile.replace('0.', '.') : ''}</td>
            </tr>)}
            <tr>
                <td colSpan={2} className="py-3"></td>
                <td colSpan={2} className="py-3"><strong>EARNINGS</strong></td>
                <td className="py-3"><strong>Total</strong></td>
                <td></td>
                <td className="py-3 text-right pe-5">
                    <strong>{formatCurrency(earnings.reduce((total, it) => total + (parseNumber(it.amount) - parseNumber(it.perDiemAmount)), 0))}</strong>
                </td>
                <td className="py-3 text-right pe-5">
                    <strong>{formatCurrency(earnings.reduce((total, it) => total + parseNumber(it.perDiemAmount), 0))}</strong>
                </td>
                <td className="py-3 text-right pe-5">
                    <strong>{formatCurrency(earnings.reduce((total, it) => total + parseNumber(it.amount), 0))}</strong>
                </td>
                <td className="py-3">
                    <strong>{formatCurrency(earnings.reduce((total, it) => total + it.miles, 0))}</strong>
                </td>
                <td></td>
            </tr>
            <tr>
                <td colSpan={11} className="pt-3"></td>
            </tr>
            {taxes.map((it, index) => <tr key={index}>
                <td colSpan={3}></td>
                <td colSpan={3}>{it.description}</td>
                <td className="text-right pe-5">{formatNegativeCurrency(parseNumber(it.amount))}</td>
            </tr>)}
            <tr>
                <td colSpan={2} className="py-3"></td>
                <td colSpan={2} className="py-3"><strong>TAX</strong></td>
                <td className="py-3"><strong>Total</strong></td>
                <td></td>
                <td className="py-3 text-right pe-5">
                    <strong>{formatNegativeCurrency(taxes.reduce((total, it) => total + parseNumber(it.amount), 0))}</strong>
                </td>
                <td colSpan={4}></td>
            </tr>
            {deductions.map((it, index) => <tr key={index}>
                <td colSpan={3}></td>
                <td colSpan={3}>{it.description}</td>
                <td className="text-right pe-5">{formatNegativeCurrency(parseNumber(it.amount))}</td>
            </tr>)}
            <tr>
                <td colSpan={2} className="py-3"></td>
                <td colSpan={2} className="py-3"><strong>Deduction</strong></td>
                <td className="py-3"><strong>Total</strong></td>
                <td></td>
                <td className="py-3 text-right pe-5">
                    <strong>{formatNegativeCurrency(deductions.reduce((total, it) => total + parseNumber(it.amount), 0))}</strong>
                </td>
                <td colSpan={4}></td>
            </tr>
            {reimbursements.map((it, index) => <tr key={index}>
                <td colSpan={3}></td>
                <td colSpan={3}>{it.description}</td>
                <td className="text-right pe-5">{formatCurrency(parseNumber(it.amount))}</td>
            </tr>)}
            {!!reimbursements?.length &&
                <tr>
                    <td colSpan={2} className="py-3"></td>
                    <td colSpan={2} className="py-3"><strong>Reimbursements</strong></td>
                    <td className="py-3"><strong>Total</strong></td>
                    <td></td>
                    <td className="py-3 text-right pe-5">
                        <strong>{formatCurrency(reimbursements.reduce((total, it) => total + parseNumber(it.amount), 0))}</strong>
                    </td>
                    <td colSpan={4}></td>
                </tr>
            }
            <tr>
                <td colSpan={7} className="py-3"></td>
                <td colSpan={1} className="py-3"><strong>Net pay Amount</strong></td>
                <td colSpan={2} className="text-center"><strong>{formatCurrency(net)}</strong></td>
                <td></td>
            </tr>
            <tr>
                <td colSpan={6} className=""></td>
                <td colSpan={3} className="">
                    <div>
                        <strong>----------> Total earning divided by miles = total CPM &lt;----------</strong>
                    </div>
                    <div className="d-flex justify-content-center gap-4">
                        <strong>{formatCurrency(earnings.reduce((total, it) => total + parseNumber(it.amount), 0))}</strong>
                        <strong>/</strong>
                        <strong>{formatCurrency(earnings.reduce((total, it) => total + it.miles, 0))}</strong>
                        <strong>=</strong>
                        <strong>{getCpm(earnings)}</strong>
                    </div>
                </td>
                <td></td>
            </tr>
            </tbody>
        </table>
    </div>;
    const caTemplate = <div className="paycheck-detail-body pb-5" style={{fontSize: "10px"}}>
        <table>
            <tbody>
            <tr>
                <td colSpan={11} className="py-3 text-center">
                    <div><strong>***** THIS IS NOT A PAYSTUB *****</strong></div>
                    <div><strong>Your Paystub is available on Workday</strong></div>
                    <div className="text-decoration-underline">https://wd5.myworkday.com/wday/authgwy/westernexp/login.htmld</div>
                </td>
            </tr>
            <tr>
                <td></td>
                <td colSpan={10}>{userStore.getState().driver?.driverCode}</td>
            </tr>
            <tr>
                <td className="pb-4">{moment().format(DateUtils.US_DATE_FORMAT)}</td>
                <td className="pb-4" colSpan={10}>{userStore.getState().driver?.name}</td>
            </tr>

            <tr>
                <td colSpan={2}><strong className="ps-4">Base Pay <span className="ps-5">Rate</span></strong></td>
                <td colSpan={3} className="text-center">{formatCurrency(mileageDetail[0]?.rph)}</td>
                <td>Hours</td>
                <td className="text-center">{formatCurrency(mileageDetail[0]?.payQuantity)}</td>
                <td colSpan={3} className="text-center">{formatCurrency(mileageDetail[0]?.hrAmt)}</td>
                <td colSpan={1}></td>
            </tr>
            <tr>
                <td colSpan={2}><strong className="ps-4">Production Bonus</strong></td>
                <td colSpan={3} className=" pe-4">Non-Taxable(CA PerDiem)</td>
                <td colSpan={2}></td>
                <td colSpan={3} className="text-center">{formatCurrency(mileageDetail[0]?.dedBenfAmount)}</td>
                <td colSpan={1}></td>
            </tr>
            <tr>
                <td colSpan={2}></td>
                <td colSpan={3} className=" pe-4">Taxable</td>
                <td colSpan={2}></td>
                <td colSpan={3} className="text-center">{formatCurrency(mileageDetail[0]?.other)}</td>
                <td colSpan={1}></td>
            </tr>
            <tr>
                <td colSpan={5}></td>
                <td colSpan={2} className="text-end pe-4"><strong>TOTAL (GROSS)</strong></td>
                <td colSpan={3} className="text-center">{formatCurrency(mileageDetail[0]?.totalGross)}</td>
                <td colSpan={1}></td>
            </tr>

            <tr>
                <td colSpan={1} className="py-5 pb-3 ps-3"><strong>Empty date</strong></td>
                <td colSpan={1} className="pt-5 pb-3 "><strong>Order</strong></td>
                <td colSpan={1} className="pt-5 pb-3 text-center"><strong>Disp</strong></td>
                <td colSpan={2} className="pt-5 pb-3 text-center"><strong>Total Order Miles</strong></td>
                <td colSpan={2} className="pt-5 pb-3 text-center"><strong>Miles Last Week</strong></td>
                <td colSpan={2} className="pt-5 pb-3 text-center"><strong>Miles This Week</strong></td>
                <td colSpan={2} className="pt-5 pb-3 text-center"><strong>Remaining Miles</strong></td>
            </tr>
            {mileageDetail.map((it, index) => <tr key={index}>
                <td colSpan={1} className="ps-3" style={{paddingRight: "10px"}}>{it.emptyDate ? DateUtils.formatDate(it.emptyDate) : 'In Transit*'}</td>
                <td colSpan={1} className="">{it.orderNum}</td>
                <td colSpan={1} className="px-1 text-center">{it.dispatchNum || ''}</td>
                <td colSpan={2} className="text-center">{it.miles}</td>
                <td colSpan={2} className="text-center">{it.priorMiles}</td>
                <td colSpan={2} className="text-center">{it.thisWeek}</td>
                <td colSpan={2} className="text-center">{it.remaining}</td>
            </tr>)}
            <tr>
                <td colSpan={3} className="pt-2"></td>
                <td colSpan={2} className="pt-2">
                    <div className="border-top border-1 border-dark text-center p-1 mx-4">
                        {mileageDetail.reduce((total, it) => total + it.miles, 0)}
                    </div>
                </td>
                <td colSpan={2} className="pt-2">
                    <div className="border-top border-1 border-dark text-center p-1 mx-4">
                        {mileageDetail.reduce((total, it) => total + it.priorMiles, 0)}
                    </div>
                </td>
                <td colSpan={2} className="pt-2">
                    <div className="border-top border-1 border-dark text-center p-1 mx-4">
                        {mileageDetail.reduce((total, it) => total + it.thisWeek, 0)}
                    </div>
                </td>
                <td colSpan={2} className="pt-2">
                    <div className="border-top border-1 border-dark text-center p-1 mx-4">
                        {mileageDetail.reduce((total, it) => total + it.remaining, 0)}
                    </div>
                </td>
            </tr>
            <tr>
                <td colSpan={11} className="text-center pt-3">* "In Transit" are loads whose pick-up and delivery dates span more than one pay period.</td>
            </tr>
            <tr>
                <td colSpan={3}></td>
                <td colSpan={8} className="pt-3">Rate-Per-Mile Equivalency: {getCpm(earnings)} /mile</td>
            </tr>
            <tr>
                <td colSpan={11} className="text-center">It is common in the transportation industry for drivers to be paid on a rate-per-mile basis. However, due to certain regulatory and statutory requirements, you are paid on an hourly basis with bonus eligibility based on production.</td>
            </tr>
            <tr>
                <td colSpan={11} className="text-center pt-3">As a convenience, and without disrupting or altering the hourly pay basis of your earnings, Western has provided a calculation on this document which represents a rate-per-mile equivalent to allow you to visualize your earnings in the method most commonly used across the industry.</td>
            </tr>
            <tr>
                <td colSpan={11} className="text-center pt-3">More information and details regarding your earnings may be found on your paystub, accessible at any time via Workday. Please contact workday help for assistance or questions regarding the Workday system. <span className="text-decoration-underline">workday@westernexp.com</span> or phone <span className="text-decoration-underline">(615) 846-8181</span></td>
            </tr>
            </tbody>
        </table>
    </div>;

    return <IonPage>
        <IonHeader>
            <IonToolbar color="primary">
                <IonButtons slot="start">
                    <IonBackButton defaultHref="/paychecks"/>
                </IonButtons>
                <IonTitle>Paycheck Detail</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent scrollX={true} style={{"--background": "white"}}>
            {is800 ? caTemplate : defaultTemplate}
        </IonContent>
    </IonPage>
}

const PaycheckDetail2: React.FC = () => {

    const [showModal, setShowModal] = useState(false);
    const [checkInfo, setCheckInfo] = useState<CheckInfo>();

    let {checkno, loadNumber} = useParams<any>();

    useEffect(() => {
        if (!checkInfo) {
            ApiService.getPaycheckDetail("payCheckDetail", {checkDate: checkno}).then(it => {
                setCheckInfo({
                    net: it.net,
                    earnings: it.items.filter(item => item.itemType === 'earnings'),
                    deductions: it.items.filter(item => item.itemType === 'deductions'),
                    taxes: it.items.filter(item => item.itemType === 'taxes'),
                    reimbursements: it.items.filter(item => item.itemType === 'reimbursements' || item.itemType === 'other')
                });
            });
        }

        // ---------------------- video -------------------------------
        let last_watched = localStorage.getItem('lastwatchedcheck')
        if (last_watched) {
            // If true: the video has been watched in the last 24 hours
            if ((new Date().getTime() - new Date(last_watched).getTime()) < 86400000) {
                mustWatch = false
                return
            }
        }
        if (watchedVid) {
            let string = new Date()
            localStorage.setItem('lastwatchedcheck', string.toString())
        } else {
            fetch('https://jsonplaceholder.typicode.com/todos/4')
                .then(response => response.json())
                .then(json => {
                    console.log(json.completed);
                    mustWatch = json.completed
                })
                .then(() => setShowModal(mustWatch))
                .then(() => console.log(showModal))
        }
        // console.log("useEffect", mustWatch)
        // setShowModal(mustWatch)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedVid]);

    const totalAmounts = (items: any, type: string) => {
        let total = 0
        if (items) {
            items.forEach((item: { amount: string }) => {
                total = total + parseInt(item.amount)
            })
            return total.toString().replace("-", "-$")
        } else {
            return 'Not seeing info on ' + type + " ? Contact driver payroll."
        }
    }

    function callTreeWalker() {
        let walker = document.createTreeWalker(document.body, NodeFilter.SHOW_TEXT)
        let result = []
        while (walker.nextNode()) {
            result.push(walker.currentNode.textContent)
        }
        console.log(result)
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/paychecks"/>
                    </IonButtons>
                    <IonTitle>Paycheck Detail</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="light">
                <IonModal isOpen={showModal} id="transparent">
                    <div className="modal-div" onClick={() => {
                        setShowModal(false);
                        console.log(loadNumber)
                        if (loadNumber) {
                            document.getElementById(loadNumber)?.scrollIntoView()
                        }
                    }}>
                        <div className="item-container" id="modal">
                            <p className='title'>Please watch this brief video before continuing</p>
                            <YouTube
                                videoId="X3p22hhnvvc"
                                onEnd={function () {
                                    mustWatch = false;
                                    watchedVid = true;
                                    setShowModal(false)
                                }}
                            />

                        </div>
                    </div>
                </IonModal>
                {/* <IonModal isOpen={showModal} cssClass='my-custom-class'>
          <IonContent>
          <IonHeader >
            <IonToolbar color="primary">
              <IonButtons slot="start">
                <IonButton onClick={ function() { setShowModal(false);}}>
                  <IonIcon slot="icon-only" icon={close} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
            
          </IonContent>
        </IonModal> */}
                <IonItem lines="none" color="light">
                    <div className='item-container'>
                        <div className="payment-line-item" style={{backgroundColor: "white", padding: "16px", borderRadius: "10px"}}>
                            <strong style={{fontSize: "large"}}>{checkno}</strong>
                            <strong style={{fontSize: "large"}}>Net Amount ${parseFloat(checkInfo?.net).toFixed(2)}</strong>
                        </div>
                    </div>
                </IonItem>

                <br/>
                <div className='main'>
                    <div>
                        <IonItem lines="none" color="light">
                            <div className='letter-icon' style={{backgroundColor: "#9975FF"}}><p>$</p></div>
                            <strong>Earnings</strong>
                        </IonItem>
                        {checkInfo?.earnings.map(function (load, idx) {
                            let item = load.orderNumber !== 0 ?
                                <Link to={"/loadhistory/" + load.orderNumber} key={load.orderNumber + idx} className="load-history" id={`${load.orderNumber}`}>
                                    <IonItem color="light" lines="none">
                                        <div className='item-container'>
                                            <h4 className='title boldest' style={{color: "#99cc00"}}>${parseFloat(load.amount).toFixed(2)}</h4>
                                            <p className='subtitle'>Load #: {load.orderNumber} Dispatch: {load.dispatchNumber}</p>
                                            <p>{load.description}</p>
                                            <strong>Route: {load.miles} mi</strong>
                                            <p className='subtitle'>Empty: {load.emptyMiles} Loaded: {load.miles}</p>
                                            <p>Cost Per Mile: ${load.costPerMile}</p>
                                            <p>Per Diem: ${load.perDiemAmount}</p>
                                            <p>Amount: ${parseFloat(load.amount).toFixed(2)}</p>
                                            <p className='subtitle'>See Detail</p>
                                        </div>
                                    </IonItem>
                                </Link> : <IonItem key={load.description} lines="none" className='load-history' color="light">
                                    <div className='item-container'>
                                        <h4 className='title boldest' style={{color: "#99cc00"}}>${parseFloat(load.amount).toFixed(2)}</h4>
                                        <p>{load.description}</p>
                                    </div>
                                </IonItem>
                            return item
                        })}
                    </div>

                    <div>
                        <IonItem lines="none" color="white">
                            <div className='letter-icon' style={{backgroundColor: "#9975FF"}}><p>T</p></div>
                            <strong>Taxes</strong>
                        </IonItem>

                        <IonItem lines="none" color="white">
                            <div className='item-container'>
                                <div style={{borderBottom: "2px solid #a7a7a7"}}>
                                    {checkInfo?.taxes.map(function (item, idx) {
                                        return <div key={item.description} className="payment-line-item"><p>{item.description}:</p><p>{item.amount.replace("-", "-$")}</p></div>
                                    })}
                                </div>
                                <br/>
                                <div className="payment-line-item"><strong style={{fontSize: "large"}}>Total:</strong><strong
                                    style={{fontSize: "large"}}>{totalAmounts(checkInfo?.taxes, 'tax')}</strong></div>
                            </div>
                        </IonItem>
                        <IonItem lines="none" color="white">
                            <div className='letter-icon' style={{backgroundColor: "#9975FF"}}><p>{'<'}</p></div>
                            <strong>Reimbursements</strong>
                        </IonItem>

                        <IonItem lines="none" color="white">
                            <div className='item-container'>
                                <div style={{borderBottom: "2px solid #a7a7a7"}}>
                                    {checkInfo?.reimbursements.map(function (item, idx) {
                                        return <div key={item.description} className="payment-line-item"><p>{item.description}:</p><p>{item.amount.replace("-", "-$")}</p></div>
                                    })}
                                </div>
                                <br/>
                                <div className="payment-line-item"><strong style={{fontSize: "large"}}>Total:</strong><strong
                                    style={{fontSize: "large"}}>{totalAmounts(checkInfo?.reimbursements, 'reimbursement')}</strong></div>
                            </div>
                        </IonItem>

                        <IonItem lines="none" color="white">
                            <div className='letter-icon' style={{backgroundColor: "#9975FF"}}><p>{'-'}</p></div>
                            <strong>Deductions</strong>
                        </IonItem>

                        <IonItem lines="none" color="white">
                            <div className='item-container'>
                                <div style={{borderBottom: "2px solid #a7a7a7"}}>
                                    {checkInfo?.deductions.map(function (item, idx) {
                                        return <div key={item.description} className="payment-line-item"><p>{item.description}:</p><p>{item.amount.replace("-", "-$")}</p></div>
                                    })}
                                </div>
                                <br/>
                                <div className="payment-line-item"><strong style={{fontSize: "large"}}>Total:</strong><strong
                                    style={{fontSize: "large"}}>{totalAmounts(checkInfo?.deductions, 'reimbursement')}</strong></div>
                            </div>
                        </IonItem>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default PaycheckDetail;
