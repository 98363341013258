import React, {useEffect, useState} from "react"
import {IonContent, IonFooter, IonHeader, IonItem, IonModal, IonPage, IonToast, IonToolbar, Gesture, createGesture} from "@ionic/react"
import Search from "./Search"
import Contacts from "./Contacts"
import Load from "./Load"
import ProfileInfo from "./ProfileInfo"
import Game from "./Game"
import Trip from "./Trip"
import Money from "./Money"
import Docs from "./Docs"
import Nav from "./Nav"
import {docsSearch, loadSearch, moneySearch, paychecks, statsSearch, tripSearch} from "../Util"
import Onboard from "./Onboard"
import Toolbar from "./Toolbar"
import chevronRight from "../imgs/chevron-right.png"
import {Link, useHistory} from "react-router-dom";
import {ApiService} from "../api/ApiService";
import {UserUtils} from "../utils/UserUtils";
import { checkOS } from "../components/OSCheck"
import { userStore, UserState } from "../api/AuthService"
import { DriverProfile } from "../api/model/DriverProfile"
import { PushNotificationsService } from "../api/PushNotificationsService"
import { userInfo } from "os"
import { sidnavStore, SidenavState } from "../api/SidenavStore"

type Load = {
  orderNumber: number,
  paymentStatus: string,
  statusDate: string,
  paperworkDate: string,
  paidDate: string, 
  dispatchNumber: string,
  milesLoaded: string,
  milesEmpty: string,
  milesTotal: string,
  emptyDate: string
}


const Home: React.FC = () => {
    const history = useHistory();

    const tabs = UserUtils.isTrainee() ? ['Load', 'Trip', 'Pay', 'Docs'] : ['Stats', 'Load', 'Trip', 'Pay', 'Docs'];

    const [searchText, setSearchText] = useState("")
    const [isSearching, setIsSearching] = useState(false)
    const [showProfile, setShowProfile] = useState(true)
    const [featured, setFeatured] = useState<JSX.Element[]>([])
    const [featuredHeader, setFeaturedHeader] = useState("")
    const [quickSelect, setQuickSelect] = useState(tabs[0]);
    const [isLookingForOrder, setIsLookingForOrder] = useState(false)
    const [noOrderFound, setNoOrderFound] = useState(false)
    const [orderPaidDate, setOrderPaidDate] = useState("")

    const {userInfo}: { userInfo: DriverProfile } = userStore((state: UserState) => ({userInfo: state.driver}));
    const {open, close}: { open: boolean, close: () => void } = sidnavStore((state: SidenavState) => ({open: state.open, close: state.close}));
    const {toggleSidenav}: { toggleSidenav: () => void } = sidnavStore((state: SidenavState) => ({toggleSidenav: state.toggle}));
    
    const ref = React.useRef<HTMLInputElement>(null)

    useEffect(() => {
        gestureInit();
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const product = urlParams.get('search')
        if(product) {
          setIsSearching(true)
        } else {
          setIsSearching(false)
        }
        console.log("os", checkOS());
        if (localStorage.getItem("pushNotificationPermission") === null) {
            console.log("register push", localStorage.getItem("pushNotificationPermission"));
            PushNotificationsService.registerAfterAppLogin(userStore.getState().user!.id)
        }
    }, [])
  

    function handleSearch (value: string) {
        if(value) {
            setSearchText(value)
            matchKeywords(value)
        } else {
            setFeaturedHeader('')
            setQuickSelect("stats")
            setShowProfile(true)
        }
    }

    function handleMode (value: boolean) {
        setIsSearching(value)
    }

    const gestureInit = () => {
        const card = ref.current
        if (card) {
            const gesture = createGesture({
                el: card,
                gestureName: 'swipeMenu',
                onMove: (detail) => {
                    console.log("moving");
                },
                onEnd: (detail) => {
                    console.log("move ended");
                    toggleSidenav();
                }                 
            })
            gesture.enable();
        }
    }

    function searchByOrderNumber(order: string) {
        setIsLookingForOrder(true);
        ApiService.getLoadHistory("home", {start: 0, length: 100}).then(async it => {
            if (!it.length) {
                setNoOrderFound(true);
                return;
            }
            const lastPaid = await ApiService.getPaycheckHistory("home", {start: 0, length: 1}).then(it => it[0]?.date);
            const load = it.find(loadItem => loadItem.orderNumber == parseInt(order));
            if (!load) {
                console.log("failed");
                setNoOrderFound(true);
                return;
            }
            if (load.paidDate <= lastPaid) {
                history.push(`/paychecks/${load.paidDate}/${load.orderNumber}`)
            } else if (load.paidDate) {
                setOrderPaidDate(load.paidDate);
            }
            setNoOrderFound(false);
        }).finally(() => setIsLookingForOrder(false));
    }

    function matchKeywords (key: string) {
       
        let keywords = {
            load: loadSearch,
            trip: tripSearch,
            money: moneySearch,
            docs: docsSearch,
            stats: statsSearch,
            paychecks: paychecks
        }
        if(key.match(paychecks)) {
            setShowProfile(false)
            setFeaturedHeader("My Pay")
            handleQuickSelect(tabs.indexOf('Pay'))
            setComponent(<Money />)

            // Search Load History
          
            searchByOrderNumber(key)
        }
        keywords.load.forEach(word => {
            if (key.toLocaleUpperCase() === word) {
                setShowProfile(false)
                setFeaturedHeader("Load")
                handleQuickSelect(tabs.indexOf('Load'))
                setComponent(<Load />)
            }
        })
        keywords.trip.forEach(word => {
            if (key.toLocaleUpperCase() === word) {
                setShowProfile(false)
                setFeaturedHeader("My Trip")
                handleQuickSelect(tabs.indexOf('Trip'))
                setComponent(<Trip />)
            }
        })
        keywords.money.forEach(word => {
            if (key.toLocaleUpperCase() === word) {
                setShowProfile(false)
                setFeaturedHeader("My Pay")
                handleQuickSelect(tabs.indexOf('Pay'))
                setComponent(<Money />)
            }
        })
        keywords.docs.forEach(word => {
            if (key.toLocaleUpperCase() === word) {
                setShowProfile(false)
                setFeaturedHeader("My Docs")
                handleQuickSelect(tabs.indexOf('Docs'))
                setComponent(<Docs />)
            }
        })
        keywords.stats.forEach(word => {
            if (key.toLocaleUpperCase() === word) {
                setShowProfile(false)
                setFeaturedHeader("Standings")
                handleQuickSelect(tabs.indexOf('Stats'))
                setComponent(<Game />)
            }
        })
    }
   
    function setComponent (component: JSX.Element) {
        let temp = new Set(featured)
        temp.add(component)

        let arr = Array.from(temp)
        temp = new Set(arr)
        setFeatured([...Array.from(temp)])    
    }


    function getComponent () {
        return (
            <div>
                <IonItem color="light" lines="none"><div className="item-container"><h3><strong style={{ margin: '0 8px'}}>Suggested: {featuredHeader}</strong></h3></div></IonItem>
                <IonItem color="light" lines="none">{featured[featured.length-1]}</IonItem>
            </div>
        )
    }

    function handleQuickSelect (tabIndex: number) {
        const bar = document.getElementById('scroll-bar');
        if (bar) {
            bar.style.marginLeft = (tabIndex * (100 / tabs.length)) + '%';
        }
        setQuickSelect(tabs[tabIndex]);
    }
    function getQuickSelect () {
        switch (quickSelect) {
            case 'Stats':
                return <Game/>
            case 'Load':
                return <IonItem lines="none" color="light"><Load/></IonItem>
            case 'Trip':
                return <IonItem lines="none" color="light"><Trip/></IonItem>
            case 'Pay':
                return <IonItem lines="none" color="light"><Money/></IonItem>
            case 'Docs':
                return <IonItem lines="none" color="light"><Docs/></IonItem>
            default:
                break
         }
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <Toolbar/>
                    {isSearching ? <Search onChange={handleSearch} featured={featuredHeader}/> : null}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="light">
                <IonModal isOpen={isLookingForOrder} id="transparent">
                <div className="modal-div">
                    <div id="modal">
                        <div className='loading-div'>
                          <div className="snippet" data-title=".dot-flashing" style={{ margin: "4px 16px"}}>
                            <div className="stage">
                              <div className="dot-flashing"></div>
                            </div> 
                          </div>
                        </div>
                    </div>
                </div>
            </IonModal>
              <IonToast
            isOpen={noOrderFound}
            color="white"
            message={`Hey there! We couldn't match this to any paychecks. ${orderPaidDate ? 'However, there is a record of payment for ' + orderPaidDate + '. Please try again at a later time.' : ''}`}
            duration={15000}
            cssClass="search-alert"
            onDidDismiss={()=>setNoOrderFound(false)}
            ></IonToast>
                    <div ref={ref} className="main" onClick={() => close()}>
                        <div>
                            <div>
                                <ProfileInfo/>
                                <Game/>
                            </div>
                        </div>
                        <div>
                            {!UserUtils.isDriver() ? <Onboard/>:  null}
                            <Contacts/>
                            <IonItem lines="none" color="light">
                                <div className="item-container">
                                    <Link className='western-button-link' to="/registervehicle">
                                        <div className="space-between" style={{boxShadow: "0 4px 4px rgba(0,0,0,0.25)", background: "#E5F4E0"}}>
                                            <p>Register personal vehicle</p>
                                            <img src={chevronRight} style={{ height: "1.8vh"}}alt="arrow"/>
                                        </div>
                                    </Link>
                                </div>
                            </IonItem>
                        </div>
                    </div>
                    
        
            </IonContent>
            <IonFooter>
                <Nav onSearch={handleMode}/>
            </IonFooter>
    </IonPage>
    )

}

export default Home
